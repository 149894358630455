import { CurrentStaffWorkSumResponse } from 'src/models/api/currentStaffWorkResponse';

export type StaffHeadCounts = {
  working: number | null;
  resting: number | null;
  waiting: number | null;
  other_working: number | null;
};

export type ActivityStatusMap = {
  our_staff_head_counts: StaffHeadCounts;
  rescue_staff_head_counts: StaffHeadCounts;
};

export type CurrentStaffWork = {
  activity_status_map: ActivityStatusMap;
};

export function convertCurrentStaffWorkSumResponse(response: CurrentStaffWorkSumResponse): CurrentStaffWork {
  return response;
}
