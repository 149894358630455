























import { defineComponent, SetupContext, computed, PropOptions, PropType } from '@vue/composition-api';
const props: Record<string, PropType<any> | PropOptions> = {
  tag: {
    type: String,
    default: 'button',
  },
  type: {
    type: String,
    default: 'default',
  },
  nativeType: {
    type: String,
    default: 'button',
  },
  round: Boolean,
  outline: Boolean,
  block: Boolean,
  social: Boolean,
  link: Boolean,
  icon: Boolean,
  wide: Boolean,
  size: {
    type: String as PropType<'sm' | 'md' | 'lg'>,
    default: 'md',
  },
};

export default defineComponent({
  name: 'p-button',
  props: props,
  setup(props, context: SetupContext) {
    const classes = computed(() => {
      return [
        { [`btn-outline-${props.type}`]: props.outline },
        { 'btn-icon': props.icon },
        { 'btn-round': props.round },
        { 'btn-block': props.block },
        { 'btn-social': props.social },
        { 'btn-link': props.link },
        { 'btn-wd': props.wide },
        `btn-${props.type}`,
        `btn-${props.size}`,
      ];
    });
    function handleClick(evt: any): void {
      context.emit('click', evt);
    }
    return {
      classes,
      handleClick,
    };
  },
});
