import { ref, computed, onMounted, type Ref, type ComputedRef } from '@vue/composition-api';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type StaffLabel } from 'src/models/staffLabel';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
  includesDisabled?: boolean;
};

type InjectionValue = {
  staffLabels: Ref<StaffLabel[]>;
  enabledStaffLabels: ComputedRef<StaffLabel[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffLabels');

export function useStaffLabelsProvider({ workplaceId, includesDisabled }: Params): void {
  const errorBoundary = useErrorBoundary();
  const staffLabels = ref<StaffLabel[]>([]);
  const fetchStaffLabels = errorBoundary.wrap(
    async () => {
      // is_enabled: falseとすると無効なスタッフラベルのみ取得してしまうため
      // is_enabled: undefinedとして全てのスタッフラベルを取得する
      const isEnabled = includesDisabled ? undefined : true;
      const { data } = await staffLabelApi.index({
        workplaceId,
        params: { is_enabled: isEnabled },
      });
      staffLabels.value = data;
    },
    {
      fallbackMessage: 'スタッフラベルの取得に失敗しました',
    },
  );

  // 無効なスタッフラベルを含まずに取得した場合、実質的にstaffLabelsと同義となる
  // 無効なスタッフラベルを含めて取得した際に、有効なスタッフラベルのみを扱いたい場合に使用する
  const enabledStaffLabels = computed(() => staffLabels.value.filter((label) => label.is_enabled));

  onMounted(fetchStaffLabels);

  provide({
    staffLabels,
    enabledStaffLabels,
  });
}

export function useStaffLabels(): InjectionValue {
  return inject();
}
