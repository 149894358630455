







































































































































import { getCurrentInstance, defineComponent } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useAttendance } from 'src/composables/useAttendance';
import { useProgressHeaders } from '../../composables/useProgressHeaders';
import progressDetailApi from 'src/apis/progressDetail';
import { useNotification } from 'src/composables/useNotification';
import { useSelectedTimetables } from '../../composables/useSelectedTimetables';
import { useCurrentStaffWork } from '../../composables/useCurrentStaffWork';
import { useMemo } from '../../composables/useMemo';
import { useSearchConditions } from 'src/composables/useSearchConditions';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    TimetableLabelSelect,
    PrimaryButton,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    const workplaceId = Number(root.$route.params.workplaceId);
    const { budgetGroups } = useBudgetGroups();
    const { timetableLabels } = useTimetableLabels();
    const { fetchAttendanceData } = useAttendance();
    const { notifyError, notifySuccess } = useNotification();
    const { timetableLabels: selectedTimetableLabels } = useSelectedTimetables();
    const { dt, budgetGroup } = useSearchConditions();

    const {
      canSearch,
      canReload,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedProgressHeaders,
      hidesEmptyQuantityProgressHeaders,
      fetchProgressHeaders,
    } = useProgressHeaders();

    const { fetchCurrentStaffWork } = useCurrentStaffWork();

    const { loadMemo } = useMemo();

    function fetchRecords() {
      fetchAttendanceData();
      fetchProgressHeaders();
      fetchCurrentStaffWork();
      loadMemo();
    }

    async function requestBulkUpdateManHours(workplace_id: number, budget_group_id: number): Promise<void> {
      const params = { workplace_id: workplace_id, budget_group_id: budget_group_id };

      await progressDetailApi.subBudgetGroupBulkUpdateManHours(params);
    }
    const onClickBulkUpdateManHours = async () => {
      if (budgetGroup.value === null) {
        notifyError('所属グループを指定してください。');
        return;
      }
      const workplace_id = budgetGroup.value.workplace_id;
      const budget_group_id = budgetGroup.value.id;

      await requestBulkUpdateManHours(workplace_id, budget_group_id);
      notifySuccess('進捗履歴を作成しました');

      const { href } = root.$router.resolve({
        name: 'WorkplaceBackgroundProcessStatus',
        query: { search: '1' },
      });

      window.open(href, '_blank');
    };

    return {
      workplaceId,
      budgetGroups,
      timetableLabels,
      dt,
      budgetGroup,
      selectedTimetableLabels,
      canSearch,
      canReload,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedProgressHeaders,
      hidesEmptyQuantityProgressHeaders,
      onClickBulkUpdateManHours,
      fetchRecords,
    };
  },
});
