import { STAFF_GENDER } from 'src/consts';

export const BLOCK_LENGTH_PER_HOUR = 4;
export const DISPLAY_HOUR_PERIOD = 48;
export const HOUR_BLOCK_WIDTH = 100;
export const SUPPORT_MASTER_ID = -11; // 応援の疑似 master_id
export const SUPPORT_DISP_COLOR = '222a35';
export const STAFF_WORK_PLAN_TABLE_ROW_HEIGHT = 41;

export const STAFF_WORK_PLAN_KEY = 'staff_work_plan_key';

export const STAFF_GENDER_LABEL_MAP = {
  [STAFF_GENDER.NO_ANSWER]: '未設定',
  [STAFF_GENDER.MALE]: '男',
  [STAFF_GENDER.FEMALE]: '女',
  [STAFF_GENDER.NON_BINARY]: '他',
};
