


















































































import Vue from 'vue';
import { defineComponent, SetupContext, onMounted, computed, ref } from '@vue/composition-api';
import { setStyleForPrint } from 'src/views/Dashboard/Layout/PrintLayout.vue';
import { useStaffWorkPlanLocalStorage } from '../composables/useStaffWorkPlanLocalStorage';
import {
  createDisplayTimes,
  getBreakTimeStyle,
  getPositionFromTime,
  getWorkPlanStyle,
  regularizePrintHours,
} from '../staffWorkPlanHelper';
import { TimeInteger } from 'src/models/common';
import { StaffWorkPlan, StaffWorkPlanRow } from '../types';
import { hoursToTimeInteger } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';

type Query = {
  startHour: string;
  endHour: string;
};

const STAFF_NAME_WIDTH = 120;
const HOUR_BLOCK_MAX_WIDTH = 1440;
const PADDING_BREAK_WIDTH = 20;
const PAGE_BREAK_NUM = 33;

export default defineComponent({
  name: 'shift-table-for-print',
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const { loadWorkPlans } = useStaffWorkPlanLocalStorage();
    const staffWorkPlans = loadWorkPlans();
    const sheetNum = computed(() => {
      return Math.ceil(staffWorkPlans.length / PAGE_BREAK_NUM);
    });
    const displayTimes = ref<string[]>([]);
    const displayNum = computed(() => displayTimes.value.length);
    const hourBlockWidth = computed(() => Math.min(HOUR_BLOCK_MAX_WIDTH, HOUR_BLOCK_MAX_WIDTH / displayNum.value));
    const tableRowStyle = computed(() => {
      return {
        gridTemplateColumns: `${STAFF_NAME_WIDTH}px repeat(${displayNum.value}, ${hourBlockWidth.value}px)`,
      };
    });
    const styleBasePosition = computed(() => {
      return hourBlockWidth.value * startHour - STAFF_NAME_WIDTH;
    });

    const { startHour: startHourStr, endHour: endHourStr } = root.$route.query as unknown as Query;
    const { startHour, endHour } = regularizePrintHours(startHourStr, endHourStr);

    onMounted(async () => {
      displayTimes.value = createDisplayTimes({ startHour, displayHourPeriod: endHour });
      setStyleForPrint({ size: 'A3 landscape' });
    });

    const getPositionFromTimeForPrint = (time: TimeInteger) => {
      return getPositionFromTime(time, hourBlockWidth.value, styleBasePosition.value);
    };

    const getBreakTimeStyleForPrint = (breakStartTime: TimeInteger | null, breakEndTime: TimeInteger | null) => {
      if (!isExist(breakStartTime) || !isExist(breakEndTime)) {
        return {
          display: 'none',
        };
      }
      const { startTime, endTime } = limitPeriod({ startTime: breakStartTime, endTime: breakEndTime });
      const style = getBreakTimeStyle(startTime, endTime, hourBlockWidth.value, styleBasePosition.value);
      if (parseInt(style.width) < PADDING_BREAK_WIDTH) {
        style.padding = '2px';
      }
      return style;
    };

    const getWorkPlanStyleForPrint = (workPlan: StaffWorkPlan) => {
      const style = getWorkPlanStyle(limitPeriod(workPlan), hourBlockWidth.value, styleBasePosition.value);
      if (parseInt(style.width) < PADDING_BREAK_WIDTH) {
        style.padding = '2px';
      }
      return style;
    };

    const limitPeriod = <T extends { startTime: number; endTime: number }>(period: T): T => {
      return {
        ...period,
        startTime: Math.max(period.startTime, hoursToTimeInteger(startHour)),
        endTime: Math.min(period.endTime, hoursToTimeInteger(endHour)),
      };
    };

    const sliceTargetWorkPlans = (workPlans: StaffWorkPlanRow[], i: number) => {
      return workPlans.slice(i * PAGE_BREAK_NUM, Math.min((i + 1) * PAGE_BREAK_NUM, workPlans.length));
    };

    const isTimeInRange = (time: TimeInteger) => {
      return hoursToTimeInteger(startHour) <= time && time <= hoursToTimeInteger(endHour);
    };

    return {
      sheetNum,
      displayTimes,
      staffWorkPlans,
      tableRowStyle,
      getPositionFromTimeForPrint,
      getBreakTimeStyleForPrint,
      getWorkPlanStyleForPrint,
      sliceTargetWorkPlans,
      isTimeInRange,
    };
  },
});
