import type { StaffWorkPlanHeadcountTimeBlock } from '../types';

/**
 * TODO:
 * 他の画面におけるバリデーション関連のコンポーザブルではVeeValidateの文脈におけるバリデータやルールを返している。
 * 今後、VeeValidateのルールを返すのか、カスタムバリデータを返すのか、単にバリデーション関数を返すのか
 * 区別できるような形で全体的にコンポーザブルの命名を整理したい。
 */
export const useValidator = () => {
  /**
   * 本バリデータを適用したい対象のフォームは工程数に応じて大量となる。（工程数 * 192 (48時間 * 4分割)）
   * そこにVeeValidateのValidationProviderを使用するとパフォーマンス的に問題があることが分かった。
   * そのため、単なるバリデーション関数として実装し使用することにした。
   */
  const validateTimeBlock = ({ requiredHeadcount }: StaffWorkPlanHeadcountTimeBlock) => {
    if (requiredHeadcount === null) {
      return true;
    }
    return 0 <= requiredHeadcount && requiredHeadcount <= 9999;
  };

  return {
    validateTimeBlock,
  };
};
