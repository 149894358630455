var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ModalForm',{attrs:{"flexible-width":"","on-submit":_vm.handleSubmit,"show":_vm.show,"title":_vm.title},on:{"close":function($event){return _vm.$emit('close')}}},[_c('Container',{attrs:{"label":"基本設定"}},[_c('SplitContent',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"工程名"}},[_c('FormItem',{attrs:{"rules":"required|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TextInput',{attrs:{"has-error":hasError},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"表示色"}},[_c('FormItem',{attrs:{"rules":"required"}},[_c('ColorSelect',{model:{value:(_vm.dispColor),callback:function ($$v) {_vm.dispColor=$$v},expression:"dispColor"}})],1)],1)]},proxy:true},{key:"right",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"工程ラベル"}},[_c('FormItem',{attrs:{"rules":"required"}},[_c('TimetableLabelSelect',{attrs:{"timetableLabels":_vm.timetableLabels},model:{value:(_vm.timetableLabel),callback:function ($$v) {_vm.timetableLabel=$$v},expression:"timetableLabel"}})],1)],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"有効"}},[_c('FormItem',[_c('ToggleSwitch',{model:{value:(_vm.isEnabled),callback:function ($$v) {_vm.isEnabled=$$v},expression:"isEnabled"}})],1)],1)]},proxy:true}])})],1),_c('Container',{attrs:{"label":"利用用途"}},[_c('SplitContent',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"利用機能","type":"checkbox"}},[_vm._v(" "+_vm._s(/* featuresRule単独で使用すると表示時に「入力に誤りがあります」が表示されてしまうため、requiredも使用している。 以下リンクの箇所が原因だと思われるが、featuresRule(featuresValidator)のオプションは効かなかった。 @see {@link https://github.com/logaretm/vee-validate/blob/2.2.15/src/components/provider.js#L238} */)+" "),_c('FormItem',{attrs:{"rules":("required|" + _vm.featuresRule)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('FeatureSelect',{attrs:{"has-error":hasError},model:{value:(_vm.features),callback:function ($$v) {_vm.features=$$v},expression:"features"}})]}}])})],1)]},proxy:true},{key:"right",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"管理対象の項目"}},[_c('FormItem',{attrs:{"rules":"required"}},[_c('ManagementTypeSelect',{model:{value:(_vm.managementType),callback:function ($$v) {_vm.managementType=$$v},expression:"managementType"}})],1)],1)]},proxy:true}])})],1),_c('Container',{attrs:{"label":"初期値"}},[_c('SplitContent',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"標準生産性"}},[_c('FormItem',{attrs:{"rules":"required|max_value:99999.9|min_value:0|decimal:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimetableMasterInputModalNumberInput',{attrs:{"has-error":hasError,"unit":"/人時"},model:{value:(_vm.plannedProductivity),callback:function ($$v) {_vm.plannedProductivity=$$v},expression:"plannedProductivity"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"予定数量"}},[_c('FormItem',{attrs:{"rules":"required|max_value:9999999999.9|min_value:0|decimal:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimetableMasterInputModalNumberInput',{attrs:{"has-error":hasError},model:{value:(_vm.plannedQuantity),callback:function ($$v) {_vm.plannedQuantity=$$v},expression:"plannedQuantity"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"配置限界"}},[_c('FormItem',{attrs:{"rules":"required|numeric|max_value:9999|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimetableMasterInputModalNumberInput',{attrs:{"has-error":hasError,"unit":"人"},model:{value:(_vm.maxAllocations),callback:function ($$v) {_vm.maxAllocations=$$v},expression:"maxAllocations"}})]}}])})],1)]},proxy:true},{key:"right",fn:function(){return [_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"開始終了"}},[_c('FormItem',{attrs:{"rules":_vm.workTimeRules,"vid":"workTimeRange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.workTimeRange),callback:function ($$v) {_vm.workTimeRange=$$v},expression:"workTimeRange"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"休憩1"}},[_c('FormItem',{attrs:{"rules":_vm.break1TimeRules,"vid":"break1TimeRange"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.break1TimeRange),callback:function ($$v) {_vm.break1TimeRange=$$v},expression:"break1TimeRange"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"休憩2"}},[_c('FormItem',{attrs:{"rules":_vm.break2TimeRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeRangeInput',{attrs:{"has-error":hasError},model:{value:(_vm.break2TimeRange),callback:function ($$v) {_vm.break2TimeRange=$$v},expression:"break2TimeRange"}})]}}])})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"目標時刻"}},[_c('FormItem',{attrs:{"rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasError = ref.hasError;
return [_c('TimeInput',{attrs:{"has-error":hasError,"value-instead-of-nan":-1},model:{value:(_vm.targetTime),callback:function ($$v) {_vm.targetTime=$$v},expression:"targetTime"}})]}}])})],1)]},proxy:true}])})],1),_c('Container',{attrs:{"label":"その他"}},[_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"数量を取得するロジメーター作業"}},[_c('TimetableActivityMastersSelect',{attrs:{"timetableActivityMasters":_vm.budgetGroupTimetableActivityMasters},model:{value:(_vm.activityMasterIdsForQuantity),callback:function ($$v) {_vm.activityMasterIdsForQuantity=$$v},expression:"activityMasterIdsForQuantity"}})],1),_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"工数を取得するロジメーター作業"}},[_c('TimetableActivityMastersSelect',{attrs:{"timetableActivityMasters":_vm.budgetGroupTimetableActivityMasters},model:{value:(_vm.activityMasterIdsForManHours),callback:function ($$v) {_vm.activityMasterIdsForManHours=$$v},expression:"activityMasterIdsForManHours"}}),_c('AppropriationDateDiffAnchorSelect',{attrs:{"appropriationDateDiffAnchorOptions":_vm.APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS},model:{value:(_vm.appropriationDateDiffAnchor),callback:function ($$v) {_vm.appropriationDateDiffAnchor=$$v},expression:"appropriationDateDiffAnchor"}}),_c('AppropriationDateRangeSelect',{attrs:{"appropriationDateRangeOptions":_vm.APPROPRIATION_DATE_RANGE_OPTIONS,"isAppropriationDateRangeOptionDisabled":_vm.isAppropriationDateRangeOptionDisabled},model:{value:(_vm.appropriationDateRange),callback:function ($$v) {_vm.appropriationDateRange=$$v},expression:"appropriationDateRange"}})],1),(_vm.hasRoleSuperAdmin)?_c('TimetableMasterInputModalFormGroup',{attrs:{"label":"進捗履歴の間引き"}},[_c('FormItem',[_c('ToggleSwitch',{model:{value:(_vm.shouldAggregateTransactions),callback:function ($$v) {_vm.shouldAggregateTransactions=$$v},expression:"shouldAggregateTransactions"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }