<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex';
import notificationMixin from 'src/mixins/notificationMixin';
import masterMixin from 'src/mixins/masterMixin';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import usersWorkplacesBudgetGroupApi from 'src/apis/workplace_masters/users_workplaces_budget_group';
import timingControlMixin from 'src/mixins/timingControlMixin';

const api = budgetGroupApi;
const displayPageName = '共有する管理グループ';
const msgVars = {
  create: '作成',
  update: '編集',
  delete: '削除',
  update_disp_order: '表示順変更',
};

export default {
  displayPageName: displayPageName,
  props: {
    searchParams: {
      type: Object,
      default: () => {},
      description: 'id of selected budget group',
    },
  },
  data() {
    return {
      displayPageName: displayPageName,
      isSuperAdmin: false,
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,
      budgetGroupCandidates: [],
      enabledBudgetGroups: [],

      pagination: {
        perPage: 50,
        currentPage: 1,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    workplaceId() {
      return this.$route.params.workplaceId;
    },
    hasList() {
      return this.list.length > 0;
    },
    paginatedList() {
      const startIdx = this.pagination.perPage * Math.max(this.pagination.currentPage - 1, 0);
      return this.list.slice(startIdx, startIdx + this.pagination.perPage);
    },
    shouldPaginate() {
      return this.pagination.total > this.pagination.perPage;
    },
    hasError() {
      return this.errors.any();
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  mixins: [notificationMixin, masterMixin, timingControlMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getApiParams() {
      const apiParams = { is_enabled: true };
      return apiParams;
    },
    async getList() {
      const { data: budgetGroups } = await api.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      });

      // For retrieving the data and select box's candidates in one API call,
      // filtering is done both in API and VUE side.
      this.list = [];
      this.enabledBudgetGroups = [];
      budgetGroups.forEach((bg) => {
        const hasRelation = bg.users_workplaces_budget_groups.find((e) => e.user_id === this.searchParams.user.user_id);
        if (hasRelation) {
          this.list.push(bg);
        } else {
          this.enabledBudgetGroups.push(bg);
        }
      });
      this.pagination.total = this.list.length;
    },
    openSaveModal() {
      // initialize budgetGroupCandidates
      this.budgetGroupCandidates = this.enabledBudgetGroups;
      if (this.budgetGroupCandidates.length === 0) {
        const msg = '選択できる管理グループがありません。先に管理グループを作成してください。';
        this.notifyError1(msg);
        return;
      }
      const saveCandidate = {};
      saveCandidate.budget_group_id = this.budgetGroupCandidates[0].id;
      saveCandidate.user_id = this.searchParams.user.user_id;
      this.saveCandidate = saveCandidate;
      this.showSaveModal = true;
    },
    closeSaveModal() {
      this.saveCandidate = {};
      this.showSaveModal = false;
    },
    async saveItem() {
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        };
        await usersWorkplacesBudgetGroupApi.create(reqObj);
        this.getList();
        this.closeSaveModal();
        this.notifySuccess1(`${displayPageName}を${msgVars.create}しました`);
      } catch (err) {
        const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002';
        const msg =
          `${displayPageName}の${msgVars.create}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
        this.notifyError1(msg, { err });
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item;
      const targetUsersWorkplacesBudgetGroup = item.users_workplaces_budget_groups.find(
        (e) => e.user_id === this.searchParams.user.user_id,
      );
      this.deleteCandidate.id = targetUsersWorkplacesBudgetGroup.id;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.deleteCandidate = {};
      this.showDeleteModal = false;
    },
    async deleteItem() {
      const opType = 'delete';
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteCandidate.id,
        };
        await usersWorkplacesBudgetGroupApi[opType](reqObj);
        this.getList();
        this.closeDeleteModal();
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`);
      } catch (err) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われている管理グループです。削除できません。' + '無効化をおすすめします。';
          this.notifyError1(msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
          this.notifyError1(msg, { err });
        }
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem);
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem);
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder);
    },
  },
  created() {
    this.makeFuncGated();
  },
  async mounted() {
    await this.ensureUserAndMasters();
    this.getList();
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
