
import workplaceExtensionApi from 'src/apis/workplace_masters/workplace_extension';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import ListSwitchPanel from 'src/views/Dashboard/Workplace/Settings/ListSwitchPanel.vue';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import keyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import forkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import customFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import {
  defineComponent,
  SetupContext,
  computed,
  reactive,
  onMounted,
  ref,
  getCurrentInstance,
  ComputedRef,
  watch,
} from '@vue/composition-api';
import { Staff } from 'src/models/staff';
import { BudgetGroup } from 'src/models/budgetGroup';
import { isExist } from 'src/util/isExist';
import { Card } from 'src/components/UIComponents';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import { SortSpec, useSorter2Container } from 'src/components/Workplace/Sorter2Container.vue';
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue';
import { StaffSkill } from 'src/models/staffSkill';
import { TimetableMaster } from 'src/models/timetableMaster';
import {
  findDayOfWeekSchedule,
  MONDAY,
  THURSDAY,
  WEDNESDAY,
  TUESDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} from 'src/views/Dashboard/Workplace/Settings/StaffExtension/setting';
import CsvUploadButton from 'src/components/UIComponents/Buttons/CsvUploadButton/CsvUploadButton.vue';
import { useCsvUploadResultModalProvider } from './composables/useCsvUploadResultModal';
import { useUploadStaffCsv } from './composables/useUploadStaffCsv';
import { CsvUploadResultModal } from './components/CsvUploadResultModal';
import type { SearchParams, StaffDisp } from './types';
import ControlPanel from './components/ControlPanel/index.vue';
import StaffList from './components/StaffList/index.vue';
import type { WorkplaceExtension } from 'src/models/workplaceExtension';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';
import StaffExtensionEditModal from 'src/components/Workplace/StaffExtensionEditModal/StaffExtensionEditModal.vue';
import StaffExtensionBulkEditModal from 'src/components/Workplace/StaffExtensionEditModal/StaffExtensionBulkEditModal.vue';
import CsvDownloadButton from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownloadButton.vue';
import { useStaffCsvExport } from './composables/useStaffCsvExport';

const displayPageName = 'スタッフ ロジボード向け';

interface Pagination {
  perPage: number;
  currentPage: number;
  total: number;
}

type State = {
  workplaceExtension: WorkplaceExtension | null;
  staffs: StaffDisp[];
  timetableMasters: TimetableMaster[];
  macroOperationMasters: MacroOperationMaster[];
  isLoaded: boolean;
  checkedStaffs: Staff[];
  hasStaff: ComputedRef<boolean>;
  selectedStaff: StaffDisp | null;
  showStaffExtensionEditModal: boolean;
  showStaffExtensionBulkEditModal: boolean;
  filteredBudgetGroups: BudgetGroup[];
};

export default defineComponent({
  components: {
    StaffExtensionEditModal,
    StaffExtensionBulkEditModal,
    ListSwitchPanel,
    Card,
    Sorter2,
    Sorter2Container: useSorter2Container<StaffDisp>(),
    PaginationContainer,
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    keyPlayerGrayIcon,
    forkliftGrayIcon,
    customFlagGrayIcon,
    CsvUploadButton,
    CsvUploadResultModal,
    ControlPanel,
    StaffList,
    CsvDownloadButton,
  },
  name: 'staff-extension',
  setup(_props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = reactive<State>({
      workplaceExtension: null,
      staffs: [],
      timetableMasters: [],
      macroOperationMasters: [],
      selectedStaff: null,
      isLoaded: false,
      checkedStaffs: [],
      hasStaff: computed((): boolean => {
        return state.staffs.length > 0;
      }),
      filteredBudgetGroups: [],
      showStaffExtensionEditModal: false,
      showStaffExtensionBulkEditModal: false,
    });
    const searchParams: SearchParams = reactive({
      staff: null,
      staffName: null,
      staffNumber: null,
      budgetGroup: null,
      staffAgency: null,
      staffLabel: null,
      staffGender: null,
      timetableMasterId: null,
      isKeyPlayer: false,
      isForkman: false,
      has_custom_skill1: false,
      has_custom_skill2: false,
      has_custom_skill3: false,
      macroOperationMasterId: null,
      displayListDisabledItem: false,
    });
    const { csvData, csvFileName, prepareExport } = useStaffCsvExport();

    const workplaceId = computed(() => {
      return Number(vueInstance.$route.params.workplaceId);
    });

    const pagination = reactive<Pagination>({
      perPage: 50,
      currentPage: 1,
      total: 0,
    });

    const defaultSortSpec = ref<SortSpec[]>([]);
    function resetToDefaultSortOrder(): void {
      defaultSortSpec.value = [{ key: 'sortFullNameKana', asc: true }];
    }
    resetToDefaultSortOrder();

    function getApiParams() {
      return {
        staff_name: searchParams.staffName,
        staff_number: searchParams.staffNumber,
        staff_id: searchParams.staff?.id || null,
        budget_group_id: searchParams.budgetGroup?.id || null,
        staff_agency_id: searchParams.staffAgency?.id || null,
        staff_label_id: searchParams.staffLabel?.id || null,
        staff_gender: searchParams.staffGender,
        timetable_master_id: searchParams.timetableMasterId,
        is_key_player: searchParams.isKeyPlayer || null,
        is_forkman: searchParams.isForkman || null,
        has_custom_skill1: searchParams.has_custom_skill1 || null,
        has_custom_skill2: searchParams.has_custom_skill2 || null,
        has_custom_skill3: searchParams.has_custom_skill3 || null,
        macro_operation_master_id: searchParams.macroOperationMasterId,
        is_enabled: searchParams.displayListDisabledItem ? null : true,
      };
    }

    async function getList() {
      state.workplaceExtension = await workplaceExtensionApi.show(workplaceId.value);

      state.timetableMasters = await timetableMasterApi.index({
        workplaceId: workplaceId.value,
        params: {
          budget_group_id: searchParams.budgetGroup?.id || undefined,
          is_enabled: true,
          use_in_staff_work_plan: true,
        },
      });

      state.macroOperationMasters = await macroOperationMasterApi.getSubWorkplaceList({
        workplaceId: workplaceId.value,
        params: { is_enabled: true },
      });

      await loadStaffs();
    }

    async function loadStaffs({ resetsCurrentPage = true }: { resetsCurrentPage?: boolean } = {}) {
      const staffs = await staffExtensionApi.staffs({
        workplaceId: workplaceId.value,
        params: getApiParams(),
      });
      state.staffs = convertStaffToStaffDisp(staffs);
      pagination.total = state.staffs.length;
      if (resetsCurrentPage) {
        pagination.currentPage = 1;
      }
    }
    function sumWorkTime(workStartTime: number | null, workEndTime: number | null) {
      return (workStartTime ?? -1) + (workEndTime ?? -1);
    }
    function convertStaffToStaffDisp(staffs: Staff[]): StaffDisp[] {
      return staffs.map((e: Staff) => {
        const staffWorkSchedules = e.staff_extension?.staff_work_schedules;
        // null/空文字の場合ソートが効かないため'-'で対応
        const macroOperationName = isExist(e.staff_extension?.macro_operation_master)
          ? e.staff_extension?.macro_operation_master.name
          : '-';
        const monWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, MONDAY);
        const tueWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, TUESDAY);
        const wedWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, WEDNESDAY);
        const thuWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, THURSDAY);
        const friWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, FRIDAY);
        const satWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, SATURDAY);
        const sunWorkSchedule = findDayOfWeekSchedule(staffWorkSchedules, SUNDAY);
        return {
          ...e,
          sortMacroOperationName: macroOperationName,
          sortMonWorkTime:
            monWorkSchedule !== null ? sumWorkTime(monWorkSchedule.work_start_time, monWorkSchedule.work_end_time) : 0,
          sortTueWorkTime:
            tueWorkSchedule !== null ? sumWorkTime(tueWorkSchedule.work_start_time, tueWorkSchedule.work_end_time) : 0,
          sortWedWorkTime:
            wedWorkSchedule !== null ? sumWorkTime(wedWorkSchedule.work_start_time, wedWorkSchedule.work_end_time) : 0,
          sortThuWorkTime:
            thuWorkSchedule !== null ? sumWorkTime(thuWorkSchedule.work_start_time, thuWorkSchedule.work_end_time) : 0,
          sortFriWorkTime:
            friWorkSchedule !== null ? sumWorkTime(friWorkSchedule.work_start_time, friWorkSchedule.work_end_time) : 0,
          sortSatWorkTime:
            satWorkSchedule !== null ? sumWorkTime(satWorkSchedule.work_start_time, satWorkSchedule.work_end_time) : 0,
          sortSunWorkTime:
            sunWorkSchedule !== null ? sumWorkTime(sunWorkSchedule.work_start_time, sunWorkSchedule.work_end_time) : 0,
          fullName: `${e.family_name} ${e.first_name}`,
          fullNameKana: `${e.family_name_kana || ''} ${e.first_name_kana || ''}`,
          // 半角スペースソートのため鸞を入れる cf). src/assets/src/hooks/staffSortHook.ts
          sortFullNameKana: `${e.family_name_kana || '鸞'} ${e.first_name_kana || '鸞'}`,
          mon: monWorkSchedule,
          tue: tueWorkSchedule,
          wed: wedWorkSchedule,
          thu: thuWorkSchedule,
          fri: friWorkSchedule,
          sat: satWorkSchedule,
          sun: sunWorkSchedule,
          skill1: convertSkill(findStaffSkill(e.staff_skills, 1)),
          skill2: convertSkill(findStaffSkill(e.staff_skills, 2)),
          skill3: convertSkill(findStaffSkill(e.staff_skills, 3)),
          skill4: convertSkill(findStaffSkill(e.staff_skills, 4)),
          skill5: convertSkill(findStaffSkill(e.staff_skills, 5)),
        };
      });
    }

    function convertStaffDispToStaff(staffDisps: StaffDisp[]) {
      const staffDispKeys = [
        'sortMonWorkTime',
        'sortTueWorkTime',
        'sortWedWorkTime',
        'sortThuWorkTime',
        'sortFriWorkTime',
        'sortSatWorkTime',
        'sortSunWorkTime',
        'sortMacroOperationName',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat',
        'sun',
        'skill1',
        'skill2',
        'skill3',
        'skill4',
        'skill5',
      ];

      return staffDisps.map((staffDisp: StaffDisp) => {
        return Object.fromEntries(Object.entries(staffDisp).filter(([key]) => !staffDispKeys.includes(key))) as Staff;
      });
    }

    function convertSkill(skill: StaffSkill | undefined) {
      if (skill === undefined) {
        return { id: 0, name: '-', color: 'FFFFFF' };
      }
      const timetableMaster = findTimetableMaster(skill.timetable_master_id);
      return {
        name: timetableMaster?.name || '-',
        color: timetableMaster?.disp_color || 'FFFFFF',
      };
    }

    // スタッフスキル検索
    function findStaffSkill(staffSkills: StaffSkill[], num: number): StaffSkill | undefined {
      return staffSkills.find((staffSkill: StaffSkill) => {
        return staffSkill.priority === num;
      });
    }

    // タイムテーブルマスタ検索
    function findTimetableMaster(timetableMasterId: number): TimetableMaster | undefined {
      return state.timetableMasters.find((timetableMaster) => {
        return timetableMaster.id === timetableMasterId;
      });
    }

    onMounted(async () => {
      state.isLoaded = false;
      setPageName(vueInstance, displayPageName);
      await getList();
      state.isLoaded = true;
    });

    useCsvUploadResultModalProvider();
    const { uploadStaffCsv } = useUploadStaffCsv({
      workplaceId,
      fetchStaffs: () => loadStaffs({ resetsCurrentPage: false }),
    });

    const updateSearchParams = (newSearchParams: SearchParams): void => {
      Object.assign(searchParams, { ...newSearchParams });
    };

    const updateCheckedStaffs = (staffs: StaffDisp[]): void => {
      state.checkedStaffs = convertStaffDispToStaff(staffs);
    };

    watch(
      () => searchParams,
      () => loadStaffs(),
      { deep: true },
    );

    async function updateStaff() {
      const staffs = await staffExtensionApi.staffs({
        workplaceId: workplaceId.value,
        params: getApiParams(),
      });
      state.staffs = convertStaffToStaffDisp(staffs);
    }

    const openStaffExtensionEditModal = (item: StaffDisp): void => {
      state.showStaffExtensionEditModal = true;
      state.selectedStaff = item;
    };

    const closeStaffExtensionEditModal = (): void => {
      state.showStaffExtensionEditModal = false;
    };

    const openStaffExtensionBulkEditModal = (): void => {
      state.filteredBudgetGroups = [
        ...new Set(
          state.staffs.reduce((budgetGroup: BudgetGroup[], staff) => {
            if (staff.budget_group === undefined) {
              return [];
            }
            if (state.checkedStaffs.map((e) => e.id).includes(staff.id)) {
              budgetGroup.push(staff.budget_group);
            }
            return budgetGroup;
          }, []),
        ),
      ];
      state.showStaffExtensionBulkEditModal = true;
    };

    const closeStaffExtensionBulkEditModal = (): void => {
      state.showStaffExtensionBulkEditModal = false;
    };

    return {
      workplaceId,
      state,
      searchParams,
      pagination,
      defaultSortSpec,
      uploadStaffCsv,
      updateSearchParams,
      updateCheckedStaffs,
      updateStaff,
      openStaffExtensionEditModal,
      closeStaffExtensionEditModal,
      openStaffExtensionBulkEditModal,
      closeStaffExtensionBulkEditModal,
      csvData,
      csvFileName,
      prepareExport,
    };
  },
});
