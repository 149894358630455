







































import { defineComponent, computed } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { getProductivityColor } from 'src/util/progressHeaderHelpers';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { formatFixedDecimal } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  filters: {
    orDefault,
    formatFixedDecimal,
  },
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const { displayedProgressEndTime, plannedProductivity, requiredProductivity } = useProgressSummary();

    const latestProductivityColor = computed(() =>
      getProductivityColor(progressHeader.value.latest_productivity, plannedProductivity.value),
    );

    const displayedProductivities = computed(() => ({
      latest:
        progressHeader.value.latest_productivity !== null
          ? formatFixedDecimal(progressHeader.value.latest_productivity)
          : '-',
      planned: plannedProductivity.value !== null ? formatFixedDecimal(plannedProductivity.value) : '-',
      required: requiredProductivity.value !== null ? formatFixedDecimal(requiredProductivity.value) : '-',
    }));

    return {
      displayedProgressEndTime,
      latestProductivityColor,
      displayedProductivities,
    };
  },
});
