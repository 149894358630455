import { ref, unref, watch, computed, type Ref } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  timetableMasters: Ref<TimetableMaster[]>;
  filteredTimetableMasters: Ref<TimetableMaster[]>;
  budgetGroup: Ref<BudgetGroup | null>;
  useInDailySimulationAndStaffWorkPlan: Ref<boolean>;
  useInPerformanceBoardDetail: Ref<boolean>;
  useInPerformanceBoardList: Ref<boolean>;
  useInStaffOverview: Ref<boolean>;
  includesDisabled: Ref<boolean>;
  isLoading: Ref<boolean>;
  fetchTimetableMasters: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableMasters');

async function getTimetableMasters({ budgetGroup }: { budgetGroup: BudgetGroup }): Promise<TimetableMaster[]> {
  return await timetableMasterApi.index({
    workplaceId: budgetGroup.workplace_id,
    params: {
      budget_group_id: budgetGroup.id,
    },
  });
}

export function useTimetableMastersProvider(): void {
  const errorBoundary = useErrorBoundary();
  const timetableMasters = ref<TimetableMaster[]>([]);
  const budgetGroup = ref<BudgetGroup | null>(null);
  const useInDailySimulationAndStaffWorkPlan = ref(false);
  const useInPerformanceBoardDetail = ref(false);
  const useInPerformanceBoardList = ref(false);
  const useInStaffOverview = ref(false);
  const includesDisabled = ref(false);
  const isLoading = ref(false);
  const fetchTimetableMasters = errorBoundary.wrap(
    async () => {
      const unwrappedBudgetGroup = unref(budgetGroup);
      if (unwrappedBudgetGroup !== null) {
        isLoading.value = true;
        try {
          timetableMasters.value = await getTimetableMasters({
            budgetGroup: unwrappedBudgetGroup,
          });
        } finally {
          isLoading.value = false;
        }
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const filteredTimetableMasters = computed(() =>
    timetableMasters.value.filter(
      (timetableMaster) =>
        // use_in_daily_simulationとuse_in_staff_work_planは同一の値となるように制御をかけており、フロント側では一方の値を使用するようにしている。
        (!useInDailySimulationAndStaffWorkPlan.value || timetableMaster.use_in_staff_work_plan) &&
        (!useInPerformanceBoardDetail.value || timetableMaster.use_in_performance_board_detail) &&
        (!useInPerformanceBoardList.value || timetableMaster.use_in_performance_board_list) &&
        (!useInStaffOverview.value || timetableMaster.use_in_staff_overview) &&
        (includesDisabled.value || timetableMaster.is_enabled),
    ),
  );

  watch([budgetGroup], fetchTimetableMasters);

  provide({
    timetableMasters,
    filteredTimetableMasters,
    budgetGroup,
    useInDailySimulationAndStaffWorkPlan,
    useInPerformanceBoardDetail,
    useInPerformanceBoardList,
    useInStaffOverview,
    includesDisabled,
    isLoading,
    fetchTimetableMasters,
  });
}

export function useTimetableMasters(): InjectionValue {
  return inject();
}
