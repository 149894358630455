const localStorageName = 'logiboard';
const SET_LOCALSTORAGE = 'SET_LOCALSTORAGE';
// const PROGRESS_DISP_MODE_DETAIL1 = 'detail1'

// the definition of initial state is required.
const state = {
  serverToken: '',
  frontCameraDeviceId: '',
  progressDispMode: '',

  isStaffPersisted: true,
  isActivityPersisted: true,
  persistedStaffInfo: null,
  persistedActivityInfo: null,
  persistedHeadCount: null,

  // balance2ShowDetailTable: true,
};

try {
  const config = JSON.parse(localStorage.getItem(localStorageName));
  Object.keys(config).forEach((k) => {
    state[k] = config[k];
  });
} catch {}

const getters = {
  serverToken: (state) => state.serverToken,
  frontCameraDeviceId: (state) => state.frontCameraDeviceId,
  // isProgressDispModeDetail1: state => state.progressDispMode === PROGRESS_DISP_MODE_DETAIL1,
  isStaffPersisted: (state) => state.isStaffPersisted,
  persistedStaffInfo: (state) => state.persistedStaffInfo,
  isActivityPersisted: (state) => state.isActivityPersisted,
  persistedActivityInfo: (state) => state.persistedActivityInfo,
  persistedHeadCount: (state) => state.persistedHeadCount,
  // balance2ShowDetailTable: state => state.balance2ShowDetailTable,
};

const actions = {
  get({ state }, { key }) {
    return Promise.resolve(state[key]);
  },
  set({ commit }, { key, val }) {
    const data = { [key]: val };
    commit(SET_LOCALSTORAGE, { data });
  },
  // setProgressDispModeDefault({ commit, state, getters }) {
  //   const data = { 'progressDispMode': '' }
  //   commit(SET_LOCALSTORAGE, { data })
  // },
  // setProgressDispModeDetail1({ commit, state, getters }) {
  //   const data = { 'progressDispMode': PROGRESS_DISP_MODE_DETAIL1 }
  //   commit(SET_LOCALSTORAGE, { data })
  // },
};

const mutations = {
  [SET_LOCALSTORAGE](state, { data }) {
    Object.keys(data).forEach((k) => {
      state[k] = data[k];
    });
    localStorage.setItem(localStorageName, JSON.stringify(state));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
