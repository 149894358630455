











import { defineComponent, PropType } from '@vue/composition-api';
import { BudgetGroup } from 'src/models/budgetGroup';
import { useMemo } from '../../composables/useMemo';
const MEMO_MAX_LENGTH = 255;
export default defineComponent({
  props: {
    workplaceId: {
      type: Number,
      required: true,
    },
    budgetGroup: {
      type: Object as PropType<BudgetGroup | null>,
      required: true,
    },
    dt: {
      type: Date,
      required: true,
    },
  },
  setup() {
    const { memo, updateMemo } = useMemo();
    const handleBlur = async () => {
      if (memo.value.length > MEMO_MAX_LENGTH) {
        return;
      }

      await updateMemo();
    };
    return {
      memo,
      handleBlur,
      MEMO_MAX_LENGTH,
    };
  },
});
