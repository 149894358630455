import { ref, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { useStaffWorkPlan } from './useStaffWorkPlan';
import { useStaffWorkPlanLocalStorage } from './useStaffWorkPlanLocalStorage';

type InjectionValue = {
  startHour: Ref<number | null>;
  endHour: Ref<number | null>;
  isDisplayedStaffWorkPlanPrintModal: Ref<boolean>;
  openStaffWorkPlanPrintModal: () => void;
  closeStaffWorkPlanPrintModal: () => void;
  printStaffWorkPlan: (startHour: number | null, endHour: number | null) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffWorkPlanPrintModal');

export function useStaffWorkPlanPrintModalProvider(): void {
  const isDisplayedStaffWorkPlanPrintModal = ref(false);
  const { workplaceId } = useSearchConditions();
  const { filteredStaffWorkPlans } = useStaffWorkPlan();
  const { saveWorkPlans } = useStaffWorkPlanLocalStorage();
  const startHour = ref<number | null>(null);
  const endHour = ref<number | null>(null);

  const openStaffWorkPlanPrintModal = () => {
    startHour.value = null;
    endHour.value = null;
    isDisplayedStaffWorkPlanPrintModal.value = true;
  };

  const closeStaffWorkPlanPrintModal = () => {
    isDisplayedStaffWorkPlanPrintModal.value = false;
  };

  const printStaffWorkPlan = (startHour: number | null, endHour: number | null) => {
    const searchParams = {
      startHour,
      endHour,
    };
    saveWorkPlans(filteredStaffWorkPlans.value);

    const queryStr = Object.entries(searchParams).map(([k, v]) => `${k}=${v}`);
    closeStaffWorkPlanPrintModal();
    window.open(`/print/workplace/${workplaceId}/staff-work-plan?${queryStr.join('&')}`);
  };

  provide({
    startHour,
    endHour,
    isDisplayedStaffWorkPlanPrintModal,
    openStaffWorkPlanPrintModal,
    closeStaffWorkPlanPrintModal,
    printStaffWorkPlan,
  });
}

export function useStaffWorkPlanPrintModal(): InjectionValue {
  return inject();
}
