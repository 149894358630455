import {
  packToTimeInteger,
  secondsToTimeInteger,
  timeDifferenceInSeconds,
  timeIntegerToSeconds,
  unpackTimeIntegerToStringFormat,
} from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { TOTAL_HOUR_BLOCK_ID, DISPLAY_END_HOUR, UNIT_BLOCK_MINUTES, UNIT_BLOCK_LENGTH_PER_HOUR } from './const';
import type {
  Header,
  SupportTimeBlock,
  HourBlock,
  TimetableRow,
  RestingTimetableRow,
  TimetableMasterWithProgressHeader,
  ActualProgress,
} from './types';
import { DeepReadonly, computed, reactive } from '@vue/composition-api';
import { createTimeRange } from 'src/values/TimeRange';
import { ProgressHeader } from 'src/models/progressHeader';
import { ProgressHeadcountDetail } from 'src/models/progressHeadcountDetail';
import { ProgressDetail } from 'src/models/progressDetail';
import { addComma } from 'src/filters/number_filters';
import { TimetableMasterMap } from 'src/composables/useTimetableMasters';
import { TimetableMaster } from 'src/models/timetableMaster';
import { MANAGEMENT_TYPE, TIMETABLE_TYPE } from 'src/consts';
import type { TimeInteger } from 'src/models/common';

/**
 * すべての一般工程の行の初期状態を生成し、その配列をタイムテーブルとして返却する
 */
export const createTimetable = (
  progressHeaders: DeepReadonly<ProgressHeader[]>,
  timetableMasterMap: TimetableMasterMap,
): TimetableRow[] => {
  return progressHeaders
    .concat()
    .filter((progressHeader) => {
      return timetableMasterMap[progressHeader.timetable_master_id]?.timetable_type === TIMETABLE_TYPE.GENERAL;
    })
    .map((progressHeader) => {
      const { actualProgresses, lastActualProgressEndTime } = createActualProgresses(progressHeader.progress_details);
      const header = createHeader(progressHeader, timetableMasterMap, lastActualProgressEndTime);
      const headcountsByHour = getTimeBlockHeadcounts(progressHeader.progress_headcount_details);
      const hourBlocks = headcountsByHour.map((headcounts) => {
        const unitTimeBlocks = headcounts.map(({ startTime, headcount }) => {
          let actualStartTime = startTime;
          const endTime = getNextIntervalUnitMinutes(actualStartTime);
          if (actualStartTime < lastActualProgressEndTime && lastActualProgressEndTime < endTime) {
            actualStartTime = lastActualProgressEndTime;
          }
          return {
            displayTime: unpackTimeIntegerToStringFormat(startTime),
            headcount,
            timetableMasterId: progressHeader.timetable_master_id,
            isSimulated: false,
            isPreviousSimulated: false,
            isHeadcountSameAsPreviousTime: false,
            onBreakHeadcount: 0,
            isBreak: false as const,
            progress: {
              startTime: actualStartTime,
              endTime,
              quantity: 0,
              totalQuantity: 0,
              productivity: header.targetProductivity ?? 0,
              isSimulated: false,
              isChanged: false,
              totalQuantityRoundingScale: null,
            },
          };
        });
        return { unitTimeBlocks };
      });
      const timetableRow: TimetableRow = reactive({
        header,
        actualProgresses,
        hourBlocks,
        progressHeader,
      });
      return timetableRow;
    })
    .sort(({ header: a }, { header: b }) => (a.dispOrder ?? 0) - (b.dispOrder ?? 0));
};

/**
 * 休憩工程の行の初期状態を生成する
 */
export const createRestingTimetableRow = (
  progressHeaders: DeepReadonly<ProgressHeader[]>,
  timetableMasterMap: TimetableMasterMap,
): RestingTimetableRow | null => {
  const restingProgressHeader = progressHeaders.find((progressHeader) => {
    return timetableMasterMap[progressHeader.timetable_master_id]?.timetable_type === TIMETABLE_TYPE.RESTING;
  });
  if (!isExist(restingProgressHeader)) {
    return null;
  }
  const restingTimetableMaster = timetableMasterMap[restingProgressHeader.timetable_master_id];
  if (!isExist(restingTimetableMaster)) {
    return null;
  }
  const headcountsByHour = getTimeBlockHeadcounts(restingProgressHeader.progress_headcount_details);
  const hourBlocks = headcountsByHour.map((headcounts) => {
    const unitTimeBlocks = headcounts.map(({ startTime, headcount }) => ({
      displayTime: unpackTimeIntegerToStringFormat(startTime),
      headcount,
      timetableMasterId: restingProgressHeader.timetable_master_id,
      isSimulated: false,
      isPreviousSimulated: false,
      isHeadcountSameAsPreviousTime: false,
      standbyHeadcount: 0,
    }));
    return { unitTimeBlocks };
  });
  const restingTimetableRow: RestingTimetableRow = reactive({
    header: {
      masterId: restingTimetableMaster.id,
      name: restingTimetableMaster.name,
      backgroundColor: restingTimetableMaster.disp_color,
    },
    hourBlocks,
    progressHeader: restingProgressHeader,
  });
  return restingTimetableRow;
};

/**
 * シミュレーションに利用する情報群を生成する
 */
const createHeader = (
  progressHeader: DeepReadonly<ProgressHeader>,
  timetableMasterMap: TimetableMasterMap,
  lastActualProgressEndTime: number,
): Header => {
  const timetableMaster = timetableMasterMap[progressHeader.timetable_master_id];
  const header: Header = reactive({
    masterId: timetableMaster?.id ?? null,
    name: timetableMaster?.name ?? null,
    backgroundColor: timetableMaster?.disp_color,
    dispOrder: timetableMaster?.disp_order ?? null,
    scheduledQuantity: progressHeader.scheduled_quantity,
    totalProductivity: progressHeader.total_productivity,
    targetProductivity: progressHeader.target_productivity,
    needManHours: computed(() => {
      if (
        !isExist(header.scheduledQuantity) ||
        !isExist(header.targetProductivity) ||
        header.targetProductivity === 0
      ) {
        return null;
      }
      return header.scheduledQuantity / header.targetProductivity;
    }),
    targetCompletionTime: progressHeader.target_completion_time,
    simulatedCompletionTime: null,
    break1TimeRange: createTimeRange(
      timetableMaster?.break1_start_time ?? null,
      timetableMaster?.break1_end_time ?? null,
    ),
    break2TimeRange: createTimeRange(
      timetableMaster?.break2_start_time ?? null,
      timetableMaster?.break2_end_time ?? null,
    ),
    timetableType: timetableMaster?.timetable_type ?? null,
    lastActualProgressEndTime,
    isManagementOnlyManHour: timetableMaster?.management_type === MANAGEMENT_TYPE.ONLY_MAN_HOUR,
  });
  return header;
};

/**
 * すべての進捗ヘッダの中で作業人数が設定されている最も遅い終了時刻を算出する
 */
export const getLastHeadcountExistTime = (progressHeaders: DeepReadonly<ProgressHeader[]>): number => {
  return progressHeaders.reduce((lastHeadcountExistTime, progressHeader) => {
    const progressHeadcountDetails = progressHeader.progress_headcount_details;
    let _lastHeadcountExistTime = 0;
    for (let hour = 0; hour < DISPLAY_END_HOUR; hour++) {
      for (let blockIndex = 0; blockIndex < UNIT_BLOCK_LENGTH_PER_HOUR; blockIndex++) {
        const targetStartTime = packToTimeInteger(hour, UNIT_BLOCK_MINUTES * blockIndex, 0);
        const targetBlock = progressHeadcountDetails.find(
          (planBoardBlock) => planBoardBlock.start_time === targetStartTime,
        );
        if (isExist(targetBlock)) {
          _lastHeadcountExistTime = targetBlock.end_time;
        }
      }
    }
    return Math.max(lastHeadcountExistTime, _lastHeadcountExistTime);
  }, 0);
};

/**
 * 作業進捗情報を生成する
 */
const createActualProgresses = (
  progressDetails: DeepReadonly<ProgressDetail[]>,
): { actualProgresses: ActualProgress[]; lastActualProgressEndTime: number } => {
  // 30分以上の単位の配列に変換する
  const batchedActualProgresses: ActualProgress[] = [];
  let quantity = 0;
  let manHours = 0;
  let totalQuantity = 0;
  let startTime: number | null = null;
  let lastActualProgressEndTime = 0;

  progressDetails
    .concat()
    .sort((a, b) => {
      return a.start_time - b.start_time;
    })
    .forEach((data, index, sortedProgressDetails) => {
      totalQuantity += data.quantity;
      quantity += data.quantity ?? 0;
      manHours += data.man_hours;
      if (startTime === null) {
        startTime = data.start_time;
      }
      const isBatchSizeSufficient = isAtLeast30Minutes(timeDifferenceInSeconds(startTime, data.end_time));
      const isLast = index === sortedProgressDetails.length - 1;
      // 30分に満たないレコードは30分以上になるようにデータを合算する
      if (isBatchSizeSufficient || isLast) {
        batchedActualProgresses.push({
          quantity,
          totalQuantity,
          productivity: quantity / manHours,
          startTime,
          endTime: data.end_time,
        });

        startTime = null;
        quantity = 0;
        manHours = 0;
      }
      lastActualProgressEndTime = data.end_time;
    });
  return {
    actualProgresses: batchedActualProgresses,
    lastActualProgressEndTime,
  };
};

type TimeBlockHeadcount = {
  startTime: TimeInteger;
  headcount: number;
};

/**
 * 1時間毎に人時情報の配列をまとめた二次元配列を生成する
 * 1時間毎の配列の中には、1時間を 4分割した 15分毎の人時情報を持つ
 *   形式: [ [ { startTime: 0, headcount: 0 }, ..., { startTime: 4500, headcount: 0 } ], ... ]
 * この人時情報を使用して、呼び出し元でHourBlocksを生成することを想定している
 */
const getTimeBlockHeadcounts = (
  progressHeadcountDetails: DeepReadonly<ProgressHeadcountDetail[]>,
): TimeBlockHeadcount[][] => {
  const headcountsByHour: TimeBlockHeadcount[][] = [];
  for (let hour = 0; hour < DISPLAY_END_HOUR; hour++) {
    const headcounts: TimeBlockHeadcount[] = [];
    for (let blockIndex = 0; blockIndex < UNIT_BLOCK_LENGTH_PER_HOUR; blockIndex++) {
      const targetStartTime = packToTimeInteger(hour, UNIT_BLOCK_MINUTES * blockIndex, 0);
      const targetBlock = progressHeadcountDetails.find(
        (planBoardBlock) => planBoardBlock.start_time === targetStartTime,
      );
      const headcount = targetBlock?.headcount ?? 0;
      headcounts.push({
        startTime: targetStartTime,
        headcount,
      });
    }
    headcountsByHour.push(headcounts);
  }
  return headcountsByHour;
};

/**
 * 一般工程と休憩工程の行を元に単位時間ごとの合計人数を 1時間ごとの配列で持つ配列を生成する
 */
export const createTotalHourBlocksFromDailySimulationTimetable = (
  timetable: (TimetableRow | RestingTimetableRow)[],
) => {
  const totalHourBlocks = createSupportHourBlocks(TOTAL_HOUR_BLOCK_ID);
  timetable.forEach((timetableRow) => {
    timetableRow.hourBlocks.forEach(({ unitTimeBlocks }, hour) => {
      unitTimeBlocks.forEach((headcountData, timeBlockIndex) => {
        if (headcountData.isSimulated) {
          return;
        }
        totalHourBlocks[hour].unitTimeBlocks[timeBlockIndex].headcount += headcountData.headcount;
      });
    });
  });
  return totalHourBlocks;
};

/**
 * 1時間ごとの作業人数を持つ配列の初期状態を生成する
 * 合計行や割当なし行の生成に使用される想定
 */
export const createSupportHourBlocks = (timetableMasterId: number): HourBlock<SupportTimeBlock>[] => {
  return new Array(DISPLAY_END_HOUR).fill('').reduce((hourBlocks, _, hour) => {
    const unitTimeBlocks: SupportTimeBlock[] = new Array(UNIT_BLOCK_LENGTH_PER_HOUR)
      .fill('')
      .map((_, timeBlockIndex) => ({
        displayTime: convertHourStr(hour, timeBlockIndex * UNIT_BLOCK_MINUTES),
        headcount: 0,
        timetableMasterId,
        isSimulated: false,
        isPreviousSimulated: false,
        isHeadcountSameAsPreviousTime: false,
      }));
    hourBlocks.push({
      unitTimeBlocks,
    });
    return hourBlocks;
  }, []);
};

const convertHourStr = (hour: number, min: number) => {
  return `${hour}:${`${min}`.padEnd(2, '0')}`;
};

export const getTimetableMastersWithProgressHeader = (
  timetableMasters: TimetableMaster[],
  progressHeaders: DeepReadonly<ProgressHeader[]>,
): TimetableMasterWithProgressHeader[] => {
  return timetableMasters.map((timetableMaster) => {
    const progressHeader = progressHeaders.find(
      (progressHeader) => progressHeader.timetable_master_id === timetableMaster.id,
    );
    return {
      ...timetableMaster,
      progressHeader: progressHeader,
    };
  });
};

/**
 * 当日シミュレーションで使用するヘルパー関数群
 */

export const formatCommaValue = (_value?: number, numberOfDecimalPlaces: number = 0, defaultValue: string = '') => {
  if (!isExist(_value) || _value.toString().length === 0) {
    return defaultValue;
  }
  const denominator = Math.pow(10, numberOfDecimalPlaces);
  const value = Math.round(_value * denominator);
  // value が -0 だった場合に、addComma(-0) の結果 '-0' という文字列に変換されてしまうのを避けるため value が 0(-0) のときは 0 という三項演算子にしている
  const commaValue = addComma(value === 0 ? 0 : value / denominator);
  return commaValue.includes('.') || numberOfDecimalPlaces === 0 ? commaValue : `${commaValue}.0`;
};

type TimeMode = 'timeInteger' | 'seconds';

const getNextIntervalMinutes = (time: number, intervalMinutes: number, mode: TimeMode = 'timeInteger'): number => {
  const minutesToSeconds = intervalMinutes * 60;
  let currentSeconds = time;
  if (mode === 'timeInteger') {
    currentSeconds = timeIntegerToSeconds(time);
  }
  const intervalMinutesToSeconds = (Math.floor(currentSeconds / minutesToSeconds) + 1) * minutesToSeconds;
  return mode === 'timeInteger' ? secondsToTimeInteger(intervalMinutesToSeconds) : intervalMinutesToSeconds;
};

const getFlooredIntervalMinutes = (time: number, intervalMinutes: number, mode: TimeMode = 'timeInteger'): number => {
  const minutesToSeconds = intervalMinutes * 60;
  let currentSeconds = time;
  if (mode === 'timeInteger') {
    currentSeconds = timeIntegerToSeconds(time);
  }
  const intervalMinutesToSeconds = Math.floor(currentSeconds / minutesToSeconds) * minutesToSeconds;
  return mode === 'timeInteger' ? secondsToTimeInteger(intervalMinutesToSeconds) : intervalMinutesToSeconds;
};

export const getNextIntervalUnitMinutes = (time: number, mode: TimeMode = 'timeInteger'): number => {
  return getNextIntervalMinutes(time, UNIT_BLOCK_MINUTES, mode);
};

export const getNextIntervalHour = (time: number, mode: TimeMode = 'timeInteger'): number => {
  return getNextIntervalMinutes(time, 60, mode);
};

export const getFlooredIntervalUnitMinutes = (time: number, mode: TimeMode = 'timeInteger'): number => {
  return getFlooredIntervalMinutes(time, UNIT_BLOCK_MINUTES, mode);
};

export const isAtLeast30Minutes = (seconds: number) => {
  return seconds >= 1800; // 30分 * 60秒
};

export const isAtLeast30MinutesByTimeRange = (startTime: TimeInteger, endTime: TimeInteger) => {
  return isAtLeast30Minutes(timeDifferenceInSeconds(startTime, endTime));
};
