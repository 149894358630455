import { ref, onMounted, type Ref } from '@vue/composition-api';
import staffTagApi from 'src/apis/workplace_masters/staffTag';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { convertStaffTagIndexResponse, type StaffTag } from 'src/models/workplaceMasters/staffTag';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  staffTags: Ref<StaffTag[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffTags');

async function getStaffTags({ workplaceId }: { workplaceId: number }): Promise<StaffTag[]> {
  const { data } = await staffTagApi.index({ workplace_id: workplaceId });
  return convertStaffTagIndexResponse(data);
}

export function useStaffTagsProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const staffTags = ref<StaffTag[]>([]);
  const fetchStaffTags = errorBoundary.wrap(
    async () => {
      staffTags.value = await getStaffTags({ workplaceId });
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  onMounted(fetchStaffTags);

  provide({
    staffTags: staffTags,
  });
}

export function useStaffTags(): InjectionValue {
  return inject();
}
