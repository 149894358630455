



import { defineComponent } from '@vue/composition-api';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { useActivityMappingCsvDownloadModal } from '../../composables/useActivityMappingCsvDownloadModal';

export default defineComponent({
  components: {
    NormalButton,
  },
  setup() {
    const { showActivityMappingCsvDownloadModal } = useActivityMappingCsvDownloadModal();

    return {
      showActivityMappingCsvDownloadModal,
    };
  },
});
