import { render, staticRenderFns } from "./AggregationTargetStaffTagListCell.vue?vue&type=template&id=6134577f&scoped=true"
import script from "./AggregationTargetStaffTagListCell.vue?vue&type=script&lang=ts"
export * from "./AggregationTargetStaffTagListCell.vue?vue&type=script&lang=ts"
import style0 from "./AggregationTargetStaffTagListCell.vue?vue&type=style&index=0&id=6134577f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6134577f",
  null
  
)

export default component.exports