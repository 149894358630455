import { reactive, watch } from '@vue/composition-api';
import { useNotification } from 'src/composables/useNotification';
import { createInjection } from 'src/util/createInjection';
import { BreakTimeSettingState } from '../types';
import { useModal } from 'src/composables/useModal';
import { useDailySimulation } from './useDailySimulation';

type InjectionValue = {
  state: BreakTimeSettingState;
  showsBreakTimeSettingModal: ReturnType<typeof useModal>['showsModal'];
  showBreakTimeSettingModal: ReturnType<typeof useModal>['showModal'];
  hideBreakTimeSettingModal: ReturnType<typeof useModal>['hideModal'];
  setBreakTimeSetting: (state: BreakTimeSettingState) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useBreakTimeSetting');

export function useBreakTimeSettingProvider(): void {
  const {
    showsModal: showsBreakTimeSettingModal,
    showModal: showBreakTimeSettingModal,
    hideModal: hideBreakTimeSettingModal,
  } = useModal();
  const { notifySuccess } = useNotification();
  const { displayedTimetable, updateBreakTime } = useDailySimulation();
  const state: BreakTimeSettingState = reactive({
    breakTimeSettings: [],
  });

  watch(
    () => showsBreakTimeSettingModal.value,
    () => {
      state.breakTimeSettings = displayedTimetable.value.map((timetableRow) => {
        return {
          timetableMasterId: timetableRow.header.masterId,
          timetableColor: timetableRow.header.backgroundColor,
          name: timetableRow.header.name,
          break1TimeRange: timetableRow.header.break1TimeRange,
          break2TimeRange: timetableRow.header.break2TimeRange,
        };
      });
    },
  );

  const setBreakTimeSetting = (nextBreakTimeSetting: BreakTimeSettingState) => {
    nextBreakTimeSetting.breakTimeSettings.forEach((item) => {
      updateBreakTime(item.timetableMasterId, item.break1TimeRange, item.break2TimeRange);
    });
    notifySuccess('休憩時刻を更新しました');
  };

  provide({
    state,
    showsBreakTimeSettingModal,
    showBreakTimeSettingModal,
    hideBreakTimeSettingModal,
    setBreakTimeSetting,
  });
}

export function useBreakTimeSetting(): InjectionValue {
  return inject();
}
