import { ref, onMounted, type Ref } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  timetableMasters: Ref<TimetableMaster[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableMasters');

export function useTimetableMastersProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const timetableMasters = ref<TimetableMaster[]>([]);
  const fetchTimetableMasters = errorBoundary.wrap(
    async () => {
      timetableMasters.value = await timetableMasterApi.index({
        workplaceId,
        params: { is_enabled: true, use_in_performance_board_detail: true },
      });
    },
    {
      fallbackMessage: '工程マスタの取得に失敗しました',
    },
  );

  onMounted(fetchTimetableMasters);

  provide({
    timetableMasters,
  });
}

export function useTimetableMasters(): InjectionValue {
  return inject();
}
