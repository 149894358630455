












































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  reactive,
  onMounted,
  onUnmounted,
  ref,
  getCurrentInstance,
  type ComputedRef,
  type PropType,
  watch,
} from '@vue/composition-api';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import KeyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import ForkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import CustomFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import CustomFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import CustomFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import KeyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import ForkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import CustomFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { packToTimeIntegerWithGuard } from 'src/util/datetime';
import { StaffGenderSelectOptions } from 'src/consts';
import type { WorkplaceExtension } from 'src/models/workplaceExtension';
import type { TimetableMaster } from 'src/models/timetableMaster';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';
import type { ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';
import { StaffWorkScheduleBulkUpdateParams } from 'src/models/api/staffWorkScheduleRequest';
import {
  BREAK_NUMBERS,
  EXTRA_DUTY_NUMBERS,
  WEEK,
  getDayValue,
  updateCandidateByShiftPattern,
  type WeekKey,
  type LabelName,
  type BulkUpdateCandidate,
  type StaffWorkScheduleFormValues,
} from './setting';
import ShiftPatternSelectModal from './components/ShiftPatternSelectModal.vue';
import { useShiftPatternSelectModal } from './composables/useShiftPatternSelectModal';
import { useDutyTimeValidation } from './composables/useDutyTimeValidation';
import { Staff } from 'src/models/staff';

const MACRO_OPERATION_MASTER_ID_NO_CHANGE = 0;

type State = {
  isLoaded: boolean;
  bulkUpdateCandidate: BulkUpdateCandidate;
  staffWorkScheduleFormValues: StaffWorkScheduleFormValues[];
  isStaffsSharingSameBudgetGroup: boolean;
  timetableMasters: TimetableMaster[];
  macroOperationMasters: MacroOperationMaster[];
  budgetGroupIds: number[];
  isFormEditable: boolean;
  isSelectedTimeTableMastersUnique: boolean;
  validations: Record<string, Record<string, unknown>>;
};

function getValidationMaps(state: State): Record<string, Object> {
  const ruleHour = { numeric: true, max: 2, max_value: 47 };
  const ruleMin = { numeric: true, max: 2, regex: /^(00|15|30|45)$/ };
  const staticRules = {
    work_start_time_hour: ruleHour,
    work_start_time_min: ruleMin,
    work_end_time_hour: ruleHour,
    work_end_time_min: ruleMin,
    break1_start_time_hour: ruleHour,
    break1_start_time_min: ruleMin,
    break1_end_time_hour: ruleHour,
    break1_end_time_min: ruleMin,
    break2_start_time_hour: ruleHour,
    break2_start_time_min: ruleMin,
    break2_end_time_hour: ruleHour,
    break2_end_time_min: ruleMin,
  };
  const { rules: dynamicRules } = useDutyTimeValidation(WEEK, state.staffWorkScheduleFormValues);
  return { ...staticRules, ...dynamicRules };
}

function initialBulkUpdateCandidateState(): BulkUpdateCandidate {
  return {
    staffId: null,
    isShiftManagementTarget: null,
    isAppropriationTarget: null,
    isKeyPlayer: null,
    isForkman: null,
    hasCustomSkill1: null,
    hasCustomSkill2: null,
    hasCustomSkill3: null,
    gender: null,
    staffWorkSchedules: [],
    macroOperationMasterId: null,
    staffSkills: [
      { priority: 1, timetableMasterId: null },
      { priority: 2, timetableMasterId: null },
      { priority: 3, timetableMasterId: null },
      { priority: 4, timetableMasterId: null },
      { priority: 5, timetableMasterId: null },
    ],
    isCleared: [],
    staffs: [],
  };
}

export default defineComponent({
  components: {
    KeyPlayerIcon,
    ForkliftIcon,
    CustomFlag1Icon,
    CustomFlag2Icon,
    CustomFlag3Icon,
    KeyPlayerGrayIcon,
    ForkliftGrayIcon,
    CustomFlagGrayIcon,
    ShiftPatternSelectModal,
  },
  name: 'StaffExtensionBulkEditModal',
  props: {
    staffs: {
      type: Array as PropType<Staff[]>,
      required: true,
    },
    resourceName: {
      type: String,
      required: false,
      default: 'スタッフ',
    },
    workplaceExtension: {
      type: Object as PropType<WorkplaceExtension>,
      required: true,
    },
  },
  emits: ['close', 'update'],
  setup(props, { emit }) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;

    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;
    const workplaceId = computed(() => {
      return Number(vueInstance.$route.params.workplaceId);
    });
    const budgetGroupId: ComputedRef<number | undefined> = computed(() => {
      return props.staffs[0]?.budget_group_id;
    });

    const state: State = reactive({
      isLoaded: false,
      budgetGroupIds: [],
      bulkUpdateCandidate: initialBulkUpdateCandidateState(),
      staffWorkScheduleFormValues: buildInitialStaffWorkScheduleFormValues(),
      timetableMasters: [],
      macroOperationMasters: [],
      isStaffsSharingSameBudgetGroup: computed(() => {
        return new Set(props.staffs.map((el) => el.budget_group_id)).size === 1;
      }),
      isFormEditable: computed(() => {
        return state.bulkUpdateCandidate.staffs.length <= 50;
      }),
      isSelectedTimeTableMastersUnique: computed(() => {
        const timetableMasterIds = state.bulkUpdateCandidate.staffSkills
          .map((staffSkill) => staffSkill.timetableMasterId)
          .filter((id): id is number => id !== null && id !== 0);
        const uniqueTimetableMasterIdSet = new Set(timetableMasterIds);
        return timetableMasterIds.length === uniqueTimetableMasterIdSet.size;
      }),
      validations: computed(() => getValidationMaps(state)),
    });

    const genderOptions = StaffGenderSelectOptions;

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });
    function clearErrors() {
      vvReset(vueInstance);
    }

    function buildInitialStaffWorkScheduleFormValues(): StaffWorkScheduleFormValues[] {
      const macroOperationMasterId = MACRO_OPERATION_MASTER_ID_NO_CHANGE;

      return WEEK.map((day) => {
        return {
          day_of_week: day.value,
          work_start_time_hour: null,
          work_start_time_min: null,
          work_end_time_hour: null,
          work_end_time_min: null,
          break1_start_time_hour: null,
          break1_start_time_min: null,
          break1_end_time_hour: null,
          break1_end_time_min: null,
          break2_start_time_hour: null,
          break2_start_time_min: null,
          break2_end_time_hour: null,
          break2_end_time_min: null,
          duty1_start_time_hour: null,
          duty1_start_time_min: null,
          duty1_end_time_hour: null,
          duty1_end_time_min: null,
          duty2_start_time_hour: null,
          duty2_start_time_min: null,
          duty2_end_time_hour: null,
          duty2_end_time_min: null,
          duty1_macro_operation_master_id: macroOperationMasterId,
          duty2_macro_operation_master_id: macroOperationMasterId,
        };
      });
    }

    async function prepareBulkUpdate() {
      state.bulkUpdateCandidate.staffs = props.staffs;
      const staffWorkSchedules = WEEK.map((dayOfWeek) => {
        return {
          day_of_week: dayOfWeek.value,
          work_start_time: null,
          work_end_time: null,
          break1_start_time: null,
          break1_end_time: null,
          break2_start_time: null,
          break2_end_time: null,
          duty1_macro_operation_master_id: 0,
          duty1_start_time: null,
          duty1_end_time: null,
          duty2_macro_operation_master_id: 0,
          duty2_start_time: null,
          duty2_end_time: null,
        };
      });
      state.bulkUpdateCandidate.staffWorkSchedules = staffWorkSchedules;
      state.bulkUpdateCandidate.macroOperationMasterId = 0;
      const staff_skills = [...Array(5)].map((_, i) => {
        return { priority: i + 1, timetableMasterId: 0 };
      });

      state.bulkUpdateCandidate.staffSkills = staff_skills;

      if (state.isStaffsSharingSameBudgetGroup && budgetGroupId.value !== undefined) {
        state.timetableMasters = await timetableMasterApi.index({
          workplaceId: workplaceId.value,
          params: {
            budget_group_id: budgetGroupId.value,
            is_enabled: true,
            use_in_staff_work_plan: true,
          },
        });

        state.macroOperationMasters = await macroOperationMasterApi.index({
          workplaceId: workplaceId.value,
          budgetGroupId: budgetGroupId.value,
          params: {
            is_enabled: true,
          },
        });
      }
    }

    async function onChangeTime(key: WeekKey, name: LabelName) {
      const dayOfWeek = getDayValue(key);
      if (dayOfWeek < 0) {
        return;
      }
      const staffWorkScheduleFormValue = state.staffWorkScheduleFormValues.find(
        (formValue) => formValue.day_of_week === dayOfWeek,
      );
      const staffWorkSchedule = state.bulkUpdateCandidate.staffWorkSchedules.find(
        (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek,
      );
      if (staffWorkScheduleFormValue !== undefined && staffWorkSchedule !== undefined) {
        staffWorkSchedule[name] = packToTimeIntegerWithGuard(
          staffWorkScheduleFormValue[`${name}_hour`],
          staffWorkScheduleFormValue[`${name}_min`],
          0,
        );
      }
      if (name.match('duty1')) {
        vvValidate(vueInstance, `${key}Duty1Time`);
      } else if (name.match('duty2')) {
        vvValidate(vueInstance, `${key}Duty2Time`);
      } else {
        vvValidate(vueInstance, key);
      }
    }

    async function onChangeDutyMacroOperationMaster(day: WeekKey, num: number) {
      if (num !== 1 && num !== 2) {
        return;
      }

      const dayValue = getDayValue(day);
      const staffWorkScheduleFormValue = state.staffWorkScheduleFormValues.find(
        (staffWorkScheduleFormValue) => staffWorkScheduleFormValue.day_of_week === dayValue,
      );
      if (staffWorkScheduleFormValue === undefined) {
        return;
      }

      const macroOperationMasterIdInput = staffWorkScheduleFormValue[`duty${num}_macro_operation_master_id`];
      const staffWorkSchedule = state.bulkUpdateCandidate.staffWorkSchedules.find(
        (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayValue,
      );
      if (staffWorkSchedule === undefined) {
        return;
      }
      staffWorkSchedule[`duty${num}_macro_operation_master_id`] = macroOperationMasterIdInput;
      staffWorkSchedule[`duty${num}_start_time`] =
        macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0
          ? staffWorkSchedule[`duty${num}_start_time`]
          : null;
      staffWorkSchedule[`duty${num}_end_time`] =
        macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0
          ? staffWorkSchedule[`duty${num}_end_time`]
          : null;

      if (macroOperationMasterIdInput !== null && macroOperationMasterIdInput !== 0) {
        vvValidate(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvValidate(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvValidate(vueInstance, `${day}Duty${num}Time`);
      } else {
        state.staffWorkScheduleFormValues.forEach((staffWorkScheduleFormValue) => {
          if (staffWorkScheduleFormValue.day_of_week === dayValue) {
            staffWorkScheduleFormValue[`duty${num}_start_time_hour`] = null;
            staffWorkScheduleFormValue[`duty${num}_start_time_min`] = null;
            staffWorkScheduleFormValue[`duty${num}_end_time_hour`] = null;
            staffWorkScheduleFormValue[`duty${num}_end_time_min`] = null;
          }
        });
        vvReset(vueInstance, `${day}Duty${num}StartTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}StartTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeHour`);
        vvReset(vueInstance, `${day}Duty${num}EndTimeMin`);
        vvReset(vueInstance, `${day}Duty${num}Time`);
      }
    }

    function attachValidate() {
      WEEK.forEach((dayOfWeek) => {
        vueInstance.$validator.attach({
          name: dayOfWeek.key,
          rules: 'custom_after2',
          getter: () => {
            const staffWorkSchedule = state.bulkUpdateCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.work_start_time,
              end_time: staffWorkSchedule.work_end_time,
              break1_start_time: staffWorkSchedule.break1_start_time,
              break1_end_time: staffWorkSchedule.break1_end_time,
              break2_start_time: staffWorkSchedule.break2_start_time,
              break2_end_time: staffWorkSchedule.break2_end_time,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty1Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.bulkUpdateCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty1_start_time,
              end_time: staffWorkSchedule.duty1_end_time,
              disallow_equal: true,
            };
          },
        });
        vueInstance.$validator.attach({
          name: `${dayOfWeek.key}Duty2Time`,
          rules: 'custom_after1',
          getter: () => {
            const staffWorkSchedule = state.bulkUpdateCandidate.staffWorkSchedules.find(
              (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek.value,
            );
            if (staffWorkSchedule === undefined) {
              return;
            }
            return {
              start_time: staffWorkSchedule.duty2_start_time,
              end_time: staffWorkSchedule.duty2_end_time,
              disallow_equal: true,
            };
          },
        });
      });
    }

    function detachValidate() {
      WEEK.forEach((day) => {
        vueInstance.$validator.detach(day.key);
        vueInstance.$validator.detach(`${day.key}Duty1Time`);
        vueInstance.$validator.detach(`${day.key}Duty2Time`);
      });
    }

    async function save() {
      if (!(await vvValidate(vueInstance))) {
        return;
      }
      await bulkUpdate();
    }

    function close() {
      emit('close');
    }

    async function bulkUpdate(): Promise<void> {
      const bulkUpdateCount = state.bulkUpdateCandidate.staffs.length;
      try {
        const macroOperationMasterId =
          state.bulkUpdateCandidate.macroOperationMasterId !== MACRO_OPERATION_MASTER_ID_NO_CHANGE
            ? state.bulkUpdateCandidate.macroOperationMasterId
            : undefined;

        const tmpStaffSkills = state.bulkUpdateCandidate.staffSkills
          .filter((obj) => obj.timetableMasterId !== MACRO_OPERATION_MASTER_ID_NO_CHANGE)
          .map((staffSkill) => {
            return {
              timetable_master_id: staffSkill.timetableMasterId,
              priority: staffSkill.priority,
            };
          });
        const staffSkills = tmpStaffSkills.length !== 0 ? tmpStaffSkills : undefined;

        const staffWorkSchedules: StaffWorkScheduleBulkUpdateParams[] =
          state.bulkUpdateCandidate.staffWorkSchedules.map((staffWorkSchedule) => {
            const dayOfWeek = getDayOfWeek(staffWorkSchedule.day_of_week);
            const clearTimes = dayOfWeek && state.bulkUpdateCandidate.isCleared.find((v) => v === dayOfWeek.key);
            const workStartTime =
              clearTimes || staffWorkSchedule.work_start_time !== null ? staffWorkSchedule.work_start_time : undefined;
            const workEndTime =
              clearTimes || staffWorkSchedule.work_end_time !== null ? staffWorkSchedule.work_end_time : undefined;
            const break1StartTime =
              clearTimes || staffWorkSchedule.break1_start_time !== null
                ? staffWorkSchedule.break1_start_time
                : undefined;
            const break1EndTime =
              clearTimes || staffWorkSchedule.break1_end_time !== null ? staffWorkSchedule.break1_end_time : undefined;
            const break2StartTime =
              clearTimes || staffWorkSchedule.break2_start_time !== null
                ? staffWorkSchedule.break2_start_time
                : undefined;
            const break2EndTime =
              clearTimes || staffWorkSchedule.break2_end_time !== null ? staffWorkSchedule.break2_end_time : undefined;

            const staffWorkScheduleBulkUpdateParams: StaffWorkScheduleBulkUpdateParams = {
              day_of_week: staffWorkSchedule.day_of_week,
              work_start_time: workStartTime,
              work_end_time: workEndTime,
              break1_start_time: break1StartTime,
              break1_end_time: break1EndTime,
              break2_start_time: break2StartTime,
              break2_end_time: break2EndTime,
            };

            if (staffWorkSchedule.duty1_macro_operation_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE) {
              Object.assign(staffWorkScheduleBulkUpdateParams, {
                duty1_macro_operation_master_id: staffWorkSchedule.duty1_macro_operation_master_id,
                duty1_start_time: staffWorkSchedule.duty1_start_time,
                duty1_end_time: staffWorkSchedule.duty1_end_time,
              });
            }
            if (staffWorkSchedule.duty2_macro_operation_master_id !== MACRO_OPERATION_MASTER_ID_NO_CHANGE) {
              Object.assign(staffWorkScheduleBulkUpdateParams, {
                duty2_macro_operation_master_id: staffWorkSchedule.duty2_macro_operation_master_id,
                duty2_start_time: staffWorkSchedule.duty2_start_time,
                duty2_end_time: staffWorkSchedule.duty2_end_time,
              });
            }
            return staffWorkScheduleBulkUpdateParams;
          });

        await staffExtensionApi.bulkUpdate({
          workplaceId: workplaceId.value,
          data: {
            is_shift_management_target: state.bulkUpdateCandidate.isShiftManagementTarget ?? undefined,
            is_appropriation_target: state.bulkUpdateCandidate.isAppropriationTarget ?? undefined,
            macro_operation_master_id: macroOperationMasterId,
            gender: state.bulkUpdateCandidate.gender ?? undefined,
            is_key_player: state.bulkUpdateCandidate.isKeyPlayer ?? undefined,
            is_forkman: state.bulkUpdateCandidate.isForkman ?? undefined,
            has_custom_skill1: state.bulkUpdateCandidate.hasCustomSkill1 ?? undefined,
            has_custom_skill2: state.bulkUpdateCandidate.hasCustomSkill2 ?? undefined,
            has_custom_skill3: state.bulkUpdateCandidate.hasCustomSkill3 ?? undefined,
            staff_skills: staffSkills,
            staff_work_schedules: staffWorkSchedules,
            staff_ids: state.bulkUpdateCandidate.staffs.map((e) => e.id),
          },
        });
        emit('update');
        close();
        notifySuccess1(vueInstance, `${props.resourceName}を${bulkUpdateCount}件一括編集しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_timetable_master') {
          const msg = '同じ担当工程は選択できません。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          const msg = `${props.resourceName}の編集に失敗しました。管理者に連絡してください。(ERR: ERR00003, user_id:${userId.value})`;
          notifyError1(vueInstance, msg, { err });
        }
      }
    }

    // 「シフトパターンから設定」関連

    const selectedShifts = ref<WeekKey[]>([]);

    const { shiftPatterns, showsShiftPatternSelectModal, showShiftPatternSelectModal, hideShiftPatternSelectModal } =
      useShiftPatternSelectModal();

    const handleClickShiftPatternSettingButton = () => {
      const budgetGroup = props.staffs[0].budget_group ?? null;
      if (budgetGroup !== null) {
        showShiftPatternSelectModal(budgetGroup);
      }
    };

    const handleSelectShiftPattern = (shiftPattern: ShiftPattern) => {
      updateCandidateByShiftPattern(
        state.bulkUpdateCandidate,
        state.staffWorkScheduleFormValues,
        selectedShifts.value,
        shiftPattern,
      );
    };
    function clearInputTimes(weekKey: WeekKey) {
      selectedShifts.value = selectedShifts.value.filter((v) => v !== weekKey);
      state.bulkUpdateCandidate.staffWorkSchedules?.forEach((obj) => {
        const dayOfWeek = getDayOfWeek(obj.day_of_week);
        if (dayOfWeek && dayOfWeek.key === weekKey) {
          obj.work_start_time = null;
          obj.work_end_time = null;
          obj.break1_start_time = null;
          obj.break1_end_time = null;
          obj.break2_start_time = null;
          obj.break2_end_time = null;
        }
      });
      state.staffWorkScheduleFormValues.forEach((times) => {
        const dayOfWeek = getDayOfWeek(times.day_of_week);
        if (dayOfWeek && dayOfWeek.key === weekKey) {
          times.work_start_time_hour = null;
          times.work_start_time_min = null;
          times.work_end_time_hour = null;
          times.work_end_time_min = null;
          times.break1_start_time_hour = null;
          times.break1_start_time_min = null;
          times.break1_end_time_hour = null;
          times.break1_end_time_min = null;
          times.break2_start_time_hour = null;
          times.break2_start_time_min = null;
          times.break2_end_time_hour = null;
          times.break2_end_time_min = null;
        }
      });
      // クリアした列のバリデーション結果をリセット
      vueInstance.$validator.detach(weekKey);
      vvReset(vueInstance, `${weekKey}WorkStartTimeHour`);
      vvReset(vueInstance, `${weekKey}WorkStartTimeMin`);
      vvReset(vueInstance, `${weekKey}WorkEndTimeHour`);
      vvReset(vueInstance, `${weekKey}WorkEndTimeMin`);
      vvReset(vueInstance, `${weekKey}Break1StartTimeHour`);
      vvReset(vueInstance, `${weekKey}Break1StartTimeMin`);
      vvReset(vueInstance, `${weekKey}Break1EndTimeHour`);
      vvReset(vueInstance, `${weekKey}Break1EndTimeMin`);
      vvReset(vueInstance, `${weekKey}Break2StartTimeHour`);
      vvReset(vueInstance, `${weekKey}Break2StartTimeMin`);
      vvReset(vueInstance, `${weekKey}Break2EndTimeHour`);
      vvReset(vueInstance, `${weekKey}Break2EndTimeMin`);
      attachValidate();
    }

    function getDayOfWeek(num: number) {
      return WEEK.find((day) => day.value === num);
    }

    const canInputTime = (weekKey: WeekKey) => {
      return (
        !state.bulkUpdateCandidate.isCleared.includes(weekKey) &&
        state.bulkUpdateCandidate.isShiftManagementTarget !== false
      );
    };

    watch(
      () => state.bulkUpdateCandidate.isShiftManagementTarget,
      (isShiftManagementTarget) => {
        if (isShiftManagementTarget === false) {
          WEEK.forEach(({ key }) => clearInputTimes(key));
          state.bulkUpdateCandidate.isCleared = [];
        }
      },
    );

    const gatedFuncGenerator = getGatedFuncGenerator();

    onMounted(async () => {
      state.isLoaded = false;
      attachValidate();
      await prepareBulkUpdate();
      state.isLoaded = true;
    });

    onUnmounted(() => {
      detachValidate();
    });

    return {
      state,
      close,
      genderOptions,
      hasError,
      getError,
      clearErrors,
      BREAK_NUMBERS,
      EXTRA_DUTY_NUMBERS,
      onChangeDutyMacroOperationMaster,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(save),
      WEEK,
      onChangeTime,
      selectedShifts,
      shiftPatterns,
      showsShiftPatternSelectModal,
      hideShiftPatternSelectModal,
      handleClickShiftPatternSettingButton,
      handleSelectShiftPattern,
      clearInputTimes,
      canInputTime,
    };
  },
});
