

















import { defineComponent, computed } from '@vue/composition-api';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { formatFixedDecimal } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const { progressPlanDataListWithCumulativeValues } = useProgressSummary();

    const displayedManHours = computed(() => ({
      result:
        progressHeader.value.result_man_hours !== null
          ? formatFixedDecimal(progressHeader.value.result_man_hours)
          : '-',
      planned:
        progressPlanDataListWithCumulativeValues.value.length > 0
          ? formatFixedDecimal(
              progressPlanDataListWithCumulativeValues.value[progressPlanDataListWithCumulativeValues.value.length - 1]
                .cumulativeManHours,
            )
          : '-',
    }));

    return {
      displayedManHours,
    };
  },
});
