import type { DeepReadonly } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { TIME_INTEGER_MAX_VALUE } from 'src/consts';

import staffAssignmentPlanApi from 'src/apis/staffAssignmentPlan';
import type { StaffWorkPlanRow } from '../types';
import type { StaffAssignmentPlanBulkUpsertResponse } from 'src/models/api/staffAssignmentPlanResponse';
import type { StaffAssignmentPlanBulkUpsertParams } from 'src/models/api/staffAssignmentPlanRequest';

type BuildParamsInputs = {
  workplaceId: number;
  budgetGroupId: number;
  workPlanHeaderId: number;
  staffWorkPlans: DeepReadonly<StaffWorkPlanRow[]>;
};

type InjectionValue = {
  bulkUpsert: (params: StaffAssignmentPlanBulkUpsertParams) => Promise<StaffAssignmentPlanBulkUpsertResponse>;
  buildParams: (inputs: BuildParamsInputs) => StaffAssignmentPlanBulkUpsertParams;
};

const { provide, inject } = createInjection<InjectionValue>('useUpsertStaffAssignmentPlan');

export const useUpsertStaffAssignmentPlanProvider = () => {
  const bulkUpsert = async (params: StaffAssignmentPlanBulkUpsertParams) => {
    return (await staffAssignmentPlanApi.bulkUpsert(params)).data;
  };

  const buildParams = ({
    workplaceId,
    budgetGroupId,
    workPlanHeaderId,
    staffWorkPlans,
  }: BuildParamsInputs): StaffAssignmentPlanBulkUpsertParams => {
    const staffAssignmentPlans = staffWorkPlans.map<
      StaffAssignmentPlanBulkUpsertParams['staff_assignment_plans'][number]
    >((staffWorkPlan) => {
      const dataList = staffWorkPlan.workPlans.map((workPlan) => ({
        timetable_master_id: workPlan.timetableMasterId,
        start_time: workPlan.startTime,
        end_time: workPlan.endTime,
      }));
      // 個人作業計画では実装の都合のためTimeIntegerの最大値を480000として扱っているが
      // アプリケーション全体として最大値は475959であるため保存できる形式に変更する
      if (dataList.length > 0 && dataList[dataList.length - 1].end_time === 480000) {
        dataList[dataList.length - 1].end_time = TIME_INTEGER_MAX_VALUE;
      }
      return {
        staff_id: staffWorkPlan.staffId,
        data: dataList,
      };
    });
    return {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      work_plan_header_id: workPlanHeaderId,
      staff_assignment_plans: staffAssignmentPlans,
    };
  };

  provide({
    bulkUpsert,
    buildParams,
  });
};

export const useUpsertStaffAssignmentPlan = () => {
  return inject();
};
