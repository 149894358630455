








import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { Content } from '../Content';
import { ControlPanel } from '../ControlPanel';
import { MainPanel } from '../MainPanel';
import { useSearchConditionsProvider } from 'src/composables/useSearchConditions';
import { useProgressBoardProvider } from '../../composables/useProgressBoard';
import { useAttendanceProvider } from 'src/composables/useAttendance';

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);

    setPageName(root, '進捗一覧');
    useBudgetGroupsProvider({ workplaceId });
    useSearchConditionsProvider();
    useProgressBoardProvider();
    useAttendanceProvider();
  },
});
