import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import { type StaffTagIndexRequest } from 'src/models/api/workplaceMasters/staffTagRequest';
import { type StaffTagIndexResponse } from 'src/models/api/workplaceMasters/staffTagResponse';

export default {
  index({ workplace_id }: StaffTagIndexRequest): Promise<AxiosResponse<StaffTagIndexResponse>> {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplace_id}/staff_tags`));
  },
};
