




















































import { defineComponent } from '@vue/composition-api';
import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { EditableTimeRange } from 'src/components/UIComponents/Inputs/EditableTimeRange';
import { useTimeRangeValidator } from 'src/composables/useTimeRangeValidator';
import { useCustomValidator } from 'src/composables/useCustomValidator';
import { useBreakTimeSetting } from '../composables/useBreakTimeSetting';

export default defineComponent({
  components: {
    EditableTimeRange,
    FormGroup,
    FormItem,
    ModalForm,
  },
  setup() {
    const { state, hideBreakTimeSettingModal, setBreakTimeSetting, showsBreakTimeSettingModal } = useBreakTimeSetting();

    const { in15StepMinutesValidator, afterOrEqualValidator } = useTimeRangeValidator();
    const in15StepMinutesRules = useCustomValidator(in15StepMinutesValidator);
    const afterOrEqualRules = useCustomValidator(afterOrEqualValidator);
    const break1TimeRangeRules = in15StepMinutesRules;
    const getBreak2TimeRangeRules = (id: number) =>
      `${in15StepMinutesRules}|${afterOrEqualRules}:break1TimeRange_${id}`;

    const onSubmit = async () => {
      setBreakTimeSetting(state);
      hideBreakTimeSettingModal();
    };

    return {
      break1TimeRangeRules,
      showsBreakTimeSettingModal,
      state,
      getBreak2TimeRangeRules,
      hideBreakTimeSettingModal,
      onSubmit,
    };
  },
});
