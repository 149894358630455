import { useModal } from 'src/composables/useModal';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  showsActivityMappingCsvDownloadModal: ReturnType<typeof useModal>['showsModal'];
  showActivityMappingCsvDownloadModal: ReturnType<typeof useModal>['showModal'];
  hideActivityMappingCsvDownloadModal: ReturnType<typeof useModal>['hideModal'];
};

const { provide, inject } = createInjection<InjectionValue>('useActivityMappingCsvDownloadModal');

export function useActivityMappingCsvDownloadModalProvider(): void {
  const {
    showsModal: showsActivityMappingCsvDownloadModal,
    showModal: showActivityMappingCsvDownloadModal,
    hideModal: hideActivityMappingCsvDownloadModal,
  } = useModal();

  provide({
    showsActivityMappingCsvDownloadModal,
    showActivityMappingCsvDownloadModal,
    hideActivityMappingCsvDownloadModal,
  });
}

export function useActivityMappingCsvDownloadModal(): InjectionValue {
  return inject();
}
