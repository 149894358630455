// TODO ディレクトリ名を DailyBoardReview に変更予定
import Vue from 'vue';
import { defineComponent, SetupContext, reactive, onMounted, ref, computed, nextTick } from '@vue/composition-api';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import planBoardApi from 'src/apis/planBoard';
import timelineApi from 'src/apis/timeline';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { isExist } from 'src/util/isExist';
import TimeRangeInput from 'src/components/Workplace/TimeRangeInput/index.vue';
import { packToTimeInteger, unpackTimeInteger, unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { TimelineState, TIMELINE_COLUMN_WIDTH, TimelineOverviewState, DayHeadcount } from './types';
import { PLAN_BOARD_REGULAR_MASTER_ID } from '../PlanBoard/const';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import {
  createTimetablesFromApiResponse,
  createTotalHeadcountHourBlocksFromApiResponse,
  createHeadcountTotal,
  getBlockLengthPerHour,
  updateHeaderAndBoardHourBlocks,
} from './timelineHelper';
import { PlanBoardIndexResult } from 'src/models/planBoard';
import { BudgetGroup } from 'src/models/budgetGroup';
import { Staff } from 'src/models/staff';
import {
  createCheckListFromApiResponse,
  createShiftInputFromApiResponse,
  createShiftInputRegularFromApiResponse,
  createTimetablesFromApiResponse as createPlanBoardTimetablesFromApiResponse,
  createTotalHeadcountHourBlocksFromApiResponse as createPlanBoardTotalHeadcountHourBlocksFromApiResponse,
} from '../PlanBoard/planBoardHelper';
import { dailyBoardPageOptions } from 'src/consts';

const setupState = (context: SetupContext): TimelineState => {
  const root = context.root as Vue;

  const displayHourPeriod = 48; // 表示したい時間数: 現状外部からの指定はないので 48 固定
  const state: TimelineState = reactive({
    ...wrappedMapGetters(root.$store, 'workplace', ['workplaceExtension']),
    budgetGroup: null,
    budgetGroups: [],
    baseDate: new Date(),
    workplaceId: root.$route.params.workplaceId,
    timetableMasters: [],
    timetables: [],
    displayTimes: new Array(displayHourPeriod).fill(0).map((startHour, i) => `${startHour + i}:00`),
    displayStartHour: computed(() => state.workplaceExtension?.timetable_start_hour ?? 8),
    displayHourPeriod,
    totalHeadcountHourBlocks: [],
    blockLengthPerHour: 4,
    lastSaveTimestamp: '',
    lastSavedBy: '',
    targetStartTime: 0,
    targetEndTime: packToTimeInteger(24, 0, 0),
    isLoading: false,
  });
  state.targetStartTime = packToTimeInteger(state.workplaceExtension?.timetable_start_hour ?? 8, 0, 0);
  return state;
};

const setupOverviewState = (timelineState: TimelineState) => {
  const overviewState: TimelineOverviewState = reactive({
    headcountTotal: createHeadcountTotal(timelineState, []),
    shiftRegular: { total: 0, targetTimeRange: 0 },
    shiftItems: [],
    shiftItemsTotal: { total: 0, targetTimeRange: 0 },
    checkList: [
      { name: 'キープレイヤー', key: 'is_key_player', icon: 'fa-star', color: '#ffc000', total: 0, targetTimeRange: 0 },
      { name: 'フォークマン', key: 'is_forkman', icon: 'fa-forklift', color: '#b1c796', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill1', icon: 'fa-flag', color: '#ed7d31', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill2', icon: 'fa-flag', color: '#8497b0', total: 0, targetTimeRange: 0 },
      { name: '-', key: 'has_custom_skill3', icon: 'fa-flag', color: '#222a35', total: 0, targetTimeRange: 0 },
    ],
    memo: '',
  });
  return overviewState;
};

export default defineComponent({
  components: {
    TimeRangeInput,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '当日ボード');
    const state = setupState(context);
    const overviewState = setupOverviewState(state);
    const timetableStyle = {
      gridTemplateColumns: `repeat(${state.displayHourPeriod}, ${TIMELINE_COLUMN_WIDTH}px)`,
      display: 'grid',
    };
    const tablesRef = ref<HTMLDivElement>();
    const overviewApiResponse = ref<PlanBoardIndexResult | null>(null);
    const routeName = root.$route.name;

    onMounted(async () => {
      await ensureUserAndMasters(context);
      state.budgetGroups = await budgetGroupApi.index(state.workplaceId);
      state.budgetGroup = state.budgetGroups[0];
    });

    /**
     * API の値で表をリセットする
     */
    const refreshTimeline = async (): Promise<void> => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }
      state.isLoading = true;

      try {
        state.timetableMasters = await timetableMasterApi.index({
          workplaceId: state.workplaceId,
          params: {
            budget_group_id: state.budgetGroup.id,
            use_in_staff_work_plan: true,
          },
        });
      } catch (err: any) {
        notifyError1(root, '工程マスタの取得に失敗しました', { err });
        return;
      } finally {
        state.isLoading = false;
      }

      const timelineResponsePromise = timelineApi.timelineIndex({
        workplace_id: state.workplaceId,
        budget_group_id: state.budgetGroup.id,
        dt: state.baseDate,
      });
      const planBoardResponsePromise = planBoardApi.planBoardIndex({
        workplace_id: state.workplaceId,
        budget_group_id: state.budgetGroup.id,
        dt: state.baseDate,
      });

      let timelineResponse, planBoardResponse;
      try {
        [timelineResponse, planBoardResponse] = await Promise.all([timelineResponsePromise, planBoardResponsePromise]);
      } catch (err: any) {
        notifyError1(root, '表示情報の取得に失敗しました', { err });
        return;
      }
      overviewApiResponse.value = planBoardResponse;
      state.blockLengthPerHour = getBlockLengthPerHour(timelineResponse);
      state.timetables = createTimetablesFromApiResponse(
        timelineResponse,
        planBoardResponse,
        state.blockLengthPerHour,
        state.displayHourPeriod,
        state.timetableMasters,
      );
      state.totalHeadcountHourBlocks = createTotalHeadcountHourBlocksFromApiResponse(
        state.timetables,
        state.blockLengthPerHour,
        state.displayHourPeriod,
      );

      state.timetables.forEach((_, i) => updateTimetable(i));

      const staffs = await fetchStaffs(parseInt(state.workplaceId), state.budgetGroup);
      updateOverViewState(planBoardResponse, staffs);

      nextTick(() => {
        if (isExist(tablesRef.value)) {
          const [startHour] = unpackTimeInteger(state.targetStartTime);
          tablesRef.value.scrollLeft = startHour * TIMELINE_COLUMN_WIDTH;
        }
      });
    };

    const fetchStaffs = async (workplaceId: number, budgetGroup: BudgetGroup): Promise<Staff[]> => {
      return await staffExtensionApi.staffs({
        workplaceId,
        params: {
          staff_number: null,
          staff_id: null,
          budget_group_id: budgetGroup.id,
          staff_agency_id: null,
          staff_label_id: null,
          is_enabled: true,
        },
      });
    };

    /**
     * サマリ欄の情報を更新する
     */
    const updateOverViewState = async (planBoardResponse: PlanBoardIndexResult, staffs: Staff[]) => {
      if (state.budgetGroup == null) {
        return;
      }

      overviewState.shiftRegular = createShiftInputRegularFromApiResponse(
        planBoardResponse.staff_shifts,
        staffs,
        state,
      );
      overviewState.shiftItems = createShiftInputFromApiResponse(planBoardResponse.collective_staff_shifts, state);
      overviewState.shiftItemsTotal = createShiftItemsTotal(overviewState.shiftRegular, overviewState.shiftItems);

      // headcountTotal のシフト値は plan_boards の値を利用するため、planBoardResponse の値から算出する（経緯: https://github.com/kurando-inc/logiboard-ap/issues/959#issuecomment-1886634160）
      const planBoardTimetables = createPlanBoardTimetablesFromApiResponse(
        planBoardResponse,
        state.blockLengthPerHour,
        state.displayHourPeriod,
        state.workplaceExtension,
      );
      const planBoardTotalHeadcountHourBlocks = createPlanBoardTotalHeadcountHourBlocksFromApiResponse(
        planBoardTimetables,
        state.blockLengthPerHour,
        state.displayHourPeriod,
      );
      overviewState.headcountTotal = createHeadcountTotal(state, planBoardTotalHeadcountHourBlocks);

      overviewState.checkList = createCheckListFromApiResponse(
        overviewState.checkList,
        planBoardResponse.staff_shifts,
        state,
        state.workplaceExtension,
      );
      overviewState.memo = planBoardResponse.budget_group_plan_board_misc?.memo ?? '';
    };

    /**
     * シフト内訳を更新する
     */
    const createShiftItemsTotal = (
      shiftRegular: DayHeadcount,
      shiftItems: Array<{ name: string; headcount: DayHeadcount }>,
    ) => {
      const shiftItemsTotal = {
        total: shiftRegular.total,
        targetTimeRange: shiftRegular.targetTimeRange,
      };
      shiftItems.forEach((shift) => {
        shiftItemsTotal.targetTimeRange += shift.headcount.targetTimeRange;
        shiftItemsTotal.total += shift.headcount.total;
      });
      return shiftItemsTotal;
    };

    /**
     * 工程情報を更新する
     */
    const updateTimetable = (targetTimetableIndex: number) => {
      const targetTimetable = state.timetables[targetTimetableIndex];
      if (!isExist(targetTimetable)) {
        return;
      }

      const { header: targetHeader, boardHourBlocks: targetBoardHourBlocks } = updateHeaderAndBoardHourBlocks(
        targetTimetable.header,
        targetTimetable.boardHourBlocks.map((boardHourBlock) => boardHourBlock.headcountList),
        state.blockLengthPerHour,
      );
      targetTimetable.header = targetHeader;
      targetTimetable.boardHourBlocks = targetBoardHourBlocks;
    };

    /**
     * メモの更新
     */
    const saveMemo = async () => {
      if (!state.baseDate) {
        return;
      }
      if (!state.budgetGroup) {
        return;
      }
      if (!isExist(overviewApiResponse.value)) {
        return;
      }
      if (overviewState.memo === overviewApiResponse.value.budget_group_plan_board_misc?.memo) {
        return;
      }

      try {
        const budget_group_plan_board_misc = await budgetGroupPlanBoardMiscApi.upsert({
          workplace_id: state.workplaceId,
          budget_group_id: state.budgetGroup.id,
          dt: state.baseDate,
          memo: overviewState.memo,
        });
        overviewApiResponse.value.budget_group_plan_board_misc = budget_group_plan_board_misc;
        notifySuccess1(root, 'メモを更新しました');
      } catch (err: any) {
        notifyError1(root, 'メモの更新に失敗しました', { err });
      }
    };

    const formatNumber = (num: number) => {
      return parseFloat(num.toFixed(1));
    };

    const onTimeRangeInputBlur = async (range: { startTime: number; endTime: number }) => {
      state.targetStartTime = range.startTime;
      state.targetEndTime = range.endTime;

      // 絞り込み時間変更時にもサマリ欄を更新する https://github.com/kurando-inc/logiboard-ap/issues/608
      const planBoardResponse = overviewApiResponse.value;
      if (isExist(state.budgetGroup)) {
        const staffs = await fetchStaffs(parseInt(state.workplaceId), state.budgetGroup);
        if (isExist(planBoardResponse)) {
          updateOverViewState(planBoardResponse, staffs);
        }
      }
    };

    const displayTimeRange = () => {
      return `${unpackTimeIntegerToStringFormat(state.targetStartTime)} - ${unpackTimeIntegerToStringFormat(
        state.targetEndTime,
      )}`;
    };

    const toFixed = (num: number | null | undefined, digits: number): string => {
      if (!isExist(num)) {
        return '';
      }
      return num.toFixed(digits);
    };

    const linkTo = (pageName: string) => {
      root.$router.push({ name: pageName });
    };

    const filteredTimetables = computed(() => {
      return state.timetables.filter((timetable) => timetable.masterId >= 0);
    });

    return {
      state,
      overviewState,
      timetableStyle,
      tablesRef,
      routeName,
      dailyBoardPageOptions,
      formatNumber,
      displayTimeRange,
      refreshTimeline,
      saveMemo,
      onTimeRangeInputBlur,
      toFixed,
      isExist,
      linkTo,
      PLAN_BOARD_REGULAR_MASTER_ID,
      filteredTimetables,
    };
  },
});
