

















import { defineComponent, ref, watch } from '@vue/composition-api';
import { ModalForm, FormItem } from 'src/components/UIComponents/Form';
import { type StaffTag } from 'src/models/workplaceMasters/staffTag';
import { useCreateAggregationTargetStaffTag } from '../../composables/useCreateAggregationTargetStaffTag';
import { StaffTagSelect } from '../StaffTagSelect';
import AggregationTargetStaffTagCreateModalFormGroup from './AggregationTargetStaffTagCreateModalFormGroup.vue';

export default defineComponent({
  components: {
    ModalForm,
    FormItem,
    StaffTagSelect,
    AggregationTargetStaffTagCreateModalFormGroup,
  },
  setup() {
    const {
      showsAggregationTargetStaffTagCreateModal,
      hideAggregationTargetStaffTagCreateModal,
      addableStaffTags,
      createAggregationTargetStaffTag,
    } = useCreateAggregationTargetStaffTag();
    const staffTag = ref<StaffTag | null>(null);
    const handleSubmit = async () => {
      await createAggregationTargetStaffTag(staffTag.value!);
    };

    watch(showsAggregationTargetStaffTagCreateModal, () => {
      staffTag.value = null;
    });

    return {
      showsAggregationTargetStaffTagCreateModal,
      hideAggregationTargetStaffTagCreateModal,
      addableStaffTags,
      staffTag,
      handleSubmit,
    };
  },
});
