




































import { defineComponent, computed } from '@vue/composition-api';
import { format } from 'date-fns';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useNotification } from 'src/composables/useNotification';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useMapping } from 'src/composables/useMapping';
import { useStaffWorkPlan } from '../../../composables/useStaffWorkPlan';
import { useWorkPlanHeader } from '../../../composables/useWorkPlanHeader';
import { useUpsertTimetableAllocationPlan } from '../../../composables/useUpsertTimetableAllocationPlan';
import { useUpsertStaffAssignmentPlan } from '../../../composables/useUpsertStaffAssignmentPlan';
import { useProgressHeaders } from '../../../composables/useProgressHeaders';
import { useValidator } from '../../../composables/useValidator';

export default defineComponent({
  components: {
    PrimaryButton,
  },
  props: {},
  setup() {
    const { notifySuccess, notifyError } = useNotification();
    const errorBoundary = useErrorBoundary();

    const { state, projectTimetableAllocationPlanToTimeBlocks, projectStaffAssignmentPlanToWorkPlans } =
      useStaffWorkPlan();
    const { lastFetchParams } = useProgressHeaders();
    const { workPlanHeader, fetchWorkPlanHeader, upsertWorkPlanHeader } = useWorkPlanHeader();

    const { bulkUpsert: bulkUpsertTimetableAllocationPlans, buildParams: buildTimetableAllocationPlanParams } =
      useUpsertTimetableAllocationPlan();
    const { bulkUpsert: bulkUpsertStaffAssignmentPlans, buildParams: buildStaffAssignmentPlanParams } =
      useUpsertStaffAssignmentPlan();

    const isDisabled = computed(() => {
      return state.isLoading || lastFetchParams.value === null;
    });

    const { validateTimeBlock } = useValidator();

    const saveCurrentWorkPlans = errorBoundary.wrap(
      async () => {
        const isRequiredHeadcountValid = state.headcountTimetable
          .flatMap(({ timeBlocks }) => Object.values(timeBlocks))
          .every(validateTimeBlock);
        if (!isRequiredHeadcountValid) {
          notifyError('入力に誤りがあります');
          return;
        }
        if (lastFetchParams.value === null) {
          return;
        }
        const workPlanUpsertResponse = await upsertWorkPlanHeader({
          ...lastFetchParams.value,
          dt: format(lastFetchParams.value.dt, 'yyyy-MM-dd'),
        });
        const [timetableResult, staffAssignmentResult] = await Promise.allSettled([
          bulkUpsertTimetableAllocationPlans(
            buildTimetableAllocationPlanParams({
              ...lastFetchParams.value,
              workPlanHeaderId: workPlanUpsertResponse.id,
              headcountTimetable: state.headcountTimetable,
            }),
          ),
          bulkUpsertStaffAssignmentPlans(
            buildStaffAssignmentPlanParams({
              ...lastFetchParams.value,
              workPlanHeaderId: workPlanUpsertResponse.id,
              staffWorkPlans: state.staffWorkPlans,
            }),
          ),
        ]);
        const timetableOk = timetableResult.status === 'fulfilled';
        const staffOk = staffAssignmentResult.status === 'fulfilled';
        if (timetableOk && staffOk) {
          notifySuccess('個人作業計画を保存しました');
          return;
        }
        if (!timetableOk && !staffOk) {
          // ErrorをthrowしてfallbackMessageを表示する
          throw new Error();
        }
        if (timetableOk) {
          notifySuccess('工程の必要人数を保存しました');
        } else {
          notifyError('工程の必要人数の保存に失敗しました');
        }
        if (staffOk) {
          notifySuccess('スタッフの工程割り当てを保存しました');
        } else {
          notifyError('スタッフの工程割り当ての保存に失敗しました');
        }
      },
      {
        fallbackMessage: '個人作業計画の保存に失敗しました',
      },
    );
    const { mapBy } = useMapping();
    const loadAndProjectWorkPlanToCurrentStates = errorBoundary.wrap(
      async () => {
        if (lastFetchParams.value === null) {
          return;
        }
        await fetchWorkPlanHeader({ ...lastFetchParams.value, dt: format(lastFetchParams.value.dt, 'yyyy-MM-dd') });
        if (workPlanHeader.value === null) {
          return;
        }
        projectTimetableAllocationPlanToTimeBlocks(
          mapBy(workPlanHeader.value.timetableAllocationPlans, 'timetableMasterId'),
        );
        projectStaffAssignmentPlanToWorkPlans(mapBy(workPlanHeader.value.staffAssignmentPlans, 'staffId'));
        notifySuccess('保存結果を表示しました');
      },
      {
        fallbackMessage: '保存結果がありません',
      },
    );

    return {
      isDisabled,
      saveCurrentWorkPlans,
      loadAndProjectWorkPlanToCurrentStates,
    };
  },
});
