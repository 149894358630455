import { TimeInteger } from 'src/models/common';
import {
  packToTimeInteger,
  timeDifferenceInSeconds,
  timeIntegerToHours,
  unpackTimeIntegerToStringFormat,
} from 'src/util/datetime';
import { BLOCK_LENGTH_PER_HOUR, DISPLAY_HOUR_PERIOD, HOUR_BLOCK_WIDTH } from './consts';
import { StaffWorkPlan } from './types';
import { isExist } from 'src/util/isExist';

export const getPositionFromTime = (time: TimeInteger, hourBlockWidth = HOUR_BLOCK_WIDTH, basePosition = 0) => {
  return timeIntegerToHours(time) * hourBlockWidth - basePosition;
};

export const getBreakTimeStyle = (
  breakStartTime: TimeInteger | null,
  breakEndTime: TimeInteger | null,
  hourBlockWidth = HOUR_BLOCK_WIDTH,
  basePosition = 0,
): Record<string, string> => {
  if (!isExist(breakStartTime) || !isExist(breakEndTime)) {
    return {
      display: 'none',
    };
  }
  const startPosition = getPositionFromTime(breakStartTime, hourBlockWidth, basePosition);
  const endPosition = getPositionFromTime(breakEndTime, hourBlockWidth, basePosition);
  return {
    left: `${startPosition}px`,
    width: `${Math.max(0, endPosition - startPosition)}px`,
  };
};

export const getWorkPlanStyle = (
  workPlan: StaffWorkPlan,
  hourBlockWidth = HOUR_BLOCK_WIDTH,
  basePosition = 0,
): Record<string, string> => {
  const startPosition = getPositionFromTime(workPlan.startTime, hourBlockWidth, basePosition);
  const endPosition = getPositionFromTime(workPlan.endTime, hourBlockWidth, basePosition);
  return {
    left: `${startPosition}px`,
    width: `${Math.max(0, endPosition - startPosition)}px`,
    backgroundColor: `#${workPlan.backgroundColor}`,
  };
};

export const getMoveLimit = (baseTime: TimeInteger, workPlans: StaffWorkPlan[]) => {
  const baseBlockNum = Math.round(timeIntegerToHours(baseTime) * BLOCK_LENGTH_PER_HOUR);
  return workPlans.reduce(
    ({ min, max }, { startTime, endTime }) => {
      const nextMin = Math.round(timeDifferenceInSeconds(baseTime, endTime) / (3600 / BLOCK_LENGTH_PER_HOUR));
      const nextMax = Math.round(timeDifferenceInSeconds(baseTime, startTime) / (3600 / BLOCK_LENGTH_PER_HOUR)) - 1;
      return {
        min: nextMin <= 0 ? nextMin : min,
        max: nextMax >= 0 ? Math.min(nextMax, max) : max,
      };
    },
    { min: -baseBlockNum, max: DISPLAY_HOUR_PERIOD * BLOCK_LENGTH_PER_HOUR - baseBlockNum - 1 },
  );
};

export const createDisplayTimes = ({
  startHour,
  displayHourPeriod,
}: {
  startHour?: number;
  displayHourPeriod: number;
}) => {
  const displayTimes: string[] = [];
  for (let hour = startHour ?? 0; hour < displayHourPeriod; hour++) {
    displayTimes.push(unpackTimeIntegerToStringFormat(packToTimeInteger(hour, 0, 0)));
  }
  return displayTimes;
};

export const regularizePrintHours = (startHourStr: string, endHourStr: string) => {
  let startHour = Number(startHourStr);
  let endHour = Number(endHourStr) + 1;

  if (isNaN(startHour)) {
    startHour = 0;
  }
  if (isNaN(endHour)) {
    endHour = 1;
  }

  startHour = Math.min(Math.max(startHour, 0), DISPLAY_HOUR_PERIOD - 1);
  endHour = Math.min(Math.max(endHour, 0), DISPLAY_HOUR_PERIOD);
  if (startHour >= endHour) {
    endHour = startHour + 1;
  }

  if (endHour - startHour > 24) {
    endHour = startHour + 24;
  }

  return {
    startHour,
    endHour,
  };
};
