import { SetupContext } from '@vue/composition-api';
import Vue from 'vue';
import axios from 'axios';
import { isGuestPage } from 'src/hooks/routeHook';

export function setupAxios(context: SetupContext): void {
  const root = context.root as Vue;
  // base url
  axios.defaults.baseURL = '/api/v1';

  // token
  const token = root.$store.getters['localStorage/serverToken'];
  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
  }

  // go to login if 401 is returned on response
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let st = 400;
      if (error.response) {
        st = error.response.status;
      }
      if (st === 401 && !isGuestPage(root.$router.currentRoute.name)) {
        root.$router.push('/login');
      } else if (st === 503) {
        // メンテナンス時はALBで503とともに静的なhtmlを返すようにする.
        // どこでもいいので一旦リロードすればブラウザにそのhtmlが表示される.
        window.location.href = '/maintenance';
      }
      return Promise.reject(error);
    },
  );
}
