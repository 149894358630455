









































import { defineComponent } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useProgressBoard } from '../../composables/useProgressBoard';
import { useSearchConditions } from 'src/composables/useSearchConditions';
import { useAttendance } from 'src/composables/useAttendance';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    PrimaryButton,
    ToggleSwitch,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { dt, budgetGroup } = useSearchConditions();

    const {
      canSearch,
      canUpdate,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedTimetableHeaders,
      fetchProgressBoard,
    } = useProgressBoard();

    const { fetchAttendanceData } = useAttendance();

    function fetchData() {
      fetchProgressBoard();
      fetchAttendanceData();
    }

    return {
      budgetGroups,
      dt,
      budgetGroup,
      canSearch,
      canUpdate,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedTimetableHeaders,
      fetchData,
    };
  },
});
