





















































import { computed, defineComponent, inject, SetupContext, watch } from '@vue/composition-api';
import { ON_CURRENT_PAGE_CHANGE_KEY } from 'src/components/UIComponents/PaginationContainer.vue';

export default defineComponent({
  name: 'p-pagination',
  props: {
    type: {
      type: String,
      default: 'default',
      description: 'Pagination type (primary|info|danger|default|warning|success)',
    },
    pageCount: {
      type: Number,
      default: 0,
      description: 'Pagination page count. This should be specified in combination with perPage',
    },
    perPage: {
      type: Number,
      default: 10,
      description: 'Pagination per page. Should be specified with total or pageCount',
    },
    total: {
      type: Number,
      default: 0,
      description: 'Can be specified instead of pageCount. The page count in this case will be total/perPage',
    },
    value: {
      type: Number,
      default: 1,
      description: 'Pagination value',
    },
  },
  setup(props, context: SetupContext) {
    const defaultPagesToDisplay = 5;
    const onCurrentPageChange = inject(ON_CURRENT_PAGE_CHANGE_KEY, (_: number) => {});

    const paginationClass = computed(() => {
      return `pagination-${props.type}`;
    });
    const totalPages = computed(() => {
      if (props.pageCount > 0) {
        return props.pageCount;
      }
      if (props.total > 0) {
        return Math.ceil(props.total / props.perPage);
      }
      return 1;
    });
    const pagesToDisplay = computed(() => {
      if (totalPages.value > 0 && totalPages.value < defaultPagesToDisplay) {
        return totalPages.value;
      }
      return defaultPagesToDisplay;
    });
    const minPage = computed(() => {
      if (props.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2);
        const newMaxPage = pagesToAdd + props.value;
        if (newMaxPage > totalPages.value) {
          return totalPages.value - pagesToDisplay.value + 1;
        }
        return props.value - pagesToAdd;
      } else {
        return 1;
      }
    });
    const maxPage = computed(() => {
      if (props.value >= pagesToDisplay.value) {
        const pagesToAdd = Math.floor(pagesToDisplay.value / 2);
        const newMaxPage = pagesToAdd + props.value;
        if (newMaxPage < totalPages.value) {
          return newMaxPage;
        } else {
          return totalPages.value;
        }
      } else {
        return pagesToDisplay.value;
      }
    });

    const emitValue = (value: number): void => {
      context.emit('input', value);
      onCurrentPageChange(value);
    };

    watch(
      () => props.perPage,
      () => {
        emitValue(1);
      },
    );
    watch(
      () => props.total,
      () => {
        emitValue(1);
      },
    );

    function range(min: number, max: number) {
      const arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    }

    function changePage(item: number) {
      emitValue(item);
    }

    function nextPage() {
      if (props.value < totalPages.value) {
        emitValue(props.value + 1);
      }
    }

    function prevPage() {
      if (props.value > 1) {
        emitValue(props.value - 1);
      }
    }

    return {
      paginationClass,
      totalPages,
      minPage,
      maxPage,

      range,
      changePage,
      nextPage,
      prevPage,
    };
  },
});
