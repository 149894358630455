import { type StaffTagIndexResponse } from 'src/models/api/workplaceMasters/staffTagResponse';

export type StaffTag = {
  id: number;
  workplace_id: number;
  value: string;
  disp_order: number;
};

export function convertStaffTagIndexResponse(response: StaffTagIndexResponse): StaffTag[] {
  return response;
}
