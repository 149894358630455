























import { defineComponent, computed } from '@vue/composition-api';
import { CreateButton } from 'src/components/UIComponents/Buttons/CreateButton';
import { useAggregationTargetStaffTags } from '../../composables/useAggregationTargetStaffTags';
import { useCreateAggregationTargetStaffTag } from '../../composables/useCreateAggregationTargetStaffTag';
import { useDeleteAggregationTargetStaffTag } from '../../composables/useDeleteAggregationTargetStaffTag';
import { useStaffTags } from '../../composables/useStaffTags';
import { AggregationTargetStaffTagList, AggregationTargetStaffTagListItem } from '../AggregationTargetStaffTagList';

export default defineComponent({
  components: {
    CreateButton,
    AggregationTargetStaffTagList,
    AggregationTargetStaffTagListItem,
  },
  setup() {
    const { aggregationTargetStaffTags } = useAggregationTargetStaffTags();
    const { showAggregationTargetStaffTagCreateModal } = useCreateAggregationTargetStaffTag();
    const { showAggregationTargetStaffTagDeleteConfirmationModal } = useDeleteAggregationTargetStaffTag();
    const { staffTags } = useStaffTags();
    const staffTagValues = computed(() => staffTags.value.map((v) => v.value));

    return {
      aggregationTargetStaffTags,
      showAggregationTargetStaffTagCreateModal,
      showAggregationTargetStaffTagDeleteConfirmationModal,
      staffTagValues,
    };
  },
});
