
import Vue from 'vue';
import { computed, defineComponent, onMounted, reactive, SetupContext } from '@vue/composition-api';
import {
  USER_WORKPLACE_ROLE_W_OWNER,
  USER_WORKPLACE_ROLE_W_LEADER,
  USER_WORKPLACE_ROLE_W_MEMBER,
  USER_WORKPLACE_STATUS_PENDING,
} from 'src/consts';
import { apisWithTransformedData as workplaceApi } from 'src/apis/workplace';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { useLogiCoredata, navigateToLogiCoredata } from 'src/util/logibase';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { Workplace } from 'src/models/workplace';
import { vvGetError, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { LovEntry } from 'src/models/master';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { routerShowNextPage } from 'src/hooks/routeHook';
import { Location } from 'vue-router';
import { ensureUserAndMasters } from 'src/hooks/masterHook';

type WorkplaceEx = Workplace & {
  isUserWorkplaceStatusPending: boolean;
};
type WorkplaceToCreate = {
  name: string;
  address: string;
};
type State = {
  validations: any;
  workplaces: WorkplaceEx[];
  userRoles: Record<string, LovEntry>;
  invitedWorkplace: Workplace | null;
  workplaceToCreate: WorkplaceToCreate;
  showAcceptInvitationModal: boolean;
  showCreateWorkplaceModal1: boolean;
  showCreateWorkplaceModal2: boolean;
  isLoading: boolean;
  workplaceFilterText: string;
};
type UserStoreState = {
  userId: string;
  userEmail: string;
  logiCoredataUrl: string;
};

function getValidationMap(): any {
  const ruleStr = {
    required: true,
    max: 255,
  };
  return {
    name: ruleStr,
    address: { max: 255 },
  };
}

function setupState(): State {
  return reactive({
    validations: getValidationMap(),
    workplaces: [],
    userRoles: {},
    invitedWorkplace: null,
    workplaceToCreate: {
      name: '',
      address: '',
    },
    showAcceptInvitationModal: false,
    showCreateWorkplaceModal1: false,
    showCreateWorkplaceModal2: false,
    isLoading: true,
    workplaceFilterText: '',
  });
}

function setupUserStoreState(root: Vue): UserStoreState {
  return reactive(
    wrappedMapGetters(root.$store, 'user', {
      userId: 'id',
      userEmail: 'email',
      logiCoredataUrl: 'logiCoredataUrl',
    }),
  );
}

export default defineComponent({
  setup(_props, context: SetupContext) {
    const root = context.root as Vue;
    const state = setupState();
    const userStoreState = setupUserStoreState(root);

    const hasWorkplaces = computed(() => {
      return state.workplaces.length > 0;
    });
    const hasError = computed(() => {
      return vvHasError(root);
    });
    const isKurandoSupportUser = computed(() => {
      // TODO modosu
      // return userStoreState.userEmail === 'support@kurando.io'
      return userStoreState.userEmail === 'board-support@kurando.io';
    });
    const filteredWorkplaces = computed<WorkplaceEx[]>(() => {
      if (!state.workplaceFilterText) {
        return state.workplaces.slice();
      }
      const filterText = state.workplaceFilterText.toLowerCase();
      return state.workplaces.filter((workplace) => {
        const workplaceName = workplace.name.toLowerCase();
        const companyName = workplace.company_name?.toLowerCase() ?? '';
        return workplaceName.includes(filterText) || companyName.includes(filterText);
      });
    });

    function getError(fieldName: string): string | null {
      return vvGetError(root, fieldName);
    }

    function clearErrors() {
      vvReset(root);
    }

    async function getWorkplaces() {
      state.isLoading = true;
      const data = await workplaceApi.index();
      state.workplaces = data.map<WorkplaceEx>((e) => ({
        ...e,
        isUserWorkplaceStatusPending: e.user_workplace_status === USER_WORKPLACE_STATUS_PENDING,
      }));
      state.isLoading = false;
    }

    async function acceptInvitation() {
      if (!state.invitedWorkplace) {
        return;
      }
      try {
        await workplaceApi.acceptWorkplace(state.invitedWorkplace.id);
        await getWorkplaces();
        closeAcceptInvitationModal();
        notifySuccess1(root, 'センター共有を承諾しました');
      } catch (err: any) {
        const msg =
          'センター共有の承諾に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00001, user_id:${userStoreState.userId})`;
        notifyError1(root, msg, { err });
      }
    }

    async function declineInvitation() {
      if (!state.invitedWorkplace) {
        return;
      }
      try {
        await workplaceApi.declineWorkplace(state.invitedWorkplace.id);
        await getWorkplaces();
        closeAcceptInvitationModal();
        notifySuccess1(root, 'センター共有を拒否しました');
      } catch (err: any) {
        const msg =
          'センター共有の拒否に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00002, user_id:${userStoreState.userId})`;
        notifyError1(root, msg, { err });
      }
    }

    function closeAcceptInvitationModal() {
      state.invitedWorkplace = null;
      state.showAcceptInvitationModal = false;
    }

    async function openCreateWorkplaceModal2() {
      // TODO atteru??
      const isValid = await vvValidate(root, 'workplace1.*');
      // const isValid = await this.$validator.validateAll('workplace1')
      if (!isValid) {
        return;
      }
      // TODO 次期開発時に修正する
      // this.showCreateWorkplaceModal1 = false
      // this.showCreateWorkplaceModal2 = true

      await createWorkplace();
    }

    async function createWorkplace() {
      try {
        await workplaceApi.create(state.workplaceToCreate);
        await root.$store.dispatch('user/getMeRefresh');
        await getWorkplaces();
        closeCreateWorkplaceModal();
        notifySuccess1(root, 'センターを新規作成しました');
      } catch (err: any) {
        const msg =
          'センターの作成に失敗しました。管理者に連絡してください。' +
          `(ERR: Workplace/Select ERR00003, user_id:${userStoreState.userId})`;
        notifyError1(root, msg, { err });
      }
    }

    function closeCreateWorkplaceModal() {
      clearErrors();
      state.showCreateWorkplaceModal1 = false;
      state.showCreateWorkplaceModal2 = false;
      state.workplaceToCreate = { name: '', address: '' };
    }

    async function openAcceptInvitationModal(wp: WorkplaceEx) {
      state.invitedWorkplace = await workplaceApi.show(wp.id);
      state.showAcceptInvitationModal = true;
    }

    async function goToNextPage(evt: MouseEvent, wp: WorkplaceEx) {
      // センターをセットして次の画面へ
      await root.$store.dispatch('workplace/setWorkplace', wp);

      const routeObj: Location = {
        name: 'WorkplaceFeatureMap',
        params: {
          workplaceId: wp.id.toString(),
        },
      };
      await routerShowNextPage(context, evt, routeObj);
    }

    function canClickWorkplace(wp: WorkplaceEx): boolean {
      return (
        wp.isUserWorkplaceStatusPending ||
        [USER_WORKPLACE_ROLE_W_OWNER, USER_WORKPLACE_ROLE_W_LEADER].includes(wp.user_role ?? '')
      );
    }

    async function onWorkplaceClick(evt: MouseEvent, wp: WorkplaceEx) {
      if (!canClickWorkplace(wp)) {
        return;
      }

      if (wp.isUserWorkplaceStatusPending) {
        if (useLogiCoredata()) {
          // Transit to LogiCoredata and open modal on LogiCoredata side.
          navigateToLogiCoredata(
            userStoreState.logiCoredataUrl,
            `/workplace/select?mode=accept&workplace_id=${wp.id}`,
            true,
          );
        } else {
          await openAcceptInvitationModal(wp);
        }
      } else {
        await goToNextPage(evt, wp);
      }
    }

    function showCreateWorkplaceModal() {
      if (useLogiCoredata()) {
        // Transit to LogiCoredata and open modal on LogiCoredata side.
        navigateToLogiCoredata(userStoreState.logiCoredataUrl, '/workplace/select?mode=create', true);
      } else {
        state.showCreateWorkplaceModal1 = true;
      }
    }

    function prepareMasters() {
      const lovs = window.master.lovs;
      state.userRoles = lovs.user_role.map_inuse;
    }

    onMounted(async () => {
      setPageName(root, 'センター選択');
      await ensureUserAndMasters(context);
      prepareMasters();
      await getWorkplaces();
    });

    const gatedFuncGenerator = getGatedFuncGenerator();

    return {
      state,
      userStoreState,
      // const
      USER_WORKPLACE_ROLE_W_OWNER,
      USER_WORKPLACE_ROLE_W_LEADER,
      USER_WORKPLACE_ROLE_W_MEMBER,
      // computed
      hasWorkplaces,
      isKurandoSupportUser,
      filteredWorkplaces,
      // methods
      hasError,
      getError,
      canClickWorkplace,
      onWorkplaceClick,
      showCreateWorkplaceModal,
      closeCreateWorkplaceModal,
      closeAcceptInvitationModal,
      openCreateWorkplaceModal2,
      acceptInvitation: gatedFuncGenerator.makeAsyncFuncGated(acceptInvitation),
      declineInvitation: gatedFuncGenerator.makeAsyncFuncGated(declineInvitation),
      createWorkplace: gatedFuncGenerator.makeAsyncFuncGated(createWorkplace),
    };
  },
});
