







import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { useAggregationTargetStaffTagsProvider } from '../../composables/useAggregationTargetStaffTags';
import { useCreateAggregationTargetStaffTagProvider } from '../../composables/useCreateAggregationTargetStaffTag';
import { useDeleteAggregationTargetStaffTagProvider } from '../../composables/useDeleteAggregationTargetStaffTag';
import { useStaffTagsProvider } from '../../composables/useStaffTags';
import { Content } from '../Content';
import { MainPanel } from '../MainPanel';
import { AggregationTargetStaffTagCreateModal } from '../AggregationTargetStaffTagCreateModal';
import { AggregationTargetStaffTagDeleteConfirmationModal } from '../AggregationTargetStaffTagDeleteConfirmationModal';

export default defineComponent({
  components: {
    Content,
    MainPanel,
    AggregationTargetStaffTagCreateModal,
    AggregationTargetStaffTagDeleteConfirmationModal,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);

    setPageName(root, 'スタッフタグ ロジボード向け');
    useStaffTagsProvider({ workplaceId });
    useAggregationTargetStaffTagsProvider({ workplaceId });
    useCreateAggregationTargetStaffTagProvider();
    useDeleteAggregationTargetStaffTagProvider();
  },
});
