import { StaffWorkPlanRow } from '../types';
import { STAFF_WORK_PLAN_KEY } from '../consts';
import { DeepReadonly } from '@vue/composition-api';

type CreateProgressHeaders = {
  saveWorkPlans: (staffWorkPlans: DeepReadonly<StaffWorkPlanRow[]>) => void;
  loadWorkPlans: () => StaffWorkPlanRow[];
};

export function useStaffWorkPlanLocalStorage(): CreateProgressHeaders {
  const saveWorkPlans = (staffWorkPlans: DeepReadonly<StaffWorkPlanRow[]>) => {
    localStorage.setItem(STAFF_WORK_PLAN_KEY, JSON.stringify(staffWorkPlans));
  };

  const loadWorkPlans = () => {
    return JSON.parse(localStorage.getItem(STAFF_WORK_PLAN_KEY) ?? '{}');
  };

  return {
    saveWorkPlans,
    loadWorkPlans,
  };
}
