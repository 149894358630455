









































import { defineComponent } from '@vue/composition-api';
import { PanelList, PanelListItem } from '../PanelList';
import { ActivityStatusPanel } from 'src/views/Dashboard/Workplace/components/ActivityStatusPanel';
import { AttendanceStatusPanel } from 'src/views/Dashboard/Workplace/components/AttendanceStatusPanel';
import { OperationPanel } from '../OperationPanel';
import { MemoPanel } from '.././MemoPanel';
import { TimetableGroupPanel } from '../TimetableGroupPanel';
import { useProgressHeaders } from '../../composables/useProgressHeaders';
import { useCurrentStaffWork } from '../../composables/useCurrentStaffWork';
import { useAttendance } from 'src/composables/useAttendance';

export default defineComponent({
  components: {
    PanelList,
    PanelListItem,
    ActivityStatusPanel,
    AttendanceStatusPanel,
    TimetableGroupPanel,
    OperationPanel,
    MemoPanel,
  },
  setup() {
    const {
      progressHeaders,
      timetableGroupMasterWithProgressHeaders,
      productivityGraph,
      progressPlansMap,
      filterProgressHeaders,
    } = useProgressHeaders();

    const { showStatusPanel, currentStaffWork } = useCurrentStaffWork();
    const { attendanceCountMap } = useAttendance();

    return {
      showStatusPanel,
      progressHeaders,
      currentStaffWork,
      timetableGroupMasterWithProgressHeaders,
      productivityGraph,
      attendanceCountMap,
      progressPlansMap,
      filterProgressHeaders,
    };
  },
});
