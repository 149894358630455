









import { defineComponent } from '@vue/composition-api';
import AggregationTargetStaffTagListRow from './AggregationTargetStaffTagListRow.vue';
import AggregationTargetStaffTagListCell from './AggregationTargetStaffTagListCell.vue';

export default defineComponent({
  components: {
    AggregationTargetStaffTagListRow,
    AggregationTargetStaffTagListCell,
  },
});
