import { type ProgressHeader } from 'src/models/progressHeader';
import { getActivityMasterIds } from 'src/util/timetableMasterExternalSourceHelpers';

type Colors = {
  blue: string;
  green: string;
  yellow: string;
  orange: string;
  black: string;
};

const colors: Colors = {
  blue: '#8497B0',
  green: '#70AD47',
  yellow: '#FFC000',
  orange: '#ED7D31',
  black: '#252422',
};

const lightColors: Colors = {
  blue: '#B4C7E7',
  green: '#C5E0B4',
  yellow: '#FFE699',
  orange: '#F8CBAD',
  black: '#BFBFBF',
};

function calcRatio(numerator: number | null, denominator: number | null): number | null {
  return numerator !== null && denominator !== null && denominator !== 0 ? numerator / denominator : null;
}

function calcPercentage(numerator: number | null, denominator: number | null): number | null {
  const ratio = calcRatio(numerator, denominator);
  return ratio !== null ? ratio * 100 : null;
}

function getProductivityRank(
  productivity: number | null,
  targetProductivity: number | null,
  colorPalette: Colors = colors,
): { label: string; color: string } {
  return getSpeedRank(productivity, targetProductivity, colorPalette);
}

function getManHourRank(
  manHour: number | null,
  targetManHour: number | null,
  colorPalette: Colors = colors,
): { label: string; color: string } {
  return getSpeedRank(manHour, targetManHour, colorPalette);
}

function getQuantityRank(
  quantity: number | null,
  targetQuantity: number | null,
  colorPalette: Colors = colors,
): { label: string; color: string } {
  return getSpeedRank(quantity, targetQuantity, colorPalette);
}

function getSpeedRank(
  productivity: number | null,
  targetProductivity: number | null,
  colorPalette: Colors = colors,
): { label: string; color: string } {
  const ratio = calcRatio(productivity, targetProductivity);

  if (ratio === null) {
    return { label: '停止', color: colorPalette.black };
  }

  if (ratio >= 1.1) {
    return { label: '高速', color: colorPalette.blue };
  }

  if (ratio >= 0.9) {
    return { label: '適速', color: colorPalette.green };
  }

  if (ratio >= 0.7) {
    return { label: '中速', color: colorPalette.yellow };
  }

  return { label: '低速', color: colorPalette.orange };
}

export function getProgressRate({ result_quantity, scheduled_quantity }: ProgressHeader): number | null {
  return calcPercentage(result_quantity, scheduled_quantity);
}

export function getResultManHoursRate({ result_man_hours, scheduled_man_hours }: ProgressHeader): number | null {
  return calcPercentage(result_man_hours, scheduled_man_hours);
}

export function getResultManHoursColor({ total_productivity, target_productivity }: ProgressHeader): string {
  const colorPalette = { ...lightColors, black: colors.black };
  return getProductivityRank(total_productivity, target_productivity, colorPalette).color;
}

export function getLatestProductivityRate({ latest_productivity, target_productivity }: ProgressHeader): number | null {
  return calcPercentage(latest_productivity, target_productivity);
}

export function getLatestProductivityColor({ latest_productivity, target_productivity }: ProgressHeader): string {
  return getProductivityRank(latest_productivity, target_productivity).color;
}

export function getLatestProductivityRank({ latest_productivity, target_productivity }: ProgressHeader): string {
  return getProductivityRank(latest_productivity, target_productivity).label;
}

export function getLatestProductivityRankColor({ latest_productivity, target_productivity }: ProgressHeader): string {
  const colorPalette = { ...colors, black: lightColors.black };
  return getProductivityRank(latest_productivity, target_productivity, colorPalette).color;
}

export function hasActivityMasterForManHours({ timetable_master }: ProgressHeader): boolean {
  return getActivityMasterIds(timetable_master.timetable_master_external_sources, 'use_for_man_hours').length > 0;
}

export function getInitialProgressDetailStartTime({ progress_details, timetable_master }: ProgressHeader): number {
  const lastProgressDetail = [...progress_details].sort((a, b) => b.end_time - a.end_time)[0];
  return lastProgressDetail?.end_time ?? timetable_master.start_time;
}

export function getTotalManHoursColor(remaining_man_hours: number, result_man_hours: number): string {
  const colorPalette = { ...colors, black: lightColors.black };
  return getManHourRank(remaining_man_hours, result_man_hours, colorPalette).color;
}

export function getTotalManHoursRank(remaining_man_hours: number, result_man_hours: number): string {
  return getManHourRank(remaining_man_hours, result_man_hours).label;
}

export function getQuantityColor(resultQuantity: number | null, plannedQuantity: number | null): string {
  return getQuantityRank(resultQuantity, plannedQuantity).color;
}

export function getProductivityColor(latestProductivity: number | null, plannedProductivity: number | null): string {
  return getProductivityRank(latestProductivity, plannedProductivity).color;
}
