import {
  ref,
  readonly,
  type Ref,
  type ComputedRef,
  type DeepReadonly,
  watch,
  reactive,
  computed,
  nextTick,
} from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { isExist } from 'src/util/isExist';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { useProgressHeaders } from './useProgressHeaders';
import { useCommonSetting } from './useCommonSetting';
import {
  UNASSIGNED_HOUR_BLOCK_ID,
  TOTAL_HOUR_BLOCK_ID,
  COLUMN_WIDTH,
  DISPLAY_END_HOUR,
  UNIT_BLOCK_MINUTES,
  UNIT_BLOCK_LENGTH_PER_HOUR,
} from '../const';
import {
  type HourBlock,
  type TimeBlock,
  type TimetableTimeBlock,
  type TimetableBreakTimeBlock,
  type RestingTimetableTimeBlock,
  type SupportTimeBlock,
  type AbstractTimeBlock,
  isTimetableTimeBlock,
  type TimetableRow,
  type RestingTimetableRow,
  type SaveProgressDetailCandidate,
  type ProgressSectionQuarterTime,
} from '../types';
import {
  createSupportHourBlocks,
  createTimetable,
  createRestingTimetableRow,
  createTotalHourBlocksFromDailySimulationTimetable,
  getLastHeadcountExistTime,
  getTimetableMastersWithProgressHeader,
  getNextIntervalUnitMinutes,
} from '../dailySimulationHelper';
import { ValidationObserverInstance } from 'vee-validate';
import { secondsToTimeInteger, timeIntegerToSeconds, timeStrToTimeInteger, unpackTimeInteger } from 'src/util/datetime';
import { TimeInteger } from 'src/models/common';
import type { TimetableMaster } from 'src/models/timetableMaster';
import { useBudgetGroupPlanBoardMisc } from './useBudgetGroupPlanBoardMiscs';
import { useDisplayConditions } from './useDisplayConditions';
import { TIMETABLE_TYPE, MANAGEMENT_TYPE } from 'src/consts';
import { isStrictTimeRange, type TimeRange } from 'src/values/TimeRange';
import { roundToInteger, roundToOneDecimal } from 'src/util/numbers';
import { isSameTimeRange } from 'src/values/TimeRange/TimeRange';

type DailySimulationState = {
  timetable: TimetableRow[];
  totalHourBlocks: HourBlock<SupportTimeBlock>[]; // 単位時間ごとの合計人時を 1 時間ごとの配列で持つ配列
  unassignedHourBlocks: HourBlock<SupportTimeBlock>[];
  restingTimetableRow: RestingTimetableRow | null;
  displayTimes: string[];
  lastHeadcountExistTime: TimeInteger;
  isLoading: boolean;
  hidesEmptyQuantityProgressHeaders: boolean;
};

type Params = {
  validationObserver: Ref<ValidationObserverInstance | undefined>;
};

type InjectionValue = {
  state: DeepReadonly<DailySimulationState>;
  displayedTimetable: ComputedRef<TimetableRow[]>;
  displayedTimetableMasters: ComputedRef<TimetableMaster[]>;
  timetableRowMap: ComputedRef<Record<number, TimetableRow>>;
  timetableRowStyle: {
    gridTemplateColumns: string;
    display: string;
  };
  simulateTimetable: (simulationStartTime: string, resetHeadcountTargetIds: number[]) => void;
  simulateSpecificTimetableRow: (
    timetableMasterId: number,
    simulationStartTime: string,
    isResetHeadcount: boolean,
  ) => void;
  calculateUnassignedTimeBlocks: (simulationStartTime: string) => void;
  updateHeadcountAndSimulateTimetableRow: (
    timeBlock: TimeBlock,
    nextHeadcount: number,
    options?: { isAdjustedWithResting?: boolean },
  ) => void;
  updateQuantity: (
    timetableMasterId: number,
    progressSectionQuarterTime: ProgressSectionQuarterTime,
    quantityToUpdate: number,
  ) => void;
  updateProductivity: (
    timetableMasterId: number,
    progressSectionQuarterTime: ProgressSectionQuarterTime,
    quantityToUpdate: number,
  ) => void;
  updateBreakTime: (
    timetableMasterId: number,
    break1TimeRange: TimeRange | null,
    break2TimeRange: TimeRange | null,
  ) => void;
  setTotalProductivity: () => void;
  fetchAndSimulate: (option?: { isResetHeader: boolean; isResetHeadcount: boolean }) => Promise<void>;
  fetchAndNoSimulate: () => void;
  fetchAndSimulateTimetableRow: (timetableMasterId: number) => void;
  resetSimulation: () => void;
  saveProgressDetailCandidate: SaveProgressDetailCandidate;
  waitLoading: () => void;
  finishLoading: () => void;
};

const { provide, inject } = createInjection<InjectionValue>('useDailySimulations');

export function useDailySimulationProvider({ validationObserver }: Params): void {
  const state: DailySimulationState = reactive({
    timetable: [],
    totalHourBlocks: [],
    unassignedHourBlocks: [],
    restingTimetableRow: null,
    lastHeadcountExistTime: 0,
    displayTimes: computed(() => {
      return state.timetable[0].hourBlocks.map(({ unitTimeBlocks }) => unitTimeBlocks[0].displayTime);
    }),
    isLoading: false,
    hidesEmptyQuantityProgressHeaders: false,
  });
  const { timetableMasterMap, timetableMasters } = useTimetableMasters();
  const { progressHeaders, fetchProgressHeaders } = useProgressHeaders();
  const { setBudgetGroupPlanBoardMisc } = useBudgetGroupPlanBoardMisc();
  const { commonSetting } = useCommonSetting();
  const { filteredTimetableMasterIds, filterTimetable } = useDisplayConditions();
  const saveProgressDetailCandidate: SaveProgressDetailCandidate = { timetableMasters: [] };
  const isNoSimulate = ref(false);
  const isResetHeader = ref(false);
  const resetHeadcountTargetIds = ref<number[]>([]);
  const isInvalidInputExist = computed(() => {
    if (!isExist(validationObserver.value)) {
      return false;
    }
    return Object.values(validationObserver.value.ctx.errors).some((error) => error.length > 0);
  });
  const allTimetableMasterIds = computed(() => {
    return state.timetable
      .map(({ header }) => header.masterId)
      .concat(TOTAL_HOUR_BLOCK_ID)
      .concat(state.restingTimetableRow?.header.masterId ?? []);
  });

  const timetableRowMap = computed<Record<number, TimetableRow>>(() => {
    return state.timetable.reduce<Record<number, TimetableRow>>((map, timetableRow) => {
      return {
        ...map,
        [timetableRow.header.masterId]: timetableRow,
      };
    }, {});
  });

  const hourBlocksMap = computed<Record<number, HourBlock[]>>(() => {
    const currentHourBlocksMap: Record<number, HourBlock<TimeBlock>[]> = {
      ...Object.entries(timetableRowMap.value).reduce<Record<number, HourBlock<TimeBlock>[]>>(
        (map, [key, timetableRow]) => {
          return {
            ...map,
            [key]: timetableRow.hourBlocks,
          };
        },
        {},
      ),
      [UNASSIGNED_HOUR_BLOCK_ID]: state.unassignedHourBlocks,
      [TOTAL_HOUR_BLOCK_ID]: state.totalHourBlocks,
    };
    if (state.restingTimetableRow !== null) {
      currentHourBlocksMap[state.restingTimetableRow.header.masterId] = state.restingTimetableRow.hourBlocks;
    }
    return currentHourBlocksMap;
  });

  function isZeroOrNull(value: number | null) {
    return value === 0 || value === null;
  }

  const displayedTimetable = computed(() => {
    return state.timetable.filter((timetableRow) => {
      const conditions = [
        filteredTimetableMasterIds.value.includes(timetableRow.header.masterId),
        timetableRow.header.timetableType === TIMETABLE_TYPE.GENERAL,
      ];
      if (state.hidesEmptyQuantityProgressHeaders) {
        conditions.push(
          timetableRow.progressHeader.timetable_master.management_type === MANAGEMENT_TYPE.ONLY_MAN_HOUR ||
            !isZeroOrNull(timetableRow.progressHeader.scheduled_quantity) ||
            !isZeroOrNull(timetableRow.progressHeader.result_quantity),
        );
      }
      return conditions.every((condition) => condition);
    });
  });

  nextTick(() => {
    watch(
      () => displayedTimetable.value,
      () => finishLoading(),
    );
  });

  const displayedTimetableMasters = computed(() => {
    return displayedTimetable.value.map((timetableRow) => timetableMasterMap.value[timetableRow.header.masterId]);
  });

  const timetableRowStyle = {
    gridTemplateColumns: `repeat(${DISPLAY_END_HOUR}, ${COLUMN_WIDTH}px)`,
    display: 'grid',
  };

  watch([progressHeaders], () => {
    resetSimulation();
  });

  watch([filteredTimetableMasterIds, progressHeaders], () => {
    saveProgressDetailCandidate.timetableMasters = getTimetableMastersWithProgressHeader(
      timetableMasters.value.filter((timetableMaster) => filteredTimetableMasterIds.value.includes(timetableMaster.id)),
      progressHeaders.value,
    );
  });

  watch(
    () => [commonSetting.targetClockOutTime],
    () => {
      resetTimetable(false, [TOTAL_HOUR_BLOCK_ID]);
      simulateTimetable('0:00', [TOTAL_HOUR_BLOCK_ID]);
    },
  );

  const resetTimetable = (isResetHeader: boolean, resetHeadcountTargetIds: number[]) => {
    const timetable = createTimetable(progressHeaders.value, timetableMasterMap.value);
    state.timetable = copyTimetable(state.timetable, timetable, {
      isResetHeader,
      resetHeadcountTargetIds,
    });
    const restingTimetableRow = createRestingTimetableRow(progressHeaders.value, timetableMasterMap.value);
    if (isExist(restingTimetableRow)) {
      state.restingTimetableRow = copyRestingTimetableRow(state.restingTimetableRow, restingTimetableRow, {
        resetHeadcount: resetHeadcountTargetIds.includes(restingTimetableRow.header.masterId),
      });
    }
    updateAllTimeBlocksBreakStatus(state.timetable);
    state.lastHeadcountExistTime = getLastHeadcountExistTime(progressHeaders.value);
    const totalHourBlocks = createTotalHourBlocksFromDailySimulationTimetable(
      [...state.timetable, state.restingTimetableRow].filter(isExist),
    );
    state.totalHourBlocks = resetHeadcountTargetIds.includes(TOTAL_HOUR_BLOCK_ID)
      ? totalHourBlocks
      : copyTotalHourBlocks(state.totalHourBlocks, totalHourBlocks);
    state.unassignedHourBlocks = createSupportHourBlocks(UNASSIGNED_HOUR_BLOCK_ID);
  };

  /**
   * Header や HourBlock[] を現在の値で上書く
   */
  const copyTimetable = (
    from: TimetableRow[],
    to: TimetableRow[],
    option: {
      isResetHeader: boolean;
      resetHeadcountTargetIds: number[];
    },
  ): TimetableRow[] => {
    const fromTimetableRowMap = from.reduce<Record<string, TimetableRow>>((timetableRowMap, timetableRow) => {
      return {
        ...timetableRowMap,
        [timetableRow.header.masterId]: timetableRow,
      };
    }, {});

    const timetable = option.isResetHeader
      ? to
      : to.concat().map((timetableRow) => {
          const reusedHeader = fromTimetableRowMap[timetableRow.header.masterId]?.header;
          const header = { ...timetableRow.header };
          if (isExist(reusedHeader)) {
            header.break1TimeRange = reusedHeader.break1TimeRange;
            header.break2TimeRange = reusedHeader.break2TimeRange;
          }
          return {
            ...timetableRow,
            header,
          };
        });

    timetable.forEach((timetableRow) => {
      if (option.resetHeadcountTargetIds.includes(timetableRow.header.masterId)) {
        return;
      }
      const fromTimetableRow = fromTimetableRowMap[timetableRow.header.masterId];
      if (!isExist(fromTimetableRow)) {
        return;
      }
      const lastHeadcountExistTime = getLastHeadcountExistTime([timetableRow.progressHeader]);
      const startTimeBlock = getTimeBlock(fromTimetableRow.hourBlocks, lastHeadcountExistTime);
      if (isExist(startTimeBlock)) {
        copyTimeBlock(startTimeBlock, timetableRow.hourBlocks);
      }
    });
    return timetable;
  };

  const copyRestingTimetableRow = (
    from: RestingTimetableRow | null,
    to: RestingTimetableRow,
    option: {
      resetHeadcount: boolean;
    },
  ): RestingTimetableRow => {
    if (option.resetHeadcount || !isExist(from)) {
      return to;
    }
    const lastHeadcountExistTime = getLastHeadcountExistTime([to.progressHeader]);
    const startTimeBlock = getTimeBlock(from.hourBlocks, lastHeadcountExistTime);
    if (isExist(startTimeBlock)) {
      copyTimeBlock(startTimeBlock, to.hourBlocks);
    }
    return to;
  };

  const copyTimeBlock = (fromTimeBlock: TimeBlock, toHourBlocks: HourBlock[]) => {
    const nextTimeBlock = getNextTimeBlock(fromTimeBlock);
    const toTimeBlock = getTimeBlock(toHourBlocks, fromTimeBlock.displayTime);
    if (!isExist(toTimeBlock)) {
      if (isExist(nextTimeBlock)) {
        copyTimeBlock(nextTimeBlock, toHourBlocks);
      }
      return;
    }

    Object.assign(toTimeBlock, fromTimeBlock);

    if (isExist(nextTimeBlock)) {
      copyTimeBlock(nextTimeBlock, toHourBlocks);
    }
  };

  const copyTotalHourBlocks = (fromHourBlocks: HourBlock[], toHourBlocks: HourBlock[]) => {
    const returnHourBlocks = toHourBlocks.concat();
    const startTimeBlock = getTimeBlock(fromHourBlocks, state.lastHeadcountExistTime);
    if (isExist(startTimeBlock)) {
      copyTimeBlock(startTimeBlock, returnHourBlocks);
    }
    return returnHourBlocks;
  };

  const updateAllTimeBlocksBreakStatus = (timetable: TimetableRow[]) => {
    timetable.forEach((timetableRow) => {
      const breakTimeRanges = [timetableRow.header.break1TimeRange, timetableRow.header.break2TimeRange];
      timetableRow.hourBlocks
        .flatMap((hourBlock) => hourBlock.unitTimeBlocks)
        .forEach((timeBlock) => {
          timeBlock.isBreak = isBreakTime(getTimeInteger(timeBlock), breakTimeRanges);
          if (timeBlock.isBreak) {
            timeBlock.behavingAsWorkTime = false;
            timeBlock.simulatedHeadcount = timeBlock.simulatedHeadcount ?? 0;
          }
        });
    });
  };

  /**
   * 全体シミュレーションのエントリポイント
   *
   * 指定された時刻からシミュレーションを開始し、以下の4種類のデータを処理する。
   *
   * 1. **TimetableRow**:
   *    - 各工程の人数や進捗状況を管理する。
   *
   * 2. **TotalHourBlocks**:
   *    - 全工程の合計人数を保持する。
   *
   * 3. **RestingTimeBlocks**:
   *    - 休憩時間帯の人数を保持する。
   *    - 他の工程から休憩に移動した人数や、休憩人数の合計を計算する。
   *
   * 4. **UnassignedTimeBlocks**:
   *    - 工程に割り当てられていない人数を保持する。
   *    - 全工程の合計人数から、各工程の人数を引いた値を記録する。
   */
  const simulateTimetable = (simulationStartTime: string, resetHeadcountTargetIds: number[] = []) => {
    if (isInvalidInputExist.value) {
      return;
    }

    state.timetable.forEach((timetableRow) => {
      simulateTimetableRow(
        timetableRow,
        simulationStartTime,
        resetHeadcountTargetIds.includes(timetableRow.header.masterId),
      );
    });
    simulateTotalHourBlocks(simulationStartTime, resetHeadcountTargetIds.includes(TOTAL_HOUR_BLOCK_ID));
    calculateRestingTimeBlocks(simulationStartTime);
    calculateUnassignedTimeBlocks(simulationStartTime);
  };

  const simulateSpecificTimetableRow = (
    timetableMasterId: number,
    simulationStartTime: string,
    isResetHeadcount: boolean,
  ) => {
    simulateTimetableRow(timetableRowMap.value[timetableMasterId], simulationStartTime, isResetHeadcount);
    calculateRestingTimeBlocks(simulationStartTime);
    calculateUnassignedTimeBlocks(simulationStartTime);
  };

  /**
   * 1つの工程に対してシミュレーションを実行する
   */
  const simulateTimetableRow = (
    timetableRow: TimetableRow,
    simulationStartTime: string,
    isResetHeadcount: boolean,
    headcountChange?: number, // 人数変更が行われた際の、各時間帯に追加(削減)する差分人数
    isAdjustedWithResting: boolean = false, // 「休憩へ移動」によるシミュレーション行う場合は true
  ) => {
    // ステップ1: 指定した時間以降の進捗履歴を未シミュレーションの状態に戻す
    resetTimetableRow(timetableRow, simulationStartTime);

    // ステップ2: シミュレーション開始点以降のタイムブロックの進捗履歴をシミュレートされたものに書き変える
    const simulationStartTimeBlock = getTimeBlock(timetableRow.hourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock)) {
      simulateTimeBlock(simulationStartTimeBlock, isResetHeadcount, headcountChange, isAdjustedWithResting, true);
    }
  };

  const simulateTimeBlock = (
    currentTimeBlock: TimetableTimeBlock,
    isResetHeadcount: boolean,
    headcountChange: number | undefined, // 人数変更が行われた際の、各時間帯に追加(削減)する差分人数
    isAdjustedWithResting: boolean, // 「休憩へ移動」によるシミュレーション行う場合は true
    isFirstSimulate = false, // シミュレーション対象のブロックがシミュレーション基準点移行最初のブロックである場合は処理条件が変わる為、フラグを用意する
  ) => {
    const { lastHeadcountExistTime } = state;
    const currentTime = getTimeInteger(currentTimeBlock);
    const nextTimeBlock = getNextTimeBlock(currentTimeBlock);
    const prevTimeBlock = getPreviousTimeBlock(currentTimeBlock);

    currentTimeBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）-> 進捗履歴の更新だけして次の時間帯へ
    if (currentTime < lastHeadcountExistTime) {
      updateTimeBlockProgress(currentTimeBlock);
      if (isExist(nextTimeBlock)) {
        simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange, isAdjustedWithResting);
      }
      return;
    }

    const requiredHeadcount = getRequiredHeadcount(currentTimeBlock);
    let simulatedHeadcount = simulateHeadcount(currentTimeBlock, isResetHeadcount, isFirstSimulate, headcountChange);

    // 休憩時間である && 実績が記録されていない 時間帯の場合 -> 休憩人数の算出を行い、人数を割り当てていく
    if (currentTimeBlock.isBreak && !currentTimeBlock.behavingAsWorkTime) {
      const oldSimulatedHeadcount = currentTimeBlock.simulatedHeadcount;
      const newSimulatedHeadcount = requiredHeadcount === 0 ? 0 : Math.max(0, simulatedHeadcount);
      // 通常の人数変更の場合、シミュレーションによる人数の変化を休憩人数に加算する
      // 「休憩へ移動」による人数変更の場合、休憩人数は変更しない
      if (!isAdjustedWithResting) {
        currentTimeBlock.onBreakHeadcount += newSimulatedHeadcount - oldSimulatedHeadcount;
      }
      currentTimeBlock.simulatedHeadcount = newSimulatedHeadcount;
      currentTimeBlock.isSimulated = true;
      if (isExist(prevTimeBlock)) {
        currentTimeBlock.isPreviousSimulated = prevTimeBlock.isSimulated;
        currentTimeBlock.isHeadcountSameAsPreviousTime = currentTimeBlock.headcount === prevTimeBlock.headcount;
      }
      updateTimeBlockProgress(currentTimeBlock);
      if (isExist(nextTimeBlock)) {
        simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange, isAdjustedWithResting);
      }
      return;
    }

    // 休憩時間ではない && 実績が記録されていない 時間帯の場合 -> 必要人数の算出を行い、人数を割り当てていく
    const isJustChangedTimeBlock = isFirstSimulate && isExist(headcountChange);
    if (requiredHeadcount < simulatedHeadcount && !isJustChangedTimeBlock) {
      simulatedHeadcount = requiredHeadcount;
      // 必要人時がシミュレーション結果の数値よりも小さい場合は、人数が縮小されたフラグを立て、次回のシミュレーション時に利用する。
      // ただし、requiredHeadcount が 0 の場合は、縮小ではなく完了とみなすので、フラグは立てない。
      currentTimeBlock.isHeadcountShrunk = requiredHeadcount > 0;
    } else {
      currentTimeBlock.isHeadcountShrunk = false;
    }

    const oldHeadcount = currentTimeBlock.headcount;
    const newHeadcount = Math.max(0, simulatedHeadcount);
    // 「休憩へ移動」による人数変更の場合、シミュレーションによる人数の変化を休憩人数に減算し、合計人数が変わらないようにする
    if (isAdjustedWithResting) {
      const actualHeadcountChange = newHeadcount - oldHeadcount;
      currentTimeBlock.onBreakHeadcount -= actualHeadcountChange;
    }
    currentTimeBlock.headcount = newHeadcount;
    currentTimeBlock.isSimulated = true;
    if (isExist(prevTimeBlock)) {
      currentTimeBlock.isPreviousSimulated = prevTimeBlock.isSimulated;
      currentTimeBlock.isHeadcountSameAsPreviousTime = currentTimeBlock.headcount === prevTimeBlock.headcount;
    }

    updateTimeBlockProgress(currentTimeBlock);

    if (isExist(nextTimeBlock)) {
      simulateTimeBlock(nextTimeBlock, isResetHeadcount, headcountChange, isAdjustedWithResting);
    }
  };

  const getRequiredHeadcount = (currentTimeBlock: TimetableTimeBlock) => {
    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];
    const { scheduledQuantity, targetCompletionTime } = timetableRow.header;
    const currentTime = getTimeInteger(currentTimeBlock);

    if (!isExist(scheduledQuantity)) {
      if (!isExist(targetCompletionTime) || currentTime < targetCompletionTime) {
        return Number.MAX_SAFE_INTEGER;
      } else {
        return 0;
      }
    } else {
      const previousTotalQuantity = getPreviousTotalQuantity(currentTimeBlock);
      const restOfQuantity = scheduledQuantity - previousTotalQuantity;
      // 残数量がなければ必要人数は0
      if (restOfQuantity <= 0) {
        return 0;
      }
      const blockProductivity = getBlockProductivity(currentTimeBlock);
      // 残数量があり、生産性が0以下の場合は何人いても到達しようがないため、最大値を返す
      if (blockProductivity <= 0) {
        return Number.MAX_SAFE_INTEGER;
      }
      return Math.ceil(restOfQuantity / blockProductivity);
    }
  };

  const simulateHeadcount = (
    currentTimeBlock: TimetableTimeBlock,
    isResetHeadcount: boolean,
    isFirstSimulate: boolean,
    headcountChange?: number,
  ) => {
    const lastHeadcount = getLastHeadcount(currentTimeBlock);
    const currentHeadcount =
      currentTimeBlock.isBreak && !currentTimeBlock.behavingAsWorkTime ? 0 : currentTimeBlock.headcount;
    const shouldUseLastHeadcount = currentTimeBlock.isHeadcountShrunk || (lastHeadcount > 0 && currentHeadcount === 0);

    if (!isResetHeadcount) {
      return shouldUseLastHeadcount ? lastHeadcount : currentHeadcount;
    }
    if (!isExist(headcountChange)) {
      return lastHeadcount;
    }
    if (isFirstSimulate) {
      return currentHeadcount + headcountChange;
    }
    return shouldUseLastHeadcount ? lastHeadcount : currentHeadcount + headcountChange;
  };

  /**
   * 「割当なし」人数を算出する
   * ユーザーが入力した「合計」人数から、「各工程の人数の合計値」を引いた値を表示するためのもの
   * 各時間帯において、ユーザーが想定している人数とシミュレーションによる人数の差分をわかりやすくすることで、シミュレーションの数値を編集する際の参考値にしてもらう意図がある
   */
  const calculateUnassignedTimeBlocks = (simulationStartTime: string) => {
    const simulationStartTimeBlock = getTimeBlock(state.unassignedHourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock)) {
      calculateUnassignedTimeBlock(simulationStartTimeBlock);
    }
  };

  const calculateUnassignedTimeBlock = (currentUnassignedBlock: SupportTimeBlock) => {
    const { lastHeadcountExistTime } = state;
    const currentTime = getTimeInteger(currentUnassignedBlock);
    const nextTimeBlock = getNextTimeBlock(currentUnassignedBlock);
    const prevTimeBlock = getPreviousTimeBlock(currentUnassignedBlock);

    currentUnassignedBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      calculateUnassignedTimeBlock(nextTimeBlock);
      return;
    }

    currentUnassignedBlock.isSimulated = true;

    const restingHeadcount = getRestingTimeBlock(currentTime)?.headcount ?? 0;

    const totalHeadcount = state.timetable.reduce((totalHeadcount, timetableRow) => {
      return totalHeadcount + (getTimeBlock(timetableRow.hourBlocks, currentTime)?.headcount ?? 0);
    }, restingHeadcount);

    const currentTotalHourBlock = getTimeBlock(state.totalHourBlocks, currentTime);
    if (isExist(currentTotalHourBlock)) {
      currentUnassignedBlock.headcount = currentTotalHourBlock.headcount - totalHeadcount;
    }

    if (isExist(prevTimeBlock)) {
      currentUnassignedBlock.isPreviousSimulated = prevTimeBlock.isSimulated;
      currentUnassignedBlock.isHeadcountSameAsPreviousTime =
        currentUnassignedBlock.headcount === prevTimeBlock.headcount;
    }

    if (isExist(nextTimeBlock)) {
      calculateUnassignedTimeBlock(nextTimeBlock);
    }
  };

  /**
   * 全工程の休憩中の人数と休憩に移動した人数を合計して、休憩時間帯の人数を算出する
   */
  const calculateRestingTimeBlocks = (simulationStartTime: string) => {
    const simulationStartTimeBlock = getRestingTimeBlock(simulationStartTime);
    if (isExist(simulationStartTimeBlock)) {
      calculateRestingTimeBlock(simulationStartTimeBlock);
    }
  };

  const calculateRestingTimeBlock = (currentRestingTimeBlock: RestingTimetableTimeBlock) => {
    const { lastHeadcountExistTime } = state;
    const currentTime = getTimeInteger(currentRestingTimeBlock);
    const nextTimeBlock = getNextTimeBlock(currentRestingTimeBlock);
    const prevTimeBlock = getPreviousTimeBlock(currentRestingTimeBlock);

    currentRestingTimeBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      calculateRestingTimeBlock(nextTimeBlock);
      return;
    }

    currentRestingTimeBlock.isSimulated = true;

    // すべての一般工程のonBreakHeadcountと休憩工程のstandbyHeadcountを合計する
    const totalOnBreakHeadcount = state.timetable.reduce((totalOnBreakHeadcount, timetableRow) => {
      const timeBlock = getTimeBlock(timetableRow.hourBlocks, currentTime);
      return totalOnBreakHeadcount + (timeBlock?.onBreakHeadcount ?? 0);
    }, currentRestingTimeBlock.standbyHeadcount);

    if (totalOnBreakHeadcount < 0) {
      // 休憩工程の人数は集計結果が負になるとき、0に設定する
      // その後のシミュレーションに影響が発生しないよう、調整した人数は休憩工程の待機人数に加算する
      currentRestingTimeBlock.standbyHeadcount += Math.abs(totalOnBreakHeadcount);
      currentRestingTimeBlock.headcount = 0;
    } else {
      currentRestingTimeBlock.headcount = totalOnBreakHeadcount;
    }

    if (isExist(prevTimeBlock)) {
      currentRestingTimeBlock.isPreviousSimulated = prevTimeBlock.isSimulated;
      currentRestingTimeBlock.isHeadcountSameAsPreviousTime =
        currentRestingTimeBlock.headcount === prevTimeBlock.headcount;
    }

    if (isExist(nextTimeBlock)) {
      calculateRestingTimeBlock(nextTimeBlock);
    }
  };

  /**
   * 合計人数をシミュレーションする
   */
  const simulateTotalHourBlocks = (
    simulationStartTime: string,
    isResetHeadcount: boolean,
    headcountChange?: number,
  ) => {
    const simulationStartTimeBlock = getTimeBlock(state.totalHourBlocks, simulationStartTime);
    if (isExist(simulationStartTimeBlock) && isResetHeadcount) {
      simulateTotalTimeBlock(simulationStartTimeBlock, headcountChange, true);
    }
  };

  const simulateTotalTimeBlock = (
    currentTotalTimeBlock: SupportTimeBlock,
    headcountChange?: number,
    isFirstSimulate = false,
  ) => {
    const currentTime = getTimeInteger(currentTotalTimeBlock);
    const nextTimeBlock = getNextTimeBlock(currentTotalTimeBlock);
    const prevTimeBlock = getPreviousTimeBlock(currentTotalTimeBlock);
    const { lastHeadcountExistTime } = state;

    currentTotalTimeBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      simulateTotalTimeBlock(nextTimeBlock, headcountChange);
      return;
    }

    currentTotalTimeBlock.isSimulated = true;

    const isBeforeClockOut =
      isExist(commonSetting.targetClockOutTime) && currentTime < commonSetting.targetClockOutTime;
    if (isExist(headcountChange)) {
      if (isFirstSimulate || isBeforeClockOut) {
        currentTotalTimeBlock.headcount = Math.max(0, currentTotalTimeBlock.headcount + headcountChange);
      }
    } else if (isBeforeClockOut) {
      currentTotalTimeBlock.headcount = getLastHeadcount(currentTotalTimeBlock);
    }

    if (isExist(prevTimeBlock)) {
      currentTotalTimeBlock.isPreviousSimulated = prevTimeBlock.isSimulated;
      currentTotalTimeBlock.isHeadcountSameAsPreviousTime = currentTotalTimeBlock.headcount === prevTimeBlock.headcount;
    }

    if (isExist(nextTimeBlock)) {
      simulateTotalTimeBlock(nextTimeBlock, headcountChange);
    }
  };

  /**
   * 休憩人数をシミュレーションする
   */
  const simulateRestingHourBlocks = (
    simulationStartTime: string,
    isResetHeadcount: boolean,
    headcountChange: number,
  ) => {
    const simulationStartTimeBlock = getRestingTimeBlock(simulationStartTime);
    if (isExist(simulationStartTimeBlock) && isResetHeadcount) {
      simulateRestingTimeBlock(simulationStartTimeBlock, headcountChange, true);
    }
  };

  const simulateRestingTimeBlock = (
    currentRestingTimeBlock: RestingTimetableTimeBlock,
    headcountChange: number,
    isFirstSimulate = false,
  ) => {
    const currentTime = getTimeInteger(currentRestingTimeBlock);
    const nextTimeBlock = getNextTimeBlock(currentRestingTimeBlock);
    const { lastHeadcountExistTime } = state;

    currentRestingTimeBlock.isSimulated = false;

    // 処理中のブロックの時刻 < 人数実績が最後に記録された時刻（実績が記録されている時間帯）
    if (currentTime < lastHeadcountExistTime && isExist(nextTimeBlock)) {
      simulateRestingTimeBlock(nextTimeBlock, headcountChange);
      return;
    }

    currentRestingTimeBlock.isSimulated = true;

    const isBeforeClockOut =
      isExist(commonSetting.targetClockOutTime) && currentTime < commonSetting.targetClockOutTime;
    if (!isFirstSimulate && !isBeforeClockOut) {
      return;
    }

    currentRestingTimeBlock.standbyHeadcount += headcountChange;

    if (isExist(nextTimeBlock)) {
      simulateRestingTimeBlock(nextTimeBlock, headcountChange);
    }
  };

  /**
   * 人数を更新しつつシミュレーションを実行する
   */
  const updateHeadcountAndSimulateTimetableRow = (
    timeBlock: TimeBlock,
    nextHeadcount: number,
    options?: { isAdjustedWithResting?: boolean },
  ) => {
    const headcountChange = nextHeadcount - timeBlock.headcount;
    if (timeBlock.timetableMasterId === TOTAL_HOUR_BLOCK_ID) {
      simulateTotalHourBlocks(timeBlock.displayTime, true, headcountChange);
    } else if (timeBlock.timetableMasterId === state.restingTimetableRow?.header.masterId) {
      simulateRestingHourBlocks(timeBlock.displayTime, true, headcountChange);
      calculateRestingTimeBlocks(timeBlock.displayTime);
    } else if (isTimetableTimeBlock(timeBlock)) {
      const timetableRow = timetableRowMap.value[timeBlock.timetableMasterId];
      if (!isExist(timetableRow)) {
        return;
      }
      if (timeBlock.isBreak) {
        markBreakBlocksAsWorking(timeBlock);
      }
      simulateTimetableRow(
        timetableRow,
        timeBlock.displayTime,
        true,
        headcountChange,
        options?.isAdjustedWithResting ?? false,
      );
      if (timeBlock.isBreak) {
        unmarkBreakBlocksAsWorking(timeBlock);
      }
      calculateRestingTimeBlocks(timeBlock.displayTime);
    }
    calculateUnassignedTimeBlocks(timeBlock.displayTime);
  };

  const resetAndSimulateTimetableRow = (timetableMasterId: number) => {
    resetTimetable(false, [timetableMasterId]);
    simulateTimetable('0:00', [timetableMasterId]);
  };

  /**
   * 直前の休憩ではない人時実績を取得する
   */
  const getLastHeadcount = (currentTimeBlock: TimeBlock) => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    const [targetHour, targetBlockIndex] = getHourAndBlockIndex(currentTimeBlock);

    let lastHour = targetBlockIndex === 0 ? targetHour - 1 : targetHour;
    let lastBlockIndex = targetBlockIndex === 0 ? UNIT_BLOCK_LENGTH_PER_HOUR - 1 : targetBlockIndex - 1;
    let lastTimeBlock = hourBlocks[lastHour]?.unitTimeBlocks[lastBlockIndex];

    while (
      isExist(lastTimeBlock) &&
      isTimetableTimeBlock(lastTimeBlock) &&
      lastTimeBlock.isBreak &&
      !lastTimeBlock.behavingAsWorkTime
    ) {
      lastHour = lastBlockIndex === 0 ? lastHour - 1 : lastHour;
      lastBlockIndex = lastBlockIndex === 0 ? UNIT_BLOCK_LENGTH_PER_HOUR - 1 : lastBlockIndex - 1;
      lastTimeBlock = hourBlocks[lastHour]?.unitTimeBlocks[lastBlockIndex];
    }

    return lastTimeBlock?.headcount ?? 0;
  };

  const getCurrentTotalQuantity = (currentTimeBlock: TimetableTimeBlock) => {
    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];
    const timeBlockEndTime = getNextIntervalUnitMinutes(getTimeInteger(currentTimeBlock));
    const actualProgress = timetableRow.actualProgresses.find((actualProgress) => {
      return actualProgress.startTime < timeBlockEndTime && timeBlockEndTime <= actualProgress.endTime;
    });
    if (isExist(actualProgress)) {
      return actualProgress.totalQuantity;
    }
    return currentTimeBlock.progress.totalQuantity;
  };

  const getPreviousTotalQuantity = (currentTimeBlock: TimetableTimeBlock) => {
    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];
    const timeBlocks = timetableRow.hourBlocks.flatMap((hourBlock) => hourBlock.unitTimeBlocks);
    const currentTime = getTimeInteger(currentTimeBlock);
    // getTimeInteger()の処理が重めなので事前にfilterしておく
    const filteredTimeBlocks = timeBlocks.filter((timeBlock) => timeBlock.progress.isSimulated);
    const timeBlock = [...filteredTimeBlocks].reverse().find((timeBlock) => {
      return getTimeInteger(timeBlock) < currentTime;
    });

    if (isExist(timeBlock)) {
      return timeBlock.progress.totalQuantity;
    }

    const actualProgress = [...timetableRow.actualProgresses].reverse().find((actualProgress) => {
      return actualProgress.endTime < getNextIntervalUnitMinutes(getTimeInteger(currentTimeBlock));
    });
    if (isExist(actualProgress)) {
      return actualProgress.totalQuantity;
    }
    return 0;
  };

  /**
   * タイムブロックごとの生産性と人数を元に作業数量を算出し、作業進捗を更新する
   */
  const updateTimeBlockProgress = (currentTimeBlock: TimetableTimeBlock) => {
    const targetTime = getTimeInteger(currentTimeBlock);

    currentTimeBlock.progress.isSimulated = false;
    currentTimeBlock.progress.endTime = getNextIntervalUnitMinutes(targetTime);

    const timetableRow = timetableRowMap.value[currentTimeBlock.timetableMasterId];

    const { scheduledQuantity, targetCompletionTime } = timetableRow.header;
    if (!isExist(scheduledQuantity) && isExist(targetCompletionTime) && targetCompletionTime <= targetTime) {
      // 予定数量 がない and 目標時刻 がある and 目標時刻 を過ぎている場合 -> 何もしない
      return;
    }

    if (currentTimeBlock.progress.endTime <= timetableRow.header.lastActualProgressEndTime) {
      // 実績の進捗履歴がタイムブロックの終了時刻まで存在する場合
      // -> タイムブロックに対応する実績の進捗から総数量を設定する（他のロジックでタイムブロックを元に参照するため）
      // -> シミュレーション自体は行わない
      Object.assign(currentTimeBlock.progress, {
        totalQuantity: getCurrentTotalQuantity(currentTimeBlock),
      });
      return;
    }

    const previousTotalQuantity = getPreviousTotalQuantity(currentTimeBlock);
    if (isExist(scheduledQuantity) && previousTotalQuantity >= scheduledQuantity) {
      // すでに 予定数量 を満たしている場合 -> 何もしない
      return;
    }

    const quantityPerHour = currentTimeBlock.headcount * currentTimeBlock.progress.productivity;
    const workingSeconds =
      timeIntegerToSeconds(currentTimeBlock.progress.endTime) -
      timeIntegerToSeconds(currentTimeBlock.progress.startTime);
    let quantity = (quantityPerHour * workingSeconds) / (60 * 60);
    let seconds = workingSeconds;
    let endTime = null;

    if (!currentTimeBlock.progress.isChanged && previousTotalQuantity === 0 && quantity === 0) {
      // 直近の数量と今回の数量が 0 の場合 -> 何もしない
      return;
    }

    if (isExist(scheduledQuantity)) {
      const exceededQuantity = previousTotalQuantity + quantity - scheduledQuantity;
      if (exceededQuantity >= 0) {
        // 今回追加することで 予定数量 を超える場合 -> 追加する数値を調整する
        quantity = quantity - exceededQuantity;
        seconds = (quantity * 60 * 60) / quantityPerHour;
        endTime = secondsToTimeInteger(timeIntegerToSeconds(currentTimeBlock.progress.startTime) + seconds);
        timetableRow.header.simulatedCompletionTime = endTime;
      }
    }

    let totalQuantity = previousTotalQuantity + quantity;
    // 指定された数量と人時をもとに生産性が設定されている場合
    // 指定された数量と、生産性を元に算出された数量が小数第一位未満の単位で異なる場合がある
    // その場合は、指定された数量の単位に合わせるために、四捨五入を行う
    if (isExist(currentTimeBlock.progress.totalQuantityRoundingScale)) {
      const round =
        currentTimeBlock.progress.totalQuantityRoundingScale === 0
          ? roundToInteger
          : currentTimeBlock.progress.totalQuantityRoundingScale === 1
          ? roundToOneDecimal
          : null;
      if (isExist(round)) {
        totalQuantity = round(totalQuantity);
      }
      currentTimeBlock.progress.totalQuantityRoundingScale = null;
    }

    Object.assign(currentTimeBlock.progress, {
      quantity,
      totalQuantity,
      isSimulated: true,
    });

    if (isExist(endTime)) {
      currentTimeBlock.progress.endTime = endTime;
    }
  };

  /**
   * 1つ工程における進捗履歴を初期化する
   * 1. 指定した時間以降の進捗履歴を未シミュレーションの状態に戻す
   * 2. 終了予測を初期化する
   */
  const resetTimetableRow = (timetableRow: TimetableRow, targetTimeStr: string) => {
    const targetTime = timeStrToTimeInteger(targetTimeStr);
    timetableRow.hourBlocks
      .flatMap((hourBlock) => hourBlock.unitTimeBlocks)
      .filter((timeBlock) => targetTime <= timeBlock.progress.startTime)
      .forEach((timeBlock) => {
        timeBlock.progress.isSimulated = false;
      });
    if (
      isExist(timetableRow.header.simulatedCompletionTime) &&
      targetTime < timetableRow.header.simulatedCompletionTime
    ) {
      timetableRow.header.simulatedCompletionTime = null;
    }
  };

  const getTimeBlock = <HB extends HourBlock>(
    hourBlocks: HB[],
    targetTime: number | string,
  ): HB['unitTimeBlocks'][0] | undefined => {
    let hour, min, timeBlockIndex;
    if (typeof targetTime === 'string') {
      [hour, timeBlockIndex] = getHourAndBlockIndex(targetTime);
    } else {
      [hour, min] = unpackTimeInteger(targetTime);
      timeBlockIndex = Math.round(min / UNIT_BLOCK_MINUTES);
    }
    return hourBlocks[hour].unitTimeBlocks[timeBlockIndex];
  };

  const getRestingTimeBlock = (targetTime: number | string) => {
    const hourBlocks = state.restingTimetableRow?.hourBlocks;
    if (!isExist(hourBlocks)) {
      return null;
    }
    return getTimeBlock(hourBlocks, targetTime);
  };

  const setBreakBlocksBehavingAsWorkTime = (currentTimeBlock: TimetableBreakTimeBlock, behavingAsWorkTime: boolean) => {
    let breakBlock: TimetableBreakTimeBlock | null = currentTimeBlock;
    do {
      breakBlock.behavingAsWorkTime = behavingAsWorkTime;
      const nextBlock: TimetableTimeBlock | null = getNextTimeBlock(breakBlock);
      breakBlock = nextBlock?.isBreak ? nextBlock : null;
    } while (breakBlock !== null);
  };

  /**
   * 現在のブロックを起点に、地続きの休憩時間はすべて作業時間としてふるまうようにフラグを立てる
   * 休憩時間の人数が変更されたときに、作業時間と同じように人数を変更するために利用する
   */
  const markBreakBlocksAsWorking = (currentTimeBlock: TimetableBreakTimeBlock) => {
    setBreakBlocksBehavingAsWorkTime(currentTimeBlock, true);
  };

  /**
   * 現在のブロックを起点に、地続きの休憩時間の作業時間としてふるまうフラグを解除する
   * 休憩時間の人数が変更によるシミュレーション後は元通り休憩時間としてふるまうため
   */
  const unmarkBreakBlocksAsWorking = (currentTimeBlock: TimetableBreakTimeBlock) => {
    setBreakBlocksBehavingAsWorkTime(currentTimeBlock, false);
  };

  const getPreviousTimeBlock = <TB extends TimeBlock>(currentTimeBlock: TB): AbstractTimeBlock<TB> | null => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    if (!isExist(hourBlocks)) {
      return null;
    }
    let [hour, timeBlockIndex] = getHourAndBlockIndex(currentTimeBlock);
    hour = timeBlockIndex === 0 ? hour - 1 : hour;
    timeBlockIndex = (timeBlockIndex + UNIT_BLOCK_LENGTH_PER_HOUR - 1) % UNIT_BLOCK_LENGTH_PER_HOUR;
    return (hourBlocks[hour]?.unitTimeBlocks[timeBlockIndex] as unknown as AbstractTimeBlock<TB>) ?? null;
  };

  const getNextTimeBlock = <TB extends TimeBlock>(currentTimeBlock: TB): AbstractTimeBlock<TB> | null => {
    const hourBlocks = hourBlocksMap.value[currentTimeBlock.timetableMasterId];
    if (!isExist(hourBlocks)) {
      return null;
    }
    let [hour, timeBlockIndex] = getHourAndBlockIndex(currentTimeBlock);
    hour = timeBlockIndex === UNIT_BLOCK_LENGTH_PER_HOUR - 1 ? hour + 1 : hour;
    timeBlockIndex = (timeBlockIndex + 1) % UNIT_BLOCK_LENGTH_PER_HOUR;
    return (hourBlocks[hour]?.unitTimeBlocks[timeBlockIndex] as unknown as AbstractTimeBlock<TB>) ?? null;
  };

  const getHourAndBlockIndex = (target: TimeBlock | string) => {
    const targetTime = typeof target === 'string' ? target : target.displayTime;
    const [hourStr, minutesStr] = targetTime.split(':');
    return [Number(hourStr), Math.round(Number(minutesStr) / UNIT_BLOCK_MINUTES)];
  };

  const getTimeInteger = (timeBlock: TimeBlock) => {
    return timeStrToTimeInteger(timeBlock.displayTime);
  };

  const getBlockManHour = (
    timeBlock: TimetableTimeBlock,
    considerHeadcountShrunk: boolean, // 必要数量が完了するタイミングで必要人数が縮小されている可能性があるが、縮小される前の人数をもとに人時を算出したい場合に対応している
  ) => {
    const headcount =
      considerHeadcountShrunk && timeBlock.isHeadcountShrunk ? getLastHeadcount(timeBlock) : timeBlock.headcount;
    const workingSeconds =
      timeIntegerToSeconds(timeBlock.progress.endTime) - timeIntegerToSeconds(timeBlock.progress.startTime);
    return (headcount * workingSeconds) / (60 * 60);
  };

  const getBlockProductivity = (timeBlock: TimetableTimeBlock) => {
    const productivity = timeBlock.progress.productivity;
    const workingSeconds =
      timeIntegerToSeconds(timeBlock.progress.endTime) - timeIntegerToSeconds(timeBlock.progress.startTime);
    return (productivity * workingSeconds) / (60 * 60);
  };

  /**
   * 指定された数量と区間の人時をもとに生産性を算出し区間に適用する。
   *
   * 1. 数量が指定された地点を起点に、区間を2つに分割する。
   * - 区間1: 指定された地点以前の区間 (Anchored Time Blocks)
   *   - 指定された時間が区間の末尾の場合、その区間自体を区間1とみなす。
   * - 区間2: 指定された地点より後の区間 (Rest Time Blocks)
   *
   * 2. 区間1の処理:
   * 「指定した数量」と「直前の区間の数量」の差を区間1の人時で行う場合の生産性を算出し、区間1に適用する。
   *
   * 3. 区間2の処理:
   * 「区間2の末尾の数量」と「指定した数量」の差を区間2の人時で行う場合の生産性を算出し、区間2に適用する。
   *   実行条件: 指定された時間が区間の末尾でない場合のみ、この処理を行う。
   *
   * 4. 1つ後ろの区間の処理 (Next Section):
   * 「1つ後ろの区間の末尾の数量」と「指定した数量」の差を1つ後ろの区間の処理の人時で行う場合の生産性を算出し、1つ後ろの区間に適用する。
   *   実行条件: 指定された時間が区間の末尾である場合かつ1つ後ろの区間が存在する場合のみ、この処理を行う。
   */
  const updateQuantity = (
    timetableMasterId: number,
    progressSectionQuarterTime: ProgressSectionQuarterTime,
    quantityToUpdate: number,
  ) => {
    const timetableRow = timetableRowMap.value[timetableMasterId];
    if (!isExist(timetableRow)) {
      return;
    }
    const isSectionEndUpdate = progressSectionQuarterTime.end === progressSectionQuarterTime.target;

    const allTimeBlocks = timetableRow.hourBlocks.flatMap((hourBlock) => hourBlock.unitTimeBlocks);
    const startTimeBlockIndex = allTimeBlocks.findIndex(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.start,
    );
    const endTimeBlockIndex = allTimeBlocks.findIndex(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.end,
    );
    const targetTimeBlock = allTimeBlocks.find(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.target,
    );
    const targetTimeBlockIndex = allTimeBlocks.findIndex(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.target,
    );

    const firstNextSectionTimeBlock = allTimeBlocks[endTimeBlockIndex + 1];
    const nextSectionProductivity = isExist(firstNextSectionTimeBlock?.progress?.productivity)
      ? roundToOneDecimal(firstNextSectionTimeBlock.progress.productivity)
      : null;
    const remainingTimeBlocks = allTimeBlocks.slice(endTimeBlockIndex + 1);
    let nextSectionTimeBlocksCount = remainingTimeBlocks.findIndex((timeBlock) => {
      const isNextSection = (timeBlock: TimetableTimeBlock) =>
        timeBlock.progress.isSimulated &&
        roundToOneDecimal(timeBlock.progress.productivity) === nextSectionProductivity;
      return !isNextSection(timeBlock);
    });
    if (nextSectionTimeBlocksCount === -1) {
      nextSectionTimeBlocksCount = remainingTimeBlocks.length;
    }
    const nextSectionTimeBlocks = allTimeBlocks.slice(
      targetTimeBlockIndex + 1,
      targetTimeBlockIndex + 1 + nextSectionTimeBlocksCount,
    );

    const anchoredTimeBlocks = allTimeBlocks.slice(startTimeBlockIndex, targetTimeBlockIndex + 1);
    const lastTimeBlockInBeforeSection = allTimeBlocks[startTimeBlockIndex - 1];
    const anchoredQuantity = quantityToUpdate - (lastTimeBlockInBeforeSection?.progress.totalQuantity ?? 0);
    // 区間1を人時算出する際、縮小される前の人数を参照する
    // 入力した数量がシミュレーション後の数量と変わってしまう可能性があるのを防ぐため
    const anchoredManHour = anchoredTimeBlocks.reduce((acc, timeBlock) => acc + getBlockManHour(timeBlock, true), 0);
    const anchoredProductivity = anchoredManHour !== 0 ? anchoredQuantity / anchoredManHour : 0;
    // 「区間末尾の更新」かつ「1つ後ろの区間が存在しない」場合、区間1だけでなくそれ以降のすべてのタイムブロックにも新しい生産性を適用する
    // -> 区間の末尾でシミュレーションが完了しなかった場合、同一の生産性でシミュレーションを継続するため
    const timeBlocksToUpdate =
      isSectionEndUpdate && nextSectionTimeBlocks.length === 0
        ? allTimeBlocks.slice(startTimeBlockIndex)
        : anchoredTimeBlocks;
    for (const timeBlock of timeBlocksToUpdate) {
      Object.assign(timeBlock.progress, {
        productivity: anchoredProductivity,
        isChanged: true,
      });
    }
    if (isExist(targetTimeBlock)) {
      targetTimeBlock.progress.totalQuantityRoundingScale = quantityToUpdate % 1 === 0 ? 0 : 1;
    }

    const isScheduledQuantityReached =
      isExist(timetableRow.header.scheduledQuantity) && timetableRow.header.scheduledQuantity <= quantityToUpdate;

    if (!isSectionEndUpdate) {
      if (isScheduledQuantityReached) {
        return;
      }
      const restTimeBlocks = allTimeBlocks.slice(targetTimeBlockIndex + 1, endTimeBlockIndex + 1);
      const restLastTimeBlock = restTimeBlocks[restTimeBlocks.length - 1];
      if (restLastTimeBlock === undefined) {
        return;
      }
      const restQuantity = restLastTimeBlock.progress.totalQuantity - quantityToUpdate;
      const restManHour = restTimeBlocks.reduce((acc, timeBlock) => acc + getBlockManHour(timeBlock, false), 0);
      const restProductivity = restManHour !== 0 ? restQuantity / restManHour : 0;
      for (const timeBlock of restTimeBlocks) {
        Object.assign(timeBlock.progress, {
          productivity: restProductivity,
          isChanged: true,
        });
      }
      restLastTimeBlock.progress.totalQuantityRoundingScale =
        restLastTimeBlock.progress.totalQuantity % 1 === 0 ? 0 : 1;
    } else if (nextSectionTimeBlocks.length > 0) {
      const nextSectionLastTimeBlock = nextSectionTimeBlocks[nextSectionTimeBlocks.length - 1];
      if (nextSectionLastTimeBlock === undefined) {
        throw Error('Here cannot be reached.');
      }
      const nextSectionQuantity = nextSectionLastTimeBlock.progress.totalQuantity - quantityToUpdate;
      const nextSectionManHour = nextSectionTimeBlocks.reduce(
        (acc, timeBlock) => acc + getBlockManHour(timeBlock, false),
        0,
      );
      const nextSectionProductivity = nextSectionManHour !== 0 ? nextSectionQuantity / nextSectionManHour : 0;
      for (const timeBlock of nextSectionTimeBlocks) {
        Object.assign(timeBlock.progress, {
          productivity: nextSectionProductivity,
          isChanged: true,
        });
      }
      nextSectionLastTimeBlock.progress.totalQuantityRoundingScale =
        nextSectionLastTimeBlock.progress.totalQuantity % 1 === 0 ? 0 : 1;
    }
  };

  /**
   * 指定された生産性を区間に適用する。
   *
   * 1. 数量が指定された地点を起点に、区間を2つに分割する。
   * - 区間1: 指定された地点より前の区間 (Anchored Time Blocks)
   * - 区間2: 指定された地点以降の区間 (Rest Time Blocks)
   *   - 指定された時間が区間の先頭の場合、その区間自体を区間2とみなす。
   *
   * 2. 区間1の処理:
   * 何も行わない。
   *
   * 3. 区間2の処理:
   * 指定された生産性を区間2に適用する。
   */
  const updateProductivity = (
    timetableMasterId: number,
    progressSectionQuarterTime: ProgressSectionQuarterTime,
    productivityToUpdate: number,
  ) => {
    const timetableRow = timetableRowMap.value[timetableMasterId];
    if (!isExist(timetableRow)) {
      return;
    }
    const isSectionStartUpdate = progressSectionQuarterTime.start === progressSectionQuarterTime.target;

    const allTimeBlocks = timetableRow.hourBlocks.flatMap((hourBlock) => hourBlock.unitTimeBlocks);
    const targetTimeBlockIndex = allTimeBlocks.findIndex(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.target,
    );
    const endTimeBlockIndex = allTimeBlocks.findIndex(
      (timeBlock) => timeBlock.displayTime === progressSectionQuarterTime.end,
    );

    const restTimeBlocks = allTimeBlocks.slice(targetTimeBlockIndex, endTimeBlockIndex + 1);

    // 区間先頭の更新でない場合または次の区間が未シミュレートの場合、区間2だけでなくそれ以降のすべてのタイムブロックにも新しい生産性を適用する
    // -> 区間の末尾でシミュレーションが完了しなかった場合、同一の生産性でシミュレーションを継続するため
    const nextSectionFirstTimeBlock = allTimeBlocks[endTimeBlockIndex + 1];
    const shouldUpdateAllRemainingBlocks = !isSectionStartUpdate || !nextSectionFirstTimeBlock?.progress.isSimulated;
    const timeBlocksToUpdate = shouldUpdateAllRemainingBlocks
      ? allTimeBlocks.slice(targetTimeBlockIndex)
      : restTimeBlocks;

    for (const timeBlock of timeBlocksToUpdate) {
      Object.assign(timeBlock.progress, {
        productivity: productivityToUpdate,
        isChanged: true,
      });
    }
  };

  const updateBreakTime = (
    timetableMasterId: number,
    break1TimeRange: TimeRange | null,
    break2TimeRange: TimeRange | null,
  ) => {
    const timetableRow = timetableRowMap.value[timetableMasterId];
    if (!isExist(timetableRow)) {
      return;
    }
    if (
      isSameTimeRange(timetableRow.header.break1TimeRange, break1TimeRange) &&
      isSameTimeRange(timetableRow.header.break2TimeRange, break2TimeRange)
    ) {
      return;
    }
    timetableRow.header.break1TimeRange = break1TimeRange;
    timetableRow.header.break2TimeRange = break2TimeRange;
    /**
     * TODO: 要パフォーマンス改善
     * 本処理は何度も実行されるものではなく、応答時間も許容範囲内ではあるが、それでも遅いことは遅いので、resetTimetable を各工程ごとに呼び出すなど、ゆくゆくは改善したい。
     * 参照: https://github.com/kurando-inc/logiboard-ap/pull/2162#discussion_r2000825618
     */
    resetAndSimulateTimetableRow(timetableMasterId);
  };

  const setTotalProductivity = () => {
    for (const timetableRow of state.timetable) {
      if (isExist(timetableRow.header.totalProductivity)) {
        updateProductivity(
          timetableRow.header.masterId,
          {
            start: '0:00',
            target: '0:00',
            end: '47:45',
          },
          timetableRow.header.totalProductivity,
        );
      }
    }
  };

  /**
   * タイムラインを再取得してシミュレーションをし直す
   */
  const fetchAndSimulate = async (option = { isResetHeader: true, isResetHeadcount: true }) => {
    isResetHeader.value = option.isResetHeader;
    resetHeadcountTargetIds.value = option.isResetHeadcount ? allTimetableMasterIds.value : [];
    await fetchProgressHeaders();
    await setBudgetGroupPlanBoardMisc();
  };

  /**
   * シミュレーション結果を設定し直す
   * その際にシミュレーション用の設定などは初期化しない
   */
  const resetSimulation = () => {
    resetTimetable(isResetHeader.value, resetHeadcountTargetIds.value);
    filterTimetable(state.timetable);
    simulateTimetable('0:00', resetHeadcountTargetIds.value);
    isNoSimulate.value = false;
    isResetHeader.value = false;
    resetHeadcountTargetIds.value = [];
  };

  /**
   * タイムラインを再取得して、シミュレーションはしない
   */
  const fetchAndNoSimulate = async () => {
    isNoSimulate.value = true;
    fetchProgressHeaders();
  };

  /**
   * 特定のタイムラインを再取得してシミュレーションをし直す
   */
  const fetchAndSimulateTimetableRow = async (timetableMasterId: number) => {
    resetHeadcountTargetIds.value = [timetableMasterId];
    fetchProgressHeaders();
  };

  /**
   * 指定した時間が休憩時間かどうか判定する
   */
  const isBreakTime = (targetTime: number, breakTimeRanges: (TimeRange | null)[]) => {
    return breakTimeRanges
      .filter(isStrictTimeRange)
      .some((breakTimeRange) => breakTimeRange.startTime <= targetTime && targetTime < breakTimeRange.endTime);
  };

  const waitLoading = (): void => {
    state.isLoading = true;
  };

  const finishLoading = (): void => {
    state.isLoading = false;
  };

  provide({
    state: readonly(state),
    displayedTimetable,
    displayedTimetableMasters,
    timetableRowMap,
    timetableRowStyle,
    simulateTimetable,
    simulateSpecificTimetableRow,
    calculateUnassignedTimeBlocks,
    updateHeadcountAndSimulateTimetableRow,
    updateQuantity,
    updateProductivity,
    updateBreakTime,
    setTotalProductivity,
    fetchAndSimulate,
    fetchAndNoSimulate,
    fetchAndSimulateTimetableRow,
    resetSimulation,
    saveProgressDetailCandidate,
    waitLoading,
    finishLoading,
  });
}

export function useDailySimulation(): InjectionValue {
  return inject();
}
