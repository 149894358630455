

















import { defineComponent, type PropType, computed } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { orDefault } from 'src/util/filters';
import { formatNumber } from '../../utils/filters';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { getQuantityColor } from 'src/util/progressHeaderHelpers';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup() {
    const { resultQuantity, plannedQuantity } = useProgressSummary();
    const progressColor = computed(() => getQuantityColor(resultQuantity.value, plannedQuantity.value));
    return {
      progressColor,
    };
  },
});
