











import { defineComponent } from '@vue/composition-api';
import { IconButton } from 'src/components/UIComponents/Buttons/IconButton';

export default defineComponent({
  components: {
    IconButton,
  },
  props: {
    onDelete: {
      type: Function,
      required: true,
    },
  },
});
