import {
  type ChartData,
  type ChartDataSets,
  type ChartOptions,
  type ChartTooltipItem,
  type ChartTooltipOptions,
  type ChartXAxe,
  type ChartYAxe,
} from 'chart.js';
import { type AnnotationOptions } from 'chartjs-plugin-annotation';
import { isToday, isYesterday } from 'date-fns';
import { dateToTimeInteger } from 'src/util/datetime';
import { type TimeInteger } from 'src/models/common';
import { type ProgressHeader, type ProgressDetail } from 'src/models/progressHeader';
import { type ProgressPlanDataWithCumulativeValues } from 'src/models/progressPlan';
import moment from 'src/util/moment-ja';
import { formatNumber, formatFixedDecimal } from '../../utils/filters';

type ProgressChartConfiguration = {
  chartData: ChartData;
  options: ChartOptions;
};

const colors = {
  blue: '#8497B0',
  bgBlue: 'rgba(200, 210, 227, 0.5)',
  green: '#70AD47',
  red: '#FF0000',
  black: '#000000',
};

const axisIds = {
  x: 'x-axis-0',
  yLeft: 'y-axis-0',
  yRight: 'y-axis-1',
};

function buildLabels(): TimeInteger[] {
  return [...[...Array(48)].map((_, i) => i * 10000), 475959]; // 0, 10000, 20000, ..., 475959
}

function sortProgressDetailsByEndTime(progressHeader: ProgressHeader): ProgressDetail[] {
  return [...progressHeader.progress_details].sort((a, b) => a.end_time - b.end_time);
}

function buildQuantityDatasets(progressHeader: ProgressHeader): ChartDataSets[] {
  const data = sortProgressDetailsByEndTime(progressHeader).reduce(
    (acc, cur) => [
      ...(acc.length === 0 ? [{ x: cur.start_time, y: 0 }] : acc),
      {
        x: cur.end_time,
        y: cur.quantity + (acc[acc.length - 1]?.y ?? 0), // 累計数量
      },
    ],
    [] as { x: number; y: number }[],
  );
  return [
    {
      backgroundColor: colors.bgBlue,
      borderColor: colors.blue,
      borderWidth: 4,
      data: data,
      fill: true,
      label: '累計数量',
      lineTension: 0,
      pointBackgroundColor: colors.blue,
      pointBorderWidth: 2,
      yAxisID: axisIds.yLeft,
    },
  ];
}

function buildPlannedQuantityDatasets(progressPlanDataList: ProgressPlanDataWithCumulativeValues[]): ChartDataSets[] {
  let data: { x: number; y: number }[] = [];
  if (progressPlanDataList.length > 0) {
    data = [
      { x: progressPlanDataList[0].startTime, y: 0 },
      ...progressPlanDataList.map((progressPlanDataList) => ({
        x: progressPlanDataList.endTime,
        y: progressPlanDataList.cumulativeQuantity,
      })),
    ];
  }
  return [
    {
      borderColor: colors.blue,
      borderWidth: 1,
      data,
      fill: false,
      lineTension: 0,
      pointHoverRadius: 0,
      pointRadius: 0,
      yAxisID: axisIds.yLeft,
    },
  ];
}

function buildProductivityDatasets(progressHeader: ProgressHeader): ChartDataSets[] {
  const data = sortProgressDetailsByEndTime(progressHeader)
    .filter((v) => v.productivity !== null)
    .map((v) => ({
      x: v.end_time,
      y: v.productivity!,
    }));

  return [
    {
      borderColor: colors.green,
      borderWidth: 3,
      data,
      fill: false,
      label: '生産性',
      lineTension: 0,
      pointBackgroundColor: colors.green,
      pointBorderWidth: 2,
      yAxisID: axisIds.yRight,
    },
  ];
}

function buildDatasets(
  progressHeader: ProgressHeader,
  progressPlanDataList: ProgressPlanDataWithCumulativeValues[],
): ChartDataSets[] {
  return [
    ...buildQuantityDatasets(progressHeader),
    ...buildPlannedQuantityDatasets(progressPlanDataList),
    ...buildProductivityDatasets(progressHeader),
  ];
}

function buildCurrentTimeAnnotation(progressHeader: ProgressHeader): AnnotationOptions | null {
  const now = new Date();
  if (!isToday(progressHeader.dt) && !isYesterday(progressHeader.dt)) {
    return null;
  }
  const currentTime = isToday(progressHeader.dt) ? dateToTimeInteger(now) : dateToTimeInteger(now) + 240000;
  return {
    borderColor: colors.black,
    borderWidth: 1,
    borderDash: [6, 6],
    mode: 'vertical',
    scaleID: axisIds.x,
    type: 'line',
    value: currentTime,
  };
}

function buildReachedScheduledQuantityTimeAnnotation(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): AnnotationOptions | null {
  if (
    progressHeader.scheduled_quantity === null ||
    lastProgressPlanData === null ||
    lastProgressPlanData.cumulativeQuantity !== progressHeader.scheduled_quantity
  ) {
    return null;
  }
  return {
    borderColor: colors.red,
    borderWidth: 1,
    mode: 'vertical',
    scaleID: axisIds.x,
    type: 'line',
    value: lastProgressPlanData.endTime,
  };
}

function buildScheduledQuantityAnnotation(progressHeader: ProgressHeader): AnnotationOptions | null {
  if (progressHeader.scheduled_quantity === null) {
    return null;
  }

  return {
    borderColor: colors.blue,
    borderWidth: 1,
    mode: 'horizontal',
    scaleID: axisIds.yLeft,
    type: 'line',
    value: progressHeader.scheduled_quantity,
  };
}

function buildTargetProductivityAnnotation(progressHeader: ProgressHeader): AnnotationOptions | null {
  if (progressHeader.target_productivity === null) {
    return null;
  }

  return {
    borderColor: colors.green,
    borderWidth: 1,
    mode: 'horizontal',
    scaleID: axisIds.yRight,
    type: 'line',
    value: progressHeader.target_productivity,
  };
}

function wrapInArray<T>(value: T | null): T[] {
  return value !== null ? [value] : [];
}

function buildAnnotations(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): AnnotationOptions[] {
  return [
    ...wrapInArray(buildCurrentTimeAnnotation(progressHeader)),
    ...wrapInArray(buildReachedScheduledQuantityTimeAnnotation(progressHeader, lastProgressPlanData)),
    ...wrapInArray(buildScheduledQuantityAnnotation(progressHeader)),
    ...wrapInArray(buildTargetProductivityAnnotation(progressHeader)),
  ];
}

function parseTimeInteger(time: TimeInteger): moment.Moment {
  const [baseTime, offsetDays] = time < 240000 ? [time, 0] : [time - 240000, 1];
  return moment(String(baseTime).padStart(6, '0'), 'HHmmss').add(offsetDays, 'days');
}

function buildXAxes(): ChartXAxe[] {
  return [
    {
      bounds: 'ticks',
      gridLines: {
        display: false,
      },
      id: axisIds.x,
      ticks: {
        maxRotation: 0,
        source: 'labels',
      },
      time: {
        displayFormats: {
          hour: 'HH:mm',
        },
        parser: parseTimeInteger,
        tooltipFormat: 'HH:mm',
        unit: 'hour',
      },
      type: 'time',
    },
  ];
}

function getMaxQuantity(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): number {
  return Math.max(
    progressHeader.scheduled_quantity ?? 0,
    progressHeader.result_quantity ?? 0,
    lastProgressPlanData?.cumulativeQuantity ?? 0,
  );
}

function getMaxProductivity(progressHeader: ProgressHeader): number {
  return Math.max(
    progressHeader.target_productivity ?? 0,
    ...progressHeader.progress_details.map((detail) => detail.productivity ?? 0),
  );
}

function calculateScaleBounds(baseValue: number): { min: number; max: number; stepSize: number } {
  const stepCount = 5;

  if (baseValue <= 0) {
    // グリッド線は表示したいので値を設定する
    // 下の計算では設定されない値にすることで、別ロジックで目盛りを表示するかどうかを判別できるようにする
    const stepSize = 0.001;
    return {
      min: 0,
      max: stepSize * stepCount,
      stepSize,
    };
  }

  // maxは以下を満たす数にする
  // stepCountの倍数
  // stepCountで割った後の数の上位3桁目以降の桁が0（割った後の数が2桁以下の場合はbaseValueよって倍数を調整）
  const base = Math.ceil(baseValue * 10); // 小数点第1位まで計算に含めるため10倍する（以降、10倍した前提の計算であることに注意）
  const step = Math.floor(base / stepCount);
  const stepLength = String(step).length;
  const roundingUnit =
    stepCount *
    (() => {
      if (stepLength >= 4) {
        return 10 ** (stepLength - 2);
      }
      if (baseValue >= 100) {
        return 50;
      }
      if (baseValue >= 1) {
        return 10;
      }
      return 1;
    })();
  const tenfoldMax = Math.floor((base + (roundingUnit - 1)) / roundingUnit) * roundingUnit;
  const tenfoldStepSize = tenfoldMax / stepCount;

  return {
    min: 0,
    max: tenfoldMax / 10,
    stepSize: tenfoldStepSize / 10,
  };
}

function buildYAxis({ maxValue, position }: { maxValue: number; position: 'left' | 'right' }): ChartYAxe {
  const scaleBounds = calculateScaleBounds(maxValue);

  return {
    gridLines: {
      borderDash: [2],
      drawBorder: false,
      drawOnChartArea: position === 'left', // 左右で表示すると線の色が濃く見えるので左側のみ表示
      tickMarkLength: 35,
    },
    id: position === 'left' ? axisIds.yLeft : axisIds.yRight,
    position,
    ticks: {
      ...scaleBounds, // 目盛りの数を固定するため、min, max, stepSizeを設定する
      autoSkip: false,
      callback: () => '', // チャート上に目盛りは表示させないが、上部の余白やグリッド線を表示させるためdisplay:falseではなく空文字にする
    },
    type: 'linear',
  };
}

function buildQuantityAxis(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): ChartYAxe {
  return buildYAxis({
    maxValue: getMaxQuantity(progressHeader, lastProgressPlanData) * 1.1, // 上部に余白を設けるため、適当な倍率をかける
    position: 'left',
  });
}

function buildProductivityAxis(progressHeader: ProgressHeader): ChartYAxe {
  return buildYAxis({
    maxValue: getMaxProductivity(progressHeader) * 1.6, // 最大値を真ん中やや上あたりに表示するため、適当な倍率をかける
    position: 'right',
  });
}

function buildYAxes(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): ChartYAxe[] {
  return [buildQuantityAxis(progressHeader, lastProgressPlanData), buildProductivityAxis(progressHeader)];
}

function shouldEnableTooltip({ datasetIndex }: ChartTooltipItem): boolean {
  // 0: 累計数量, 2: 生産性 （datasetsに設定した順番）
  return datasetIndex !== undefined && [0, 2].includes(datasetIndex);
}

function getFormattedTooltipValue({ datasetIndex, yLabel }: ChartTooltipItem): string {
  if (typeof yLabel !== 'number') {
    return yLabel ?? '';
  }
  if (datasetIndex === 0) {
    return formatNumber(yLabel) ?? '';
  }
  if (datasetIndex === 2) {
    return formatFixedDecimal(yLabel) ?? '';
  }
  return String(yLabel);
}

function formatTooltipLabel(item: ChartTooltipItem, data: ChartData): string {
  return `${data.datasets?.[item.datasetIndex!]?.label ?? ''}: ${getFormattedTooltipValue(item)}`;
}

function buildTooltips(): ChartTooltipOptions {
  return {
    callbacks: {
      label: formatTooltipLabel,
    },
    enabled: true,
    filter: shouldEnableTooltip,
  };
}

function buildOptions(
  progressHeader: ProgressHeader,
  lastProgressPlanData: ProgressPlanDataWithCumulativeValues | null,
): ChartOptions {
  return {
    annotation: {
      annotations: buildAnnotations(progressHeader, lastProgressPlanData),
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    layout: {
      padding: {
        left: -10,
        right: -10,
      },
    },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: buildXAxes(),
      yAxes: buildYAxes(progressHeader, lastProgressPlanData),
    },
    tooltips: buildTooltips(),
  };
}

export function buildProgressChartConfiguration(
  progressHeader: ProgressHeader,
  progressPlanDataList: ProgressPlanDataWithCumulativeValues[],
): ProgressChartConfiguration {
  const lastProgressPlanData = progressPlanDataList[progressPlanDataList.length - 1] ?? null;
  const labels = buildLabels();
  const datasets = buildDatasets(progressHeader, progressPlanDataList);
  const options = buildOptions(progressHeader, lastProgressPlanData);

  return {
    chartData: {
      labels,
      datasets,
    },
    options,
  };
}
