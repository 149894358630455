import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import type { WorkPlanHeaderByDateParams, WorkPlanHeaderUpsertParams } from 'src/models/api/workPlanHeaderRequest';
import type { WorkPlanHeaderByDateResponse, WorkPlanHeaderUpsertResponse } from 'src/models/api/workPlanHeaderResponse';

export default {
  forDate({
    workplace_id,
    budget_group_id,
    ...data
  }: WorkPlanHeaderByDateParams): Promise<AxiosResponse<WorkPlanHeaderByDateResponse>> {
    return axios.get(
      utils.getApiUrl(`/workplaces/${workplace_id}/budget_groups/${budget_group_id}/work_plan_headers/by_date`),
      {
        params: data,
      },
    );
  },
  upsert({
    workplace_id,
    budget_group_id,
    ...data
  }: WorkPlanHeaderUpsertParams): Promise<AxiosResponse<WorkPlanHeaderUpsertResponse>> {
    return axios.post(
      utils.getApiUrl(`/workplaces/${workplace_id}/budget_groups/${budget_group_id}/work_plan_headers/upsert`),
      data,
    );
  },
};
