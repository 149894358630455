import { type Validator } from 'src/composables/useCustomValidator';
import { type TimeInteger } from 'src/models/common';
import { unpackTimeInteger } from 'src/util/datetime';

function isValidMinutes(time: TimeInteger): boolean {
  const [_, minutes] = unpackTimeInteger(time);
  return [0, 15, 30, 45].includes(minutes);
}

export const timeValidator: Validator = {
  name: 'time',
  validate(value: unknown) {
    return value === null || (typeof value === 'number' && isValidMinutes(value));
  },
};

export const featuresValidator: Validator = {
  name: 'features',
  validate(value: unknown) {
    return value !== null && typeof value === 'object';
  },
};
