import { type TimeInteger } from 'src/models/common';

export type TimeRange = {
  startTime: TimeInteger | null;
  endTime: TimeInteger | null;
};

export const isSameTimeRange = (a: TimeRange | null, b: TimeRange | null) => {
  // null 同士の場合は同じとみなす
  if (a === null && b === null) {
    return true;
  }
  // 片方が null の場合は異なるとみなす
  if (a === null || b === null) {
    return false;
  }
  // どちらも存在している場合は、startTime と endTime を比較する
  return a.startTime === b.startTime && a.endTime === b.endTime;
};
