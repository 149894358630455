import { computed, type ComputedRef, Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { timeIntegerToSeconds } from 'src/util/datetime';
import { type ProgressHeader } from 'src/models/progressHeader';
import type { ProgressPlanDataWithCumulativeValues } from 'src/models/progressPlan';
import { useProgressPlan } from './useProgressPlan';

type InjectionValue = {
  progressPlanDataListWithCumulativeValues: ComputedRef<ProgressPlanDataWithCumulativeValues[]>;
  resultQuantity: ComputedRef<number>;
  plannedQuantity: ComputedRef<number | null>;
  plannedProductivity: ComputedRef<number | null>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressSummary');

export const useProgressSummaryProvider = (progressHeader: Ref<ProgressHeader>) => {
  const { progressPlan } = useProgressPlan();

  const progressPlanDataListWithCumulativeValues = computed<ProgressPlanDataWithCumulativeValues[]>(() => {
    const progressPlanValue = progressPlan.value;
    if (progressPlanValue === null) {
      return [];
    }
    let cumulativeQuantity = 0;
    let cumulativeManHours = 0;
    return progressPlanValue.data.map<ProgressPlanDataWithCumulativeValues>((data) => {
      cumulativeQuantity += data.quantity;
      cumulativeManHours += data.manHours;
      return {
        ...data,
        cumulativeQuantity,
        cumulativeManHours,
      };
    });
  });

  const targetData = computed<ProgressPlanDataWithCumulativeValues | null>(() => {
    const endTime = progressHeader.value.end_time;
    if (endTime === null) {
      return null;
    }
    return (
      progressPlanDataListWithCumulativeValues.value.find(
        (data) => data.startTime <= endTime && endTime <= data.endTime,
      ) ?? null
    );
  });

  const resultQuantity = computed(() => {
    return progressHeader.value.result_quantity ?? 0;
  });

  const plannedQuantity = computed<number | null>(() => {
    const endTime = progressHeader.value.end_time;
    if (endTime === null || targetData.value === null) {
      return null;
    }
    const durationRatio =
      (timeIntegerToSeconds(targetData.value.endTime) - timeIntegerToSeconds(endTime)) /
      (timeIntegerToSeconds(targetData.value.endTime) - timeIntegerToSeconds(targetData.value.startTime));
    return targetData.value.cumulativeQuantity - targetData.value.quantity * durationRatio;
  });

  const plannedProductivity = computed<number | null>(() => {
    if (targetData.value === null || targetData.value.manHours === 0) {
      return null;
    }
    return targetData.value.quantity / targetData.value.manHours;
  });

  provide({
    progressPlanDataListWithCumulativeValues,
    resultQuantity,
    plannedQuantity,
    plannedProductivity,
  });
};

export function useProgressSummary(): InjectionValue {
  return inject();
}
