// https://qiita.com/Tsuyoshi84/items/0524ab994745e9acd5ab

// TからKに指定されたkeyのみを取り出した型を定義する.
export type PickProps<T, K extends keyof T> = { [P in Extract<keyof T, K>]: T[P] };

// オブジェクトからnullのプロパティーをとりのぞき、各プロパティを任意にする
export type NonNullablePropsPartial<T> = { [K in keyof T]?: NonNullable<T[K]> };

// オブジェクトからnullのプロパティーをとりのぞき、各プロパティを必須にする
export type NonNullablePropsRequired<T> = { [K in keyof T]-?: NonNullable<T[K]> };

// 空オブジェクト型
export type EmptyObject = { [key: string]: never };

// K で指定したプロパティを必須にする
export type SomeRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

// K で指定したキーをオプショナルにする
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// K で指定したキーをオプショナルにし、O で指定したキーを Omit する
//
// 使い方
// type A = {
//   hoge: number,
//   fuga: number,
//   bar: number,
//   piyo: number,
// }
//
// type B = WithOptional<A, 'hoge' | 'fuga', 'piyo'>
//
// // hoge, fuga は Optional になり piyo は Omit される
// // {
// //   hoge?: number,
// //   fuga?: number,
// //   bar: number,
// // }
export type WithOptionalWithOmit<T, K extends keyof T, O extends keyof T> = Omit<WithOptional<T, K>, O>;

// csv行の型定義
export type CsvLine = (string | number)[];

type Writable<T> = { -readonly [K in keyof T]: T[K] };

type FlexibleInstanceType<T> = T extends abstract new (...args: any) => any ? InstanceType<T> : never;

type ReadonlyComponentProps<T> = FlexibleInstanceType<T> extends { $props: infer P } ? P : never;

// Vueコンポーネントからpropsの型を取り出すための型。ComponentProps<typeof SomeComponent>のように使う
export type ComponentProps<T> = Writable<ReadonlyComponentProps<T>>;

/**
 * キャメルケースに変換する型
 * 主にモデルの型のプロパティがスネークケースで定義されているのをキャメルケースに変換したい場合に使用することを想定している
 *
 * 以下のライブラリから移植したものをカスタマイズして実装
 * https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/camelize/index.d.ts
 */
type CamelSeparator = '-' | '_';

/**
 * Converts a string to camel case.
 */
type CamelCase<
  S extends string,
  Sep extends CamelSeparator = '_',
> = S extends `${infer FirstWord}${Sep}${infer SecondChar}${infer Remaining}`
  ? `${Lowercase<FirstWord>}${Uppercase<SecondChar>}${CamelCase<Remaining, Sep>}`
  : Lowercase<S>;

export type Camelize<T, Sep extends CamelSeparator = '_'> = T extends Date
  ? T
  : T extends RegExp
  ? T
  : T extends Array<infer I>
  ? Array<Camelize<I, Sep>>
  : T extends object
  ? {
      [K in keyof T as CamelCase<string & K, Sep>]: Camelize<T[K], Sep>;
    }
  : T;

// 第一引数に取ったオブジェクトから第二引数に含まれるプロパティのみを抽出し、
// * それらの値が全てnullishでない場合、抽出したサブセットを返す.
// * 一つでもnullishが含まれる場合、nullを返す. (nullが返された場合、呼び出し側でエラー処理を行う)
// (第二引数には所望の型のキーとダミーの値を持ったオブジェクトをテンプレートとして渡す)
export const deriveNonNullSubset = <T extends Record<string, unknown>, R extends NonNullablePropsPartial<T>>(
  srcObj: T,
  dstObjTemplate: R,
): R | null => {
  const ret = { ...dstObjTemplate };
  const dstObjKeys = Object.keys(dstObjTemplate) as Array<keyof T>;
  for (const k of dstObjKeys) {
    const val = srcObj[k];
    if (val == null) {
      // 一つでもnullishがあれば変換が不可能なのでなんらか失敗させる. throwしてもいいが...
      return null;
    }
    ret[k] = val as R[typeof k];
  }
  return ret;
};
