import { ref, onMounted, computed, type Ref } from '@vue/composition-api';
import aggregationTargetStaffTagApi from 'src/apis/workplace_masters/aggregationTargetStaffTag';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import {
  convertAggregationTargetStaffTagIndexResponse,
  type AggregationTargetStaffTag,
} from 'src/models/workplaceMasters/aggregationTargetStaffTag';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  aggregationTargetStaffTags: Ref<AggregationTargetStaffTag[]>;
  fetchAggregationTargetStaffTags: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useAggregationTargetStaffTags');

async function getAggregationTargetStaffTags({
  workplaceId,
}: {
  workplaceId: number;
}): Promise<AggregationTargetStaffTag[]> {
  const { data } = await aggregationTargetStaffTagApi.index({ workplace_id: workplaceId });
  return convertAggregationTargetStaffTagIndexResponse(data);
}

export function useAggregationTargetStaffTagsProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const aggregationTargetStaffTags = ref<AggregationTargetStaffTag[]>([]);
  const fetchAggregationTargetStaffTags = errorBoundary.wrap(
    async () => {
      aggregationTargetStaffTags.value = await getAggregationTargetStaffTags({ workplaceId });
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const sortedAggregationTargetStaffTags = computed(() =>
    [...aggregationTargetStaffTags.value].sort((a, b) => a.id - b.id),
  );

  onMounted(fetchAggregationTargetStaffTags);

  provide({
    aggregationTargetStaffTags: sortedAggregationTargetStaffTags,
    fetchAggregationTargetStaffTags: fetchAggregationTargetStaffTags,
  });
}

export function useAggregationTargetStaffTags(): InjectionValue {
  return inject();
}
