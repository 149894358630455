

























































import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { defineComponent } from '@vue/composition-api';
import { useStaffWorkPlanPrintModal } from 'src/views/Dashboard/Workplace/StaffWorkPlan/composables/useStaffWorkPlanPrintModal';
import NumberInput from 'src/components/UIComponents/Inputs/NumberInput/NumberInput.vue';
import { useCustomValidator, Validator } from 'src/composables/useCustomValidator';

export default defineComponent({
  components: {
    ModalForm,
    FormGroup,
    FormItem,
    NumberInput,
  },
  setup() {
    const { startHour, endHour, isDisplayedStaffWorkPlanPrintModal, printStaffWorkPlan, closeStaffWorkPlanPrintModal } =
      useStaffWorkPlanPrintModal();

    const endHourValidator: Validator = {
      name: 'endHourValidator',
      validate(endHour: unknown, targets) {
        const startHour = Array.isArray(targets) ? targets[0] : null;

        if (typeof startHour !== 'number' || isNaN(startHour)) {
          return false;
        }
        if (typeof endHour !== 'number' || isNaN(endHour)) {
          return false;
        }
        const hours = endHour - startHour;
        if (hours < 0 || hours > 23) {
          return false;
        }
        return true;
      },
      options: { hasTarget: true },
    };

    const endHourRule = useCustomValidator(endHourValidator);

    const onPrintButtonClick = () => {
      printStaffWorkPlan(startHour.value, endHour.value);
      return Promise.resolve();
    };

    return {
      isDisplayedStaffWorkPlanPrintModal,
      endHourRule,
      onPrintButtonClick,
      closeStaffWorkPlanPrintModal,
      startHour,
      endHour,
    };
  },
});
