import { ref, type Ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import workPlanHeaderApi from 'src/apis/workPlanHeader';
import { convertWorkPlanHeaderByDateResponse, type WorkPlanHeader } from 'src/models/workPlanHeader';
import type { WorkPlanHeaderUpsertResponse } from 'src/models/api/workPlanHeaderResponse';

type InjectionValue = {
  workPlanHeader: Ref<WorkPlanHeader | null>;
  fetchWorkPlanHeader: (params: { workplaceId: number; budgetGroupId: number; dt: string }) => Promise<void>;
  upsertWorkPlanHeader: (params: {
    workplaceId: number;
    budgetGroupId: number;
    dt: string;
  }) => Promise<WorkPlanHeaderUpsertResponse>;
};

const { provide, inject } = createInjection<InjectionValue>('useWorkPlanHeader');

export const useWorkPlanHeaderProvider = () => {
  const workPlanHeader = ref<WorkPlanHeader | null>(null);
  const fetchWorkPlanHeader = async (params: { workplaceId: number; budgetGroupId: number; dt: string }) => {
    const workPlanHeaderResponse = await workPlanHeaderApi.forDate({
      workplace_id: params.workplaceId,
      budget_group_id: params.budgetGroupId,
      dt: params.dt,
    });
    workPlanHeader.value = convertWorkPlanHeaderByDateResponse(workPlanHeaderResponse.data);
  };
  const upsertWorkPlanHeader = async (params: { workplaceId: number; budgetGroupId: number; dt: string }) => {
    return (
      await workPlanHeaderApi.upsert({
        workplace_id: params.workplaceId,
        budget_group_id: params.budgetGroupId,
        dt: params.dt,
      })
    ).data;
  };

  provide({
    workPlanHeader,
    fetchWorkPlanHeader,
    upsertWorkPlanHeader,
  });
};

export const useWorkPlanHeader = () => {
  return inject();
};
