
















































import { computed, defineComponent, ref } from '@vue/composition-api';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { TimetableMasterSelect } from 'src/components/TimetableMasterSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { useDisplayConditions } from '../composables/useDisplayConditions';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { useStaffWorkPlan } from '../composables/useStaffWorkPlan';
import { TimetableMaster } from 'src/models/timetableMaster';

export default defineComponent({
  components: {
    PrimaryButton,
    TimetableLabelSelect,
    TimetableMasterSelect,
  },
  setup() {
    const isOpened = ref<boolean>(false);
    const selectedTimetableLabelTemp = ref<TimetableLabel | ''>('');
    const selectedTimetableMastersTemp = ref<TimetableMaster[]>([]);
    const { timetableLabels } = useTimetableLabels();
    const { timetableMasters } = useTimetableMasters();
    const { timetableLabel, timetableMasters: selectedTimetableMasters } = useDisplayConditions();
    const { state } = useStaffWorkPlan();

    const filteredTimetableLabels = computed(() =>
      timetableLabels.value.filter(({ id }) => {
        return state.headcountTimetable.map(({ timetableLabelId }) => timetableLabelId).includes(id);
      }),
    );

    const displayConditionLabel = computed(() => {
      return selectedTimetableMasters.value.length > 0
        ? selectedTimetableMasters.value
            .slice()
            .sort((pre, cur) => pre.id - cur.id)
            .map(({ name }) => name)
            .join(', ')
        : timetableLabel.value?.name ?? 'なし';
    });

    const filteredTimetableMasters = computed(() => {
      return timetableMasters.value.filter((timetableMaster) => {
        const headcountTimetables =
          selectedTimetableLabelTemp.value === ''
            ? state.headcountTimetable
            : state.headcountTimetable.filter(
                ({ timetableLabelId }) => timetableLabelId === (selectedTimetableLabelTemp.value as TimetableLabel).id,
              );
        return headcountTimetables.map(({ masterId }) => masterId).includes(timetableMaster.id);
      });
    });

    const openPopover = () => {
      selectedTimetableLabelTemp.value = timetableLabel.value !== null ? timetableLabel.value : '';
      selectedTimetableMastersTemp.value =
        selectedTimetableMasters.value.length > 0 ? selectedTimetableMasters.value : [];
      isOpened.value = true;
    };

    const closePopover = () => {
      isOpened.value = false;
    };

    const filterTimetable = () => {
      timetableLabel.value = selectedTimetableLabelTemp.value !== '' ? selectedTimetableLabelTemp.value : null;
      selectedTimetableMasters.value = selectedTimetableMastersTemp.value;
      closePopover();
    };

    const onOutSideClick = (e: MouseEvent & { target: HTMLDivElement }) => {
      if (e.target.classList.contains('TimetableFilterPopover-button')) {
        return;
      }
      closePopover();
    };

    return {
      filteredTimetableLabels,
      selectedTimetableLabelTemp,
      displayConditionLabel,
      filteredTimetableMasters,
      selectedTimetableMastersTemp,
      isOpened,
      openPopover,
      closePopover,
      filterTimetable,
      onOutSideClick,
    };
  },
});
