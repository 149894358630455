import { type AggregationTargetStaffTagIndexResponse } from 'src/models/api/workplaceMasters/aggregationTargetStaffTagResponse';

export type AggregationTargetStaffTag = {
  id: number;
  workplace_id: number;
  staff_tag_value: string;
};

export function convertAggregationTargetStaffTagIndexResponse(
  response: AggregationTargetStaffTagIndexResponse,
): AggregationTargetStaffTag[] {
  return response;
}
