
import Vue from 'vue';
import { computed, defineComponent, ref, SetupContext, watch } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { type TimeInteger } from 'src/models/common';

import ControlPanel from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/ControlPanel/ControlPanel.vue';
import StaffWorkPlanController from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/StaffWorkPlanController.vue';
import StaffFilterPopover from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/StaffFilterPopover.vue';
import TimeBlockFocus from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/TimeBlockFocus.vue';
import TimetableFilterPopover from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/TimetableFilterPopover.vue';
import StaffWorkPlanClearModal from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/modal/StaffWorkPlanClearModal.vue';
import StaffWorkPlanCopyPasteModal from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/modal/StaffWorkPlanCopyPasteModal.vue';
import StaffWorkPlanPrintModal from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/modal/StaffWorkPlanPrintModal.vue';
import WorkPlanUpsertPopover from 'src/views/Dashboard/Workplace/StaffWorkPlan/components/WorkPlanUpsertPopover.vue';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import NumberInput from 'src/components/UIComponents/Inputs/NumberInput/NumberInput.vue';

// カスタムhooks
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useStaffLabelsProvider } from 'src/composables/useStaffLabels';
import { useStaffSkillProvider } from 'src/composables/useStaffSkill';
import { useTimetableMastersProvider } from 'src/composables/useTimetableMasters';
import { useTimetableLabelsProvider } from 'src/composables/useTimetableLabels';
import { useMouseMoveProvider } from 'src/composables/useMouseMove';

import { isExist } from 'src/util/isExist';

import { useDisplayConditionsProvider, useDisplayConditions } from './composables/useDisplayConditions';
import { useProgressHeadersProvider } from './composables/useProgressHeaders';
import { useRegularShiftsProvider } from './composables/useRegularShifts';
import { useSearchConditionsProvider } from './composables/useSearchConditions';
import { useMacroOperationMastersProvider } from './composables/useMacroOperationMasters';
import { useStaffWorkPlanProvider, useStaffWorkPlan } from './composables/useStaffWorkPlan';
import { useStaffWorkPlanClearModalProvider } from './composables/useStaffWorkPlanClearModal';
import { useStaffWorkPlanCopyPasteModalProvider } from './composables/useStaffWorkPlanCopyPasteModal';
import { useWorkPlanUpsertPopoverProvider, useWorkPlanUpsertPopover } from './composables/useWorkPlanUpsertPopover';
import { useWorkPlanTransformerProvider, useWorkPlanTransformer } from './composables/useWorkPlanTransformer';
import { useWorkPlanHeaderProvider } from './composables/useWorkPlanHeader';
import { useUpsertTimetableAllocationPlanProvider } from './composables/useUpsertTimetableAllocationPlan';
import { useUpsertStaffAssignmentPlanProvider } from './composables/useUpsertStaffAssignmentPlan';
import { useCreateProgressHeaders } from './composables/useCreateProgressHeaders';
import {
  useTimetableMastersWithSupport,
  useTimetableMastersWithSupportProvider,
} from './composables/useTimetableMastersWithSupport';
import { useValidator } from './composables/useValidator';

import type { StaffWorkPlan } from './types';
import { getPositionFromTime } from './staffWorkPlanHelper';
import { HOUR_BLOCK_WIDTH, SUPPORT_MASTER_ID } from './consts';
import { useWorkplaceExtension } from 'src/composables/useWorkplaceExtension';
import { useScrollSyncProvider } from 'src/composables/useScrollSync';
import { useStaffWorkPlanPrintModalProvider } from './composables/useStaffWorkPlanPrintModal';

export default defineComponent({
  components: {
    ControlPanel,
    NumberInput,
    NormalButton,
    PrimaryButton,
    StaffWorkPlanController,
    StaffFilterPopover,
    TimeBlockFocus,
    TimetableFilterPopover,
    StaffWorkPlanClearModal,
    StaffWorkPlanCopyPasteModal,
    StaffWorkPlanPrintModal,
    WorkPlanUpsertPopover,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);
    setPageName(root, '個人作業計画');
    const rootElement = ref<HTMLDivElement>();

    useBudgetGroupsProvider({ workplaceId });
    useTimetableLabelsProvider({ workplaceId });
    useTimetableMastersProvider({
      workplaceId,
      options: {
        includes_disabled: true, // 履歴のあるものは無効なものでも表示するという仕様のため、無効なものも取得する
        use_in_staff_work_plan: true,
        includes_resting: true,
      },
    });
    useTimetableMastersWithSupportProvider();
    useSearchConditionsProvider({ workplaceId });
    useStaffLabelsProvider({ workplaceId, includesDisabled: true });
    useStaffSkillProvider();
    useMacroOperationMastersProvider();
    useDisplayConditionsProvider();
    useProgressHeadersProvider();
    useRegularShiftsProvider();
    useStaffWorkPlanProvider();
    useStaffWorkPlanClearModalProvider();
    useStaffWorkPlanCopyPasteModalProvider();
    useStaffWorkPlanPrintModalProvider();
    useMouseMoveProvider();
    useWorkPlanUpsertPopoverProvider();
    useWorkPlanTransformerProvider();
    useWorkPlanHeaderProvider();
    useUpsertTimetableAllocationPlanProvider();
    useUpsertStaffAssignmentPlanProvider();

    const headcountTableRef = ref<HTMLDivElement>();
    const planTableRef = ref<HTMLDivElement>();
    useScrollSyncProvider({ tableA: headcountTableRef, tableB: planTableRef });

    const {
      state,
      filteredStaffWorkPlans,
      checkedStaffWorkPlans,
      checkAllHeadcountTimetable,
      checkHeadcountTimetable,
      checkAllStaffs,
      checkStaff,
    } = useStaffWorkPlan();
    const { timetableLabel, timetableMasters } = useDisplayConditions();
    const { closePopover, onBlankMouseDown, onWorkPlanClick, targetStaff, isBulkUpdateMode } =
      useWorkPlanUpsertPopover();
    const { onWorkPlanLeftHandleMousedown, onWorkPlanRightHandleMousedown } = useWorkPlanTransformer();
    const { timetableMasterMapWithSupport } = useTimetableMastersWithSupport();
    const { timetableStartHour } = useWorkplaceExtension();
    const { createProgressHeaders } = useCreateProgressHeaders();

    const filteredHeadcountTimetable = computed(() => {
      const selectedTimetableLabelId = timetableLabel.value?.id;
      const selectedTimetableMasterIds = timetableMasters.value.map((timetableMaster) => timetableMaster.id);
      if (selectedTimetableLabelId === undefined && selectedTimetableMasterIds.length === 0) {
        return state.headcountTimetable;
      }
      return state.headcountTimetable.filter((timetableRow) => {
        return (
          timetableRow.timetableLabelId === selectedTimetableLabelId ||
          selectedTimetableMasterIds.includes(timetableRow.masterId) ||
          timetableRow.masterId === SUPPORT_MASTER_ID
        );
      });
    });

    const filteredTimetableMasters = computed(() => {
      return filteredHeadcountTimetable.value.map(
        (timetableRow) => timetableMasterMapWithSupport.value[timetableRow.masterId],
      );
    });

    const getBreakTimeStyle = (breakStartTime: TimeInteger | null, breakEndTime: TimeInteger | null) => {
      if (!isExist(breakStartTime) || !isExist(breakEndTime)) {
        return {
          display: 'none',
        };
      }
      const startPosition = getPositionFromTime(breakStartTime);
      const endPosition = getPositionFromTime(breakEndTime);
      return {
        left: `${startPosition}px`,
        width: `${endPosition - startPosition}px`,
      };
    };

    const getWorkPlanStyle = (workPlan: StaffWorkPlan) => {
      const startPosition = getPositionFromTime(workPlan.startTime);
      const endPosition = getPositionFromTime(workPlan.endTime);
      return {
        left: `${startPosition}px`,
        width: `${endPosition - startPosition}px`,
        backgroundColor: `#${workPlan.backgroundColor}`,
      };
    };

    const { validateTimeBlock } = useValidator();

    watch(
      () => [headcountTableRef.value, planTableRef.value],
      ([headcountTableElement, planTableElement]) => {
        if (!isExist(headcountTableElement) || !isExist(planTableElement)) {
          return;
        }
        headcountTableElement.scrollLeft = timetableStartHour.value * HOUR_BLOCK_WIDTH;
        planTableElement.scrollLeft = timetableStartHour.value * HOUR_BLOCK_WIDTH;
      },
    );

    return {
      state,
      filteredHeadcountTimetable,
      filteredTimetableMasters,
      filteredStaffWorkPlans,
      checkedStaffWorkPlans,
      rootElement,
      headcountTableRef,
      planTableRef,
      targetStaff,
      isBulkUpdateMode,
      createProgressHeaders,
      closePopover,
      getPositionFromTime,
      getBreakTimeStyle,
      getWorkPlanStyle,
      validateTimeBlock,
      onHeadcountAllCheckBoxClick: checkAllHeadcountTimetable,
      onHeadcountCheckBoxClick: checkHeadcountTimetable,
      onStaffAllCheckBoxClick: checkAllStaffs,
      onStaffCheckBoxClick: checkStaff,
      onBlankMouseDown,
      onWorkPlanClick,
      onWorkPlanLeftHandleMousedown,
      onWorkPlanRightHandleMousedown,
      SUPPORT_MASTER_ID,
    };
  },
});
