import { reactive } from '@vue/composition-api';
import { useNotification } from 'src/composables/useNotification';
import { createInjection } from 'src/util/createInjection';
import { CommonSettingState } from '../types';
import { useModal } from 'src/composables/useModal';

type InjectionValue = {
  commonSetting: CommonSettingState;
  showsCommonSettingModal: ReturnType<typeof useModal>['showsModal'];
  showCommonSettingModal: ReturnType<typeof useModal>['showModal'];
  hideCommonSettingModal: ReturnType<typeof useModal>['hideModal'];
  setCommonSetting: (state: CommonSettingState) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useCommonSetting');

export function useCommonSettingProvider(): void {
  const {
    showsModal: showsCommonSettingModal,
    showModal: showCommonSettingModal,
    hideModal: hideCommonSettingModal,
  } = useModal();
  const { notifySuccess } = useNotification();
  const commonSetting: CommonSettingState = reactive({
    targetClockOutTime: 180000,
  });
  const setCommonSetting = (nextCommonSetting: CommonSettingState) => {
    commonSetting.targetClockOutTime = nextCommonSetting.targetClockOutTime;
    notifySuccess('勤務終了時刻を更新しました');
  };

  provide({
    commonSetting,
    showsCommonSettingModal,
    showCommonSettingModal,
    hideCommonSettingModal,
    setCommonSetting,
  });
}

export function useCommonSetting(): InjectionValue {
  return inject();
}
