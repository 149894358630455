import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus';

const processNameMap: Record<BackgroundProcessStatus['process_type'], string> = {
  1: 'シフト：マスタから登録',
  2: 'シフト：月合計 更新',
  3: 'シフト：シフトをLMに送信',
  4: 'シフト：出勤実績をLMから受信',
  5: 'シフト：シフトパターンCSVから登録',
  6: 'シフト：シフトパターン雛形を出力',
  7: '旧進捗：外部データ取込',
  8: 'パフォーマンスボード：外部データ取込（予定）',
  9: 'パフォーマンスボード：外部データ取込（実績）',
  12: 'パフォーマンスボード：数量を管理しない工程の工数をLMから取得',
  13: '月間ボード：CSVから数量を入力',
  14: 'シフト：CSVから登録',
};

export function getProcessName(processType: BackgroundProcessStatus['process_type']): string {
  return processNameMap[processType];
}
