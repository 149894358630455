












import { defineComponent, computed } from '@vue/composition-api';
import { ModalForm } from 'src/components/UIComponents/Form';
import { useDeleteAggregationTargetStaffTag } from '../../composables/useDeleteAggregationTargetStaffTag';

export default defineComponent({
  components: {
    ModalForm,
  },
  setup() {
    const {
      aggregationTargetStaffTag,
      showsAggregationTargetStaffTagDeleteConfirmationModal,
      hideAggregationTargetStaffTagDeleteConfirmationModal,
      deleteAggregationTargetStaffTag,
    } = useDeleteAggregationTargetStaffTag();

    return {
      staffTagValue: computed(() => aggregationTargetStaffTag.value?.staff_tag_value),
      showsAggregationTargetStaffTagDeleteConfirmationModal,
      hideAggregationTargetStaffTagDeleteConfirmationModal,
      deleteAggregationTargetStaffTag,
    };
  },
});
