















































import { watch, defineComponent, computed, ref } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { useCreateProgressHeaders } from '../../composables/useCreateProgressHeaders';
import { useProgressSet } from '../../composables/useProgressSet';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { useTimetableMasters } from '../../composables/useTimetableMasters';
import { TimetableMasterSelect } from '../TimetableMasterSelect';
import { useUrlQueryAsSearchParameters } from 'src/composables/useUrlQueryAsSearchParameters';
import { format } from 'date-fns';

type UrlParameters = {
  budget_group_id?: number | null;
  timetable_master_ids?: number[] | null;
  timetable_label_ids?: number[] | null;
  dt?: string | null;
};
const searchParamsTemplate = {
  budget_group_id: 0,
  timetable_master_ids: [],
  timetable_label_ids: [],
  dt: '',
};
const initialSearchParams = {
  budget_group_id: null,
  timetable_master_ids: null,
  timetable_label_ids: null,
  dt: null,
};

export default defineComponent({
  components: {
    BudgetGroupSelect,
    NormalButton,
    PrimaryButton,
    TimetableLabelSelect,
    TimetableMasterSelect,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { timetableLabels } = useTimetableLabels();
    const { timetableMasters } = useTimetableMasters();
    const {
      dt,
      budgetGroup,
      timetableLabels: selectedTimetableLabels,
      timetableMasters: selectedTimetableMasters,
    } = useSearchConditions();
    const { canSearch, fetchProgresses } = useProgressSet();
    const { createProgressHeaders } = useCreateProgressHeaders();
    const filteredTimetableMasters = computed(() =>
      timetableMasters.value.filter((v) => v.budget_group_id === (budgetGroup.value?.id ?? v.budget_group_id)),
    );
    const { getUrlStoredParameters, setUrlStoredParameters } = useUrlQueryAsSearchParameters<UrlParameters>({
      searchParametersTemplate: searchParamsTemplate,
      defaultSearchParameters: initialSearchParams,
      acceptableValueMap: {},
    });
    const isMastersLoaded = ref<Boolean>(false);
    const urlParameters = ref(getUrlStoredParameters());
    watch(
      () => [timetableMasters.value, budgetGroups.value, timetableLabels.value],
      () => {
        if (
          timetableMasters.value.length === 0 ||
          budgetGroups.value.length === 0 ||
          timetableLabels.value.length === 0
        ) {
          return;
        }

        isMastersLoaded.value = true;
      },
    );

    watch(
      () => isMastersLoaded.value,
      () => {
        syncSearchParamsFromUrl();
      },
    );
    const syncSearchParamsFromUrl = async () => {
      urlParameters.value = getUrlStoredParameters();
      const filteredTimetableMasters = timetableMasters.value.filter((v) =>
        urlParameters.value.timetable_master_ids?.includes(v.id),
      );
      //  対象のTimetableMasterが見つからない場合は他の条件もセットしない
      if (filteredTimetableMasters.length === 0) {
        return;
      }

      selectedTimetableMasters.value = [...selectedTimetableMasters.value, ...filteredTimetableMasters];

      const selectedBudgetGroup = budgetGroups.value.find((t) => t.id === urlParameters.value.budget_group_id);
      if (selectedBudgetGroup !== undefined) {
        budgetGroup.value = selectedBudgetGroup;
      }
      const filteredTimetableLabels = timetableLabels.value.filter((v) =>
        urlParameters.value.timetable_label_ids?.includes(v.id),
      );
      selectedTimetableLabels.value = [...selectedTimetableLabels.value, ...filteredTimetableLabels];

      if (selectedBudgetGroup !== undefined) {
        budgetGroup.value = selectedBudgetGroup;
      }
      if (urlParameters.value.dt != null) {
        dt.value = new Date(urlParameters.value.dt);
      }
      await fetchProgresses();
    };

    const changeURLQuery = async () => {
      const query = {
        budget_group_id: budgetGroup.value ? Number(budgetGroup.value.id) : null,
        dt: dt.value ? String(format(dt.value, 'yyyy-MM-dd')) : null,
        timetable_label_ids:
          selectedTimetableLabels.value.length > 0 ? selectedTimetableLabels.value.map((t) => t.id) : null,
        timetable_master_ids:
          selectedTimetableMasters.value.length > 0 ? selectedTimetableMasters.value.map((t) => t.id) : null,
      };
      await setUrlStoredParameters(query);
    };

    const onDisplayButtonClick = () => {
      changeURLQuery();
      fetchProgresses();
    };

    return {
      budgetGroups,
      timetableLabels,
      filteredTimetableMasters,
      dt,
      budgetGroup,
      selectedTimetableLabels,
      selectedTimetableMasters,
      canSearch,
      fetchProgresses,
      createProgressHeaders,
      onDisplayButtonClick,
    };
  },
});
