























import { defineComponent } from '@vue/composition-api';
import { PanelList, PanelListItem } from '../PanelList';
import { ActivityStatusPanel } from '../ActivityStatusPanel';
import { AttendanceStatusPanel } from '../AttendanceStatusPanel';
import { TimetablePanel } from '../TimetablePanel';
import { useProgressBoard } from '../../composables/useProgressBoard';
import { useAttendance } from 'src/composables/useAttendance';

export default defineComponent({
  components: {
    PanelList,
    PanelListItem,
    ActivityStatusPanel,
    AttendanceStatusPanel,
    TimetablePanel,
  },
  setup() {
    const { progressBoard, filterTimetableHeaders } = useProgressBoard();
    const { attendanceCountMap } = useAttendance();

    return {
      progressBoard,
      filterTimetableHeaders,
      attendanceCountMap,
    };
  },
});
