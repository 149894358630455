





















































































import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { orDefault } from 'src/util/filters';
import { formatNumber, formatFixedDecimal } from '../../utils/filters';
import { MANAGEMENT_TYPE } from 'src/consts';
import { useProgressSummary } from '../../composables/useProgressSummary';
import { getQuantityColor, getProductivityColor } from 'src/util/progressHeaderHelpers';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
    formatFixedDecimal,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const { resultQuantity, plannedQuantity, progressPlanDataListWithCumulativeValues, plannedProductivity } =
      useProgressSummary();
    const progressColor = computed(() => getQuantityColor(resultQuantity.value, plannedQuantity.value));
    const latestProductivityColor = computed(() =>
      getProductivityColor(props.progressHeader.latest_productivity, plannedProductivity.value),
    );

    const displayedManHours = computed(() => ({
      result:
        props.progressHeader.result_man_hours !== null
          ? formatFixedDecimal(props.progressHeader.result_man_hours)
          : '-',
      planned:
        progressPlanDataListWithCumulativeValues.value.length > 0
          ? formatFixedDecimal(
              progressPlanDataListWithCumulativeValues.value[progressPlanDataListWithCumulativeValues.value.length - 1]
                .cumulativeManHours,
            )
          : '-',
    }));
    const isManHourManaged = computed(
      () =>
        props.progressHeader.timetable_master.management_type === MANAGEMENT_TYPE.ONLY_MAN_HOUR ||
        props.progressHeader.timetable_master.management_type === MANAGEMENT_TYPE.QUANTITY_AND_MAN_HOUR,
    );
    const isQuantityManaged = computed(
      () =>
        props.progressHeader.timetable_master.management_type === MANAGEMENT_TYPE.ONLY_QUANTITY ||
        props.progressHeader.timetable_master.management_type === MANAGEMENT_TYPE.QUANTITY_AND_MAN_HOUR,
    );

    return {
      progressColor,
      latestProductivityColor,
      MANAGEMENT_TYPE,
      displayedManHours,
      isManHourManaged,
      isQuantityManaged,
    };
  },
});
