











import { defineComponent, computed, type PropType } from '@vue/composition-api';
import ProgressSection from '../components/ProgressSection.vue';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';
import { useProgressSection } from '../composables/useProgressSection';
import type { TimetableRow } from '../types';
export default defineComponent({
  components: {
    ProgressSection,
  },
  props: {
    timetableRow: {
      type: Object as PropType<TimetableRow>,
      required: true,
    },
  },
  setup(props) {
    const { timetableMasterMap } = useTimetableMasters();
    const timetableMaster = computed(() => timetableMasterMap.value[props.timetableRow.header.masterId]);
    const { convertTimetableRowToProgressSections, getProgressSectionsStyle } = useProgressSection();
    const progressSections = computed(() => convertTimetableRowToProgressSections(props.timetableRow));
    return {
      progressSections,
      getProgressSectionsStyle,
      timetableMaster,
    };
  },
});
