import Vue from 'vue';

export const STORED_PARAMETERS_URL = 'url';
export const STORED_PARAMETERS_PAGE_TEMPORARY = 'pageTemporary';
export type StoredParametersStoreMode = typeof STORED_PARAMETERS_URL | typeof STORED_PARAMETERS_PAGE_TEMPORARY;

const DEFAULT_KEY = 'default';

export type QueryParameters = Record<string, string | number>;
/**
 * Parameter's Key must be Lower CamelCased string.
 * Ex. { timeSpan: 'weekly', sectionNumber: 5 }
 */
export type Parameters = QueryParameters | Record<string, string | number | object>;

const isParametersQueryParameters = (params: Parameters): params is QueryParameters => {
  return Object.values(params).every((el) => typeof el === 'string' || typeof el === 'number');
};

export const setUrlStoredParameters = async (vue: Vue, params: QueryParameters): Promise<void> => {
  if (!isParametersQueryParameters(params)) {
    throw new Error('All query parameters must be string or number.');
  }

  // 全く同じ条件で2回呼ばれるとvue-routerがエラーを吐くので、防ぐ
  const currentQuery = getUrlStoredParameters(vue);
  const areAllQueriesSame =
    Object.keys(currentQuery).length === Object.keys(params).length &&
    Object.entries(currentQuery).every(([k, v]) => String(v) === String(params[k]));
  if (areAllQueriesSame) {
    return;
  }

  const path = vue.$route.path;
  const query = params as Record<string, string>;
  await vue.$router.replace({ path, query });
};

export const setPageTemporaryStoredParameters = (
  vue: Vue,
  params: Parameters,
  key: string | null = DEFAULT_KEY,
): void => {
  if (!key) {
    throw new Error('Key must be specified.');
  }
  vue.$store.commit('storedParameters/setByKey', { key, value: params });
};

const _getUrlStoredParameters = (vue: Vue): QueryParameters => {
  // QueryParameterはキーをstring、値をstring|numberとしているが、クエリ文字列一般では
  // 値がnullの場合や配列の場合を許容し、このモジュールではそれらのケースがあった時パラメータから除外する
  const query = vue.$route.query;
  return Object.keys(query).reduce((params, key) => {
    const value = query[key];
    if (typeof value === 'string') {
      params[key] = value.match(/^([1-9]\d*|0)(\.\d+)?$/) ? Number(value) : value;
    }
    return params;
  }, {} as QueryParameters);
};

export const getUrlStoredParameters = <T extends QueryParameters>(vue: Vue): Partial<T> => {
  return _getUrlStoredParameters(vue) as Partial<T>;
};

const _getPageTemporaryStoredParameters = (vue: Vue, key: string): Parameters => {
  return vue.$store.getters['storedParameters/byKey'](key);
};

export const getPageTemporaryStoredParameters = <T extends Parameters>(vue: Vue, key: string): T => {
  if (!key) {
    throw new Error('Key must be specified.');
  }
  return _getPageTemporaryStoredParameters(vue, key) as T;
};

export const initializePageTemporaryStoredParameters = (vue: Vue): void => {
  vue.$store.commit('storedParameters/clear');
};

export const getStoredParameters = <T extends Parameters>(
  vue: Vue,
  mode: StoredParametersStoreMode,
  key: string | null = DEFAULT_KEY,
): T => {
  switch (mode) {
    case STORED_PARAMETERS_URL:
      return getUrlStoredParameters(vue) as T;
    case STORED_PARAMETERS_PAGE_TEMPORARY:
      if (!key) {
        throw new Error('Key must be specified.');
      }
      return getPageTemporaryStoredParameters(vue, key) as T;
  }
};

export const setStoredParameters = (
  vue: Vue,
  params: Parameters,
  mode: StoredParametersStoreMode,
  key: string | null = DEFAULT_KEY,
) => {
  switch (mode) {
    case STORED_PARAMETERS_URL:
      if (!isParametersQueryParameters(params)) {
        throw new Error('All query parameters must be string or number.');
      }
      setUrlStoredParameters(vue, params);
      break;
    case STORED_PARAMETERS_PAGE_TEMPORARY:
      if (!key) {
        throw new Error('Key must be specified.');
      }
      setPageTemporaryStoredParameters(vue, params, key);
      break;
  }
};
