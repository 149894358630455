

























































import { computed, defineComponent } from '@vue/composition-api';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useStaffWorkPlanClearModal } from 'src/views/Dashboard/Workplace/StaffWorkPlan/composables/useStaffWorkPlanClearModal';
import { useStaffWorkPlanCopyPasteModal } from '../composables/useStaffWorkPlanCopyPasteModal';
import { useStaffWorkPlan } from '../composables/useStaffWorkPlan';
import { useStaffWorkPlanPrintModal } from '../composables/useStaffWorkPlanPrintModal';

export default defineComponent({
  components: {
    PrimaryButton,
  },
  setup() {
    const { openStaffWorkPlanClearModal } = useStaffWorkPlanClearModal();
    const { openStaffWorkPlanCopyPasteModal } = useStaffWorkPlanCopyPasteModal();
    const { checkedStaffWorkPlans } = useStaffWorkPlan();

    const isButtonDisabled = computed(() => checkedStaffWorkPlans.value.length === 0);
    const { openStaffWorkPlanPrintModal } = useStaffWorkPlanPrintModal();

    return {
      isButtonDisabled,
      openStaffWorkPlanClearModal,
      openStaffWorkPlanCopyPasteModal,
      openStaffWorkPlanPrintModal,
    };
  },
});
