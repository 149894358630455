


































import { computed, defineComponent, reactive } from '@vue/composition-api';
import { ModalForm, FormGroup } from 'src/components/UIComponents/Form';
import { useTimetableMasters } from '../../composables/useTimetableMasters';
import { useDownloadCsv } from 'src/composables/useDownloadCsv';
import { buildCsvData } from './buildCsvData';
import format from 'date-fns/format';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { useActivityMappingCsvDownloadModal } from '../../composables/useActivityMappingCsvDownloadModal';

type FeatureKey =
  | 'use_in_daily_simulation_and_staff_work_plan'
  | 'use_in_performance_board_detail'
  | 'use_in_performance_board_list'
  | 'use_in_staff_overview';

export default defineComponent({
  components: {
    FormGroup,
    ModalForm,
  },
  setup() {
    const { hideActivityMappingCsvDownloadModal, showsActivityMappingCsvDownloadModal } =
      useActivityMappingCsvDownloadModal();

    const featuresMap: { value: FeatureKey; label: string }[] = [
      { value: 'use_in_daily_simulation_and_staff_work_plan', label: '当日ボード・個人作業計画' },
      { value: 'use_in_performance_board_detail', label: 'パフォーマンスボード グラフ' },
      { value: 'use_in_performance_board_list', label: 'パフォーマンスボード カード' },
      { value: 'use_in_staff_overview', label: '工程経験' },
    ];
    const state = reactive({
      targetFeature: featuresMap[0],
    });
    const { timetableMasters } = useTimetableMasters();
    const { budgetGroupTimetableActivityMastersIncludingDisabled } = useTimetableActivityMasters();

    const filteredTimetableMasters = computed(() => {
      const filterKey =
        state.targetFeature.value === 'use_in_daily_simulation_and_staff_work_plan'
          ? 'use_in_staff_work_plan'
          : state.targetFeature.value;
      return timetableMasters.value.filter((timetableMaster) => timetableMaster[filterKey] === true);
    });
    const { downloadCsv } = useDownloadCsv({
      prepare: () => ({
        fileName: `activity_mapping_${format(new Date(), 'yyyyMMddHHmmss')}.csv`,
        data: buildCsvData(filteredTimetableMasters.value, budgetGroupTimetableActivityMastersIncludingDisabled.value),
      }),
    });
    return {
      state,
      featuresMap,
      downloadCsv,
      showsActivityMappingCsvDownloadModal,
      hideActivityMappingCsvDownloadModal,
    };
  },
});
