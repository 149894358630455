import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import type { StaffAssignmentPlanBulkUpsertParams } from 'src/models/api/staffAssignmentPlanRequest';
import type { StaffAssignmentPlanBulkUpsertResponse } from 'src/models/api/staffAssignmentPlanResponse';

export default {
  bulkUpsert({
    workplace_id,
    budget_group_id,
    work_plan_header_id,
    ...data
  }: StaffAssignmentPlanBulkUpsertParams): Promise<AxiosResponse<StaffAssignmentPlanBulkUpsertResponse>> {
    return axios.post(
      utils.getApiUrl(
        `/workplaces/${workplace_id}/budget_groups/${budget_group_id}/work_plan_headers/${work_plan_header_id}/staff_assignment_plans/bulk_upsert`,
      ),
      data,
    );
  },
};
