


import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import notificationMixin from 'src/mixins/notificationMixin';
import staffAgencyApi from 'src/apis/workplace_masters/staff_agency';
import timingControlMixin from 'src/mixins/timingControlMixin';
import windowMixin from 'src/mixins/windowMixin';
import ListSwitchPanel from '../ListSwitchPanel';
import { setPageName } from 'src/hooks/displayPageNameHook';
import * as C from 'src/consts';
import { secondsToTimeInteger, unpackTimeInteger, packToTimeInteger, timeIntegerToSeconds } from 'src/util/datetime';
import { userHasWorkplaceRoleGteOwnerSync } from 'src/hooks/appRoleHook';

const api = staffAgencyApi;
const displayPageName = 'スタッフ所属会社';
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' };

export default {
  components: { Draggable, ListSwitchPanel },
  data() {
    return {
      displayPageName: displayPageName,
      searchParams: {
        displayListDisabledItem: false,
      },
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,

      validations: this.getValidationMap(),

      dragOptions: {
        handle: '.grabbable',
        animation: 300,
        chosenClass: 'bg-color-default',
      },

      workStartTimeInputTypeNotRounded: C.WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED,
      workStartTimeInputTypeRoundedUp: C.WORK_START_TIME_INPUT_TYPE_ROUNDED_UP,
      workStartTimeInputTypeRoundedDown: C.WORK_START_TIME_INPUT_TYPE_ROUNDED_DOWN,
      workStartTimeInputStepCandidates: [],
      workEndTimeInputTypeNotRounded: C.WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED,
      workEndTimeInputTypeRoundedUp: C.WORK_END_TIME_INPUT_TYPE_ROUNDED_UP,
      workEndTimeInputTypeRoundedDown: C.WORK_END_TIME_INPUT_TYPE_ROUNDED_DOWN,
      workEndTimeInputStepCandidates: [],
      activityTimeInputTypeNotRounded: C.ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED,
      activityTimeInputTypeRoundedUp: C.ACTIVITY_TIME_INPUT_TYPE_ROUNDED_UP,
      activityTimeInputTypeRoundedDown: C.ACTIVITY_TIME_INPUT_TYPE_ROUNDED_DOWN,
      activityTimeInputStepCandidates: [],
      holidaysList: [0, 6],
      standardWorkingHoursInHours: 8,
      overtimeExtraRatePercent: 25,
      holidayExtraRatePercent: 35,
      midnightExtraRatePercent: 30,
      isGteOwner: false,
      fixTable: true,
    };
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    workplaceId() {
      return this.$route.params.workplaceId;
    },
    ...mapGetters('workplace', {
      useCreateAndUpdateInfo: 'useCreateAndUpdateInfo',
      useStaffAgency: 'useStaffAgency',
    }),
    hasList() {
      return this.list.length > 0;
    },
    hasError() {
      return this.errors.any();
    },
    rowWidth() {
      return 436 + this.isGteOwner * 1130 + this.useCreateAndUpdateInfo * 660;
    },
  },
  mixins: [notificationMixin, timingControlMixin, windowMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getValidationMap() {
      const ruleStr = { required: true, max: 255 };
      const ruleHour = { numeric: true, max: 2, max_value: 40 };
      const ruleMin = { numeric: true, max: 2, max_value: 59 };
      const rulePercentage = {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: 100,
      };
      return {
        name: ruleStr,
        start_time_hour: ruleHour,
        start_time_min: ruleMin,
        end_time_hour: ruleHour,
        end_time_min: ruleMin,
        standard_working_hours_in_hours: { required: true, decimal: 1, max_value: 24 },
        overtime_extra_rate_percent: rulePercentage,
        midnight_extra_rate_percent: rulePercentage,
        holiday_extra_rate_percent: rulePercentage,
        midnight_time_start_hour: { numeric: true, max: 2, max_value: 47, required: true },
        midnight_time_start_min: { numeric: true, max: 2, max_value: 59, required: true },
        midnight_time_end_hour: { numeric: true, max: 2, max_value: 47, required: true },
        midnight_time_end_min: { numeric: true, max: 2, max_value: 59, required: true },
      };
    },
    getApiParams() {
      const apiParams = {};
      if (!this.searchParams.displayListDisabledItem) {
        apiParams.is_enabled = true;
      }
      return apiParams;
    },
    clearErrors() {
      this.$validator.reset();
    },
    async getList() {
      const { data } = await api.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      });

      data.forEach((e) => {
        // alias
        e.standardWorkingHoursInHours = timeIntegerToSeconds(e.standard_working_hours) / 3600;
        e.overtimeExtraRatePercent = e.overtime_extra_rate * 100;
        e.midnightExtraRatePercent = e.midnight_extra_rate * 100;
        e.holidayExtraRatePercent = e.holiday_extra_rate * 100;
        e.holidaysList = e.holidays !== null ? e.holidays.slice() : [];
      });

      this.list = data;
    },
    async getListWithUpdatedSearchParams(params) {
      this.searchParams.displayListDisabledItem = params.displayDisabledModel;
      await this.getList();
    },
    openSaveModal(item) {
      let saveCandidate = {};
      if (!item.id) {
        saveCandidate.isNew = true;
        saveCandidate.is_enabled = true;
        saveCandidate.work_start_time_input_type = C.WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED;
        saveCandidate.work_end_time_input_type = C.WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED;
        saveCandidate.activity_time_input_type = C.ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED;
        saveCandidate.overtime_extra_rate = (25 / 100).toFixed(2);
        saveCandidate.standard_working_hours = secondsToTimeInteger(8 * 3600);
        saveCandidate.holiday_extra_rate = (35 / 100).toFixed(2);
        saveCandidate.midnight_extra_rate = (30 / 100).toFixed(2);
        saveCandidate.holidays = [0, 6].slice().sort();
        saveCandidate.midnight_time_start = '220000';
        const [startHour, startMin] = unpackTimeInteger(saveCandidate.midnight_time_start);
        saveCandidate.midnight_time_start_hour = startHour;
        saveCandidate.midnight_time_start_min = ('0' + startMin).slice(-2);
        saveCandidate.midnight_time_end = '290000';
        const [endHour, endMin] = unpackTimeInteger(saveCandidate.midnight_time_end);
        saveCandidate.midnight_time_end_hour = endHour;
        saveCandidate.midnight_time_end_min = ('0' + endMin).slice(-2);
      } else {
        saveCandidate = JSON.parse(JSON.stringify(item));
        if (saveCandidate.midnight_time_start !== null) {
          const [startHour, startMin] = unpackTimeInteger(saveCandidate.midnight_time_start);
          saveCandidate.midnight_time_start_hour = startHour;
          saveCandidate.midnight_time_start_min = ('0' + startMin).slice(-2);
        }
        if (saveCandidate.midnight_time_end !== null) {
          const [endHour, endMin] = unpackTimeInteger(saveCandidate.midnight_time_end);
          saveCandidate.midnight_time_end_hour = endHour;
          saveCandidate.midnight_time_end_min = ('0' + endMin).slice(-2);
        }
      }
      this.saveCandidate = saveCandidate;
      this.overtimeExtraRatePercent = Number((saveCandidate.overtime_extra_rate * 100).toFixed());
      this.midnightExtraRatePercent = Number((saveCandidate.midnight_extra_rate * 100).toFixed());
      this.holidayExtraRatePercent = Number((saveCandidate.holiday_extra_rate * 100).toFixed());
      this.standardWorkingHoursInHours = Number(
        (timeIntegerToSeconds(saveCandidate.standard_working_hours) / 3600).toFixed(1),
      );
      this.holidaysList = saveCandidate.holidays !== null ? saveCandidate.holidays.slice() : [];
      this.showSaveModal = true;
    },
    closeSaveModal() {
      this.saveCandidate = {};
      this.clearErrors();
      this.showSaveModal = false;
    },
    async saveItem() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }

      const opType = this.saveCandidate.isNew ? 'create' : 'update';
      this.saveCandidate.work_start_time_input_step = this.saveCandidate.work_start_time_input_step
        ? this.saveCandidate.work_start_time_input_step
        : 0;
      this.saveCandidate.work_end_time_input_step = this.saveCandidate.work_end_time_input_step
        ? this.saveCandidate.work_end_time_input_step
        : 0;
      this.saveCandidate.activity_time_input_step = this.saveCandidate.activity_time_input_step
        ? this.saveCandidate.activity_time_input_step
        : 0;
      this.saveCandidate.overtime_extra_rate = (this.overtimeExtraRatePercent / 100).toFixed(2);
      this.saveCandidate.standard_working_hours = secondsToTimeInteger(this.standardWorkingHoursInHours * 3600);
      this.saveCandidate.holiday_extra_rate = (this.holidayExtraRatePercent / 100).toFixed(2);
      this.saveCandidate.midnight_extra_rate = (this.midnightExtraRatePercent / 100).toFixed(2);
      this.saveCandidate.holidays = this.holidaysList.slice().sort();
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        };
        await api[opType](reqObj);
        this.getList();
        this.closeSaveModal();
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`);
        this.reloadOpener();
      } catch (err) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = 'その会社名は既に使用されています。';
          this.notifyError1(msg, { timeout: 5 * 1000 });
        } else {
          const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002';
          const msg =
            `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
          this.notifyError1(msg, { err });
        }
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.deleteCandidate = {};
      this.showDeleteModal = false;
    },
    async deleteItem() {
      const opType = 'delete';
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteCandidate.id,
        };
        await api[opType](reqObj);
        this.getList();
        this.closeDeleteModal();
        this.notifySuccess1(`${displayPageName}を${msgVars[opType]}しました`);
        this.reloadOpener();
      } catch (err) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' + '無効化をおすすめします。';
          this.notifyError1(msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
          this.notifyError1(msg, { err });
        }
      }
    },
    isDraggable() {
      return true;
    },
    async updateDispOrder() {
      const opType = 'update_disp_order';
      try {
        this.list.forEach((e, i) => {
          e.disp_order = i + 1;
        });
        const reqObj = {
          workplaceId: this.workplaceId,
          items: this.list,
        };
        await api.bulkUpdateDispOrder(reqObj);
        this.getList();
      } catch (err) {
        const errId = 'ERR00004';
        const msg =
          `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
        this.notifyError1(msg, { err });
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem);
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem);
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder);
    },
    onChangeWorkStartTimeInputTypeNotRounded() {
      this.saveCandidate.work_start_time_input_step = 0;
    },

    onChangeWorkEndTimeInputTypeNotRounded() {
      this.saveCandidate.work_end_time_input_step = 0;
    },
    onChangeActivityTimeInputTypeNotRounded() {
      this.saveCandidate.activity_time_input_step = 0;
    },
    onTimeChange(prop) {
      const staff_agency = this.saveCandidate;
      if (prop === 'midnight_time_start') {
        if (!staff_agency.midnight_time_start_hour || !staff_agency.midnight_time_start_min) {
          staff_agency.midnight_time_start = null;
        }
        if (
          isNaN(parseInt(staff_agency.midnight_time_start_hour)) ||
          isNaN(parseInt(staff_agency.midnight_time_start_min))
        ) {
          staff_agency.midnight_time_start = null;
        }
        const time = packToTimeInteger(staff_agency.midnight_time_start_hour, staff_agency.midnight_time_start_min, 0);
        staff_agency.midnight_time_start = time;
        this.$validator.validate('endTime');
      } else if (prop === 'midnight_time_end') {
        if (!staff_agency.midnight_time_end_hour || !staff_agency.midnight_time_end_min) {
          staff_agency.midnight_time_end = null;
        }
        if (
          isNaN(parseInt(staff_agency.midnight_time_end_hour)) ||
          isNaN(parseInt(staff_agency.midnight_time_end_min))
        ) {
          staff_agency.midnight_time_end = null;
        }
        const time = packToTimeInteger(staff_agency.midnight_time_end_hour, staff_agency.midnight_time_end_min, 0);
        staff_agency.midnight_time_end = time;
        this.$validator.validate('endTime');
      }
    },
  },
  created() {
    this.makeFuncGated();
  },
  async mounted() {
    setPageName(this, displayPageName);
    await this.getList();
    this.workStartTimeInputStepCandidates = window.master.lovs.work_start_time_input_step.vals.map((e) => {
      return { name: e.name, value: e.key };
    });
    this.workEndTimeInputStepCandidates = window.master.lovs.work_end_time_input_step.vals.map((e) => {
      return { name: e.name, value: e.key };
    });
    this.activityTimeInputStepCandidates = window.master.lovs.activity_time_input_step.vals.map((e) => {
      return { name: e.name, value: e.key };
    });
    this.$validator.attach({
      name: 'endTime',
      rules: 'custom_after1',
      getter: () => {
        return {
          start_time: this.saveCandidate.midnight_time_start,
          end_time: this.saveCandidate.midnight_time_end,
          max_duration_seconds: 86400,
        };
      },
    });
    this.isGteOwner = userHasWorkplaceRoleGteOwnerSync(this, parseInt(this.workplaceId));
  },
};
