import { computed, type ComputedRef } from '@vue/composition-api';
import aggregationTargetStaffTagApi from 'src/apis/workplace_masters/aggregationTargetStaffTag';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { useModal } from 'src/composables/useModal';
import { type StaffTag } from 'src/models/workplaceMasters/staffTag';
import { createInjection } from 'src/util/createInjection';
import { useAggregationTargetStaffTags } from './useAggregationTargetStaffTags';
import { useStaffTags } from './useStaffTags';

type InjectionValue = {
  showsAggregationTargetStaffTagCreateModal: ReturnType<typeof useModal>['showsModal'];
  showAggregationTargetStaffTagCreateModal: ReturnType<typeof useModal>['showModal'];
  hideAggregationTargetStaffTagCreateModal: ReturnType<typeof useModal>['hideModal'];
  addableStaffTags: ComputedRef<StaffTag[]>;
  createAggregationTargetStaffTag: (staffTag: StaffTag) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useCreateAggregationTargetStaffTag');

async function requestToCreateAggregationTargetStaffTag({ staffTag }: { staffTag: StaffTag }): Promise<void> {
  await aggregationTargetStaffTagApi.create({
    workplace_id: staffTag.workplace_id,
    staff_tag_id: staffTag.id,
  });
}

export function useCreateAggregationTargetStaffTagProvider(): void {
  const { notifySuccess, notifyError } = useNotification();
  const errorBoundary = useErrorBoundary();
  const {
    showsModal: showsAggregationTargetStaffTagCreateModal,
    showModal,
    hideModal: hideAggregationTargetStaffTagCreateModal,
  } = useModal();
  const { aggregationTargetStaffTags, fetchAggregationTargetStaffTags } = useAggregationTargetStaffTags();
  const { staffTags } = useStaffTags();
  const addableStaffTags = computed(() => {
    const addedStaffTagValues = aggregationTargetStaffTags.value.map((v) => v.staff_tag_value);
    return staffTags.value.filter((v) => !addedStaffTagValues.includes(v.value));
  });
  const showAggregationTargetStaffTagCreateModal = () => {
    if (addableStaffTags.value.length === 0) {
      notifyError('追加できるスタッフタグがありません。先に、スタッフタグを作成してください');
      return;
    }
    showModal();
  };
  const createAggregationTargetStaffTag = errorBoundary.wrap(
    async (staffTag: StaffTag) => {
      await requestToCreateAggregationTargetStaffTag({ staffTag: staffTag });
      hideAggregationTargetStaffTagCreateModal();
      notifySuccess('シフト集計に使うスタッフタグを追加しました');
      await fetchAggregationTargetStaffTags();
    },
    {
      fallbackMessage: 'シフト集計に使うスタッフタグの追加に失敗しました。',
      shouldContactUs: true,
    },
  );

  provide({
    showsAggregationTargetStaffTagCreateModal,
    showAggregationTargetStaffTagCreateModal,
    hideAggregationTargetStaffTagCreateModal,
    addableStaffTags,
    createAggregationTargetStaffTag,
  });
}

export function useCreateAggregationTargetStaffTag(): InjectionValue {
  return inject();
}
