import type { DeepReadonly } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { TIME_INTEGER_MAX_VALUE } from 'src/consts';

import timetableAllocationPlanApi from 'src/apis/timetableAllocationPlan';
import type { StaffWorkPlanHeadcountTimetableRow } from '../types';
import type { TimetableAllocationPlanBulkUpsertResponse } from 'src/models/api/timetableAllocationPlanResponse';
import type { TimetableAllocationPlanBulkUpsertParams } from 'src/models/api/timetableAllocationPlanRequest';

type BuildParamsInputs = {
  workplaceId: number;
  budgetGroupId: number;
  workPlanHeaderId: number;
  headcountTimetable: DeepReadonly<StaffWorkPlanHeadcountTimetableRow[]>;
};

type InjectionValue = {
  bulkUpsert: (params: TimetableAllocationPlanBulkUpsertParams) => Promise<TimetableAllocationPlanBulkUpsertResponse>;
  buildParams: (inputs: BuildParamsInputs) => TimetableAllocationPlanBulkUpsertParams;
};

const { provide, inject } = createInjection<InjectionValue>('useUpsertTimetableAllocationPlan');

export const useUpsertTimetableAllocationPlanProvider = () => {
  const bulkUpsert = async (params: TimetableAllocationPlanBulkUpsertParams) => {
    return (await timetableAllocationPlanApi.bulkUpsert(params)).data;
  };

  const buildParams = ({
    workplaceId,
    budgetGroupId,
    workPlanHeaderId,
    headcountTimetable,
  }: BuildParamsInputs): TimetableAllocationPlanBulkUpsertParams => {
    const timetableAllocationPlans = headcountTimetable.map<
      TimetableAllocationPlanBulkUpsertParams['timetable_allocation_plans'][number]
    >((headcountTimetableRow) => {
      let shouldContinue = false;
      const timeBlockEntries = Object.entries(headcountTimetableRow.timeBlocks);
      const dataList = timeBlockEntries.reduce<
        TimetableAllocationPlanBulkUpsertParams['timetable_allocation_plans'][number]['data']
      >((acc, [timeKey, value], index) => {
        const time = Number(timeKey);
        const nextTimeKey = timeBlockEntries[index + 1]?.[0];
        // 個人作業計画では実装の都合のためTimeIntegerの最大値を480000として扱っているが
        // アプリケーション全体として最大値は475959であるため保存できる形式に変更する
        const nextTime = nextTimeKey ? Number(nextTimeKey) : TIME_INTEGER_MAX_VALUE;
        const requiredHeadcount = value.requiredHeadcount;
        if (requiredHeadcount === null) {
          shouldContinue = false;
          return acc;
        } else if (
          acc.length === 0 ||
          acc[acc.length - 1].required_headcount !== requiredHeadcount ||
          !shouldContinue
        ) {
          shouldContinue = true;
          acc.push({
            start_time: time,
            end_time: nextTime,
            required_headcount: requiredHeadcount,
          });
        } else {
          acc[acc.length - 1].end_time = nextTime;
        }
        return acc;
      }, []);
      return {
        timetable_master_id: headcountTimetableRow.masterId,
        data: dataList,
      };
    }, {});
    return {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      work_plan_header_id: workPlanHeaderId,
      timetable_allocation_plans: timetableAllocationPlans,
    };
  };

  provide({
    bulkUpsert,
    buildParams,
  });
};

export const useUpsertTimetableAllocationPlan = () => {
  return inject();
};
