






















import { defineComponent, type PropType, computed } from '@vue/composition-api';
import { AttendanceStatus, ATTENDANCE_STATUSES } from 'src/consts/index';
import { CardPanel } from '../CardPanel';
import { formatInteger } from '../../utils/filters';

export default defineComponent({
  components: {
    CardPanel,
  },
  filters: {
    formatInteger,
  },
  props: {
    attendanceCountMap: {
      type: Object as PropType<Record<AttendanceStatus, number>>,
      required: true,
    },
  },
  setup(props) {
    const workingCount = computed(() => props.attendanceCountMap[ATTENDANCE_STATUSES.ATTENDING]);
    const notYetAtWorkCount = computed(() => props.attendanceCountMap[ATTENDANCE_STATUSES.NOT_ATTENDING]);
    const leftWorkCount = computed(() => props.attendanceCountMap[ATTENDANCE_STATUSES.FINISHED]);
    const unscheduledAbsentCount = computed(() => props.attendanceCountMap[ATTENDANCE_STATUSES.ABSENCE]);

    return {
      workingCount,
      notYetAtWorkCount,
      leftWorkCount,
      unscheduledAbsentCount,
    };
  },
});
