import { ref, computed, type Ref } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import { type StaffShift } from 'src/models/staffShift';
import { type StaffAttendance } from 'src/models/staffAttendance';
import staffAttendanceApi from 'src/apis/staffAttendance';
import { useSearchConditions } from 'src/composables/useSearchConditions';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { ATTENDANCE_TYPE, ATTENDANCE_TYPES_AT_WORK, ATTENDANCE_STATUSES, type AttendanceStatus } from 'src/consts';
import { createInjection } from 'src/util/createInjection';

interface AttendanceData {
  staffShifts: StaffShift[];
  staffAttendances: StaffAttendance[];
}

export interface DetailedAttendance {
  start_time: number | null;
  end_time: number | null;
  attendance_status: AttendanceStatus;
  memo: string | null;
  dt: Date;
  staff_id: number | null;
}

type TimeRange = {
  start_time: number | null;
  end_time: number | null;
};

type InjectionValue = {
  attendanceCountMap: Ref<Record<AttendanceStatus, number>>;
  detailedAttendances: Ref<DetailedAttendance[]>;
  fetchAttendanceData: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useAttendance');

export function useAttendanceProvider(): void {
  const { dt, budgetGroup } = useSearchConditions();
  const errorBoundary = useErrorBoundary();
  const attendanceData = ref<AttendanceData | null>(null);

  const fetchAttendanceData = errorBoundary.wrap(
    async () => {
      if (budgetGroup.value === null) {
        return;
      }
      const workplaceId = budgetGroup.value.workplace_id;
      const [staffShifts, staffAttendances] = await Promise.all([
        staffShiftApi.sub_budget_group_list({
          dt: dt.value,
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          is_appropriation_target: true,
        }),
        staffAttendanceApi.index({
          dt: dt.value,
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          is_appropriation_target: true,
        }),
      ]);

      attendanceData.value = { staffShifts, staffAttendances };
    },
    {
      fallbackMessage: '勤怠情報の取得に失敗しました',
    },
  );

  const detailedAttendances = computed<DetailedAttendance[]>(() => {
    if (!attendanceData.value) {
      return [];
    }

    const { staffShifts, staffAttendances } = attendanceData.value;

    // スタッフシフトを出勤予定ステータスでフィルタリング
    const staffShiftsToWork = staffShifts.filter((staffShift) =>
      ATTENDANCE_TYPES_AT_WORK.includes(staffShift.scheduled_attendance_type),
    );

    const staffIds = Array.from(
      new Set([...staffShiftsToWork.map((v) => v.staff_id), ...staffAttendances.map((v) => v.staff.id)]),
    );

    return staffIds.map((staff_id) => {
      const staffShift = staffShiftsToWork.find((s) => s.staff_id === staff_id);
      const staffAttendance = staffAttendances.find((a) => a.staff.id === staff_id);
      const attendanceStatus = getAttendanceStatus(staffAttendance, staffShift);
      const timeRange = getTimeRange(staffAttendance, staffShift, attendanceStatus);
      const memo = staffShifts.find((s) => s.staff_id === staff_id)?.memo ?? null;

      return {
        start_time: timeRange.start_time,
        end_time: timeRange.end_time,
        attendance_status: attendanceStatus,
        dt: dt.value,
        memo,
        staff_id: staff_id ?? null,
      };
    });
  });

  const attendanceCountMap = computed(() =>
    detailedAttendances.value.reduce(
      (count, item) => {
        count[item.attendance_status as AttendanceStatus] += 1;
        return count;
      },
      {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      },
    ),
  );

  provide({
    attendanceCountMap,
    detailedAttendances,
    fetchAttendanceData,
  });
}

const getAttendanceStatus = (staffAttendance?: StaffAttendance, staffShift?: StaffShift): AttendanceStatus => {
  if (staffAttendance !== undefined) {
    return staffAttendance.adjusted_work_end_time ? ATTENDANCE_STATUSES.FINISHED : ATTENDANCE_STATUSES.ATTENDING;
  } else if (staffShift !== undefined) {
    return staffShift.actual_attendance_type === ATTENDANCE_TYPE.ABSENCE
      ? ATTENDANCE_STATUSES.ABSENCE
      : ATTENDANCE_STATUSES.NOT_ATTENDING;
  }
  return ATTENDANCE_STATUSES.NOT_ATTENDING;
};

const getTimeRange = (
  staffAttendance: StaffAttendance | undefined,
  staffShift: StaffShift | undefined,
  AttendanceStatus: number,
): TimeRange => {
  const scheduled_start_time = staffShift ? staffShift.scheduled_work_start_time : null;
  const scheduled_end_time = staffShift ? staffShift.scheduled_work_end_time : null;
  const actual_start_time = staffAttendance ? staffAttendance.adjusted_work_start_time : null;
  const actual_end_time = staffAttendance ? staffAttendance.adjusted_work_end_time : null;
  switch (AttendanceStatus) {
    case ATTENDANCE_STATUSES.ATTENDING:
      return {
        start_time: actual_start_time,
        end_time: scheduled_end_time,
      };
    case ATTENDANCE_STATUSES.NOT_ATTENDING:
      return {
        start_time: scheduled_start_time,
        end_time: scheduled_end_time,
      };
    case ATTENDANCE_STATUSES.FINISHED:
      return {
        start_time: actual_start_time,
        end_time: actual_end_time,
      };
    default:
      return {
        start_time: null,
        end_time: null,
      };
  }
};

export function useAttendance(): InjectionValue {
  return inject();
}
