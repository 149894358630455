
import {
  defineComponent,
  reactive,
  SetupContext,
  getCurrentInstance,
  computed,
  watchEffect,
  ComputedRef,
} from '@vue/composition-api';
import { vvHasError, vvGetError, vvReset } from 'src/util/vee_validate';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import Axios from 'axios';
import { isExist } from 'src/util/isExist';
import timetableGroupMasterApi from 'src/apis/workplace_masters/timetable_group_master';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { TimetableMaster } from 'src/models/timetableMaster';

const displayPageName = 'グループに含む工程';
type Operation = 'assign' | 'unassign';
const msgVars: Record<Operation, string> = {
  assign: '作成',
  unassign: '削除',
};
const errIds = { assign: 'ERR00001', unassign: 'ERR00003' };

type SaveCandidate = {
  timetableMasterId: number;
};

interface State {
  timetableMasters: TimetableMaster[];
  saveCandidate: SaveCandidate;
  showSaveModal: boolean;
  unassignCandidate: TimetableMaster | null;
  showUnassignModal: boolean;
  selectedBudgetGroupId?: number | null;
  selectTimetableMasterId: number | null;
  filteredTimetableMasters: TimetableMaster[];
  selectedTimetableGroupMasterId?: number | null;
}

const validations = {
  name: { required: true, max: 30 },
};

function buildInitialSaveCandidate(): SaveCandidate {
  return reactive({
    timetableMasterId: 0,
  });
}

function setupState(): State {
  return reactive({
    timetableMasters: [],
    saveCandidate: buildInitialSaveCandidate(),
    showSaveModal: false,
    unassignCandidate: null,
    showUnassignModal: false,
    selectedBudgetGroupId: null,
    selectTimetableMasterId: null,
    filteredTimetableMasters: [],
    selectedTimetableGroupMasterId: null,
  });
}

export default defineComponent({
  name: 'timetable-master',
  props: {
    budgetGroupId: {
      type: Number,
    },
    timetableGroupMasterId: {
      type: Number,
    },
    displayDisabledItem: {
      type: Boolean,
    },
  },
  setup(props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()!.proxy.$root;
    const state = setupState();
    const workplaceId = vueInstance.$route.params.workplaceId;
    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;
    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });
    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    function clearErrors() {
      vvReset(vueInstance);
    }
    watchEffect(() => {
      state.selectedBudgetGroupId = props.budgetGroupId;
      state.selectedTimetableGroupMasterId = props.timetableGroupMasterId;
      if (!isExist(state.selectedTimetableGroupMasterId)) {
        state.timetableMasters = [];
        return;
      }

      selectTimetableMasters(state.selectedBudgetGroupId!, state.selectedTimetableGroupMasterId!);
    });

    async function openCreateModal() {
      if (!isExist(state.selectedTimetableGroupMasterId)) {
        return;
      }

      const params = { budget_group_id: state.selectedBudgetGroupId! };
      const timetableMasters = await timetableMasterApi.index({
        workplaceId: workplaceId,
        params: params,
      });
      const filteredTimetableMasters = timetableMasters.filter(
        (timetableMaster) => timetableMaster.budget_group_id === state.selectedBudgetGroupId!,
      );

      if (filteredTimetableMasters.length === 0) {
        notifyError1(vueInstance, '登録可能な工程マスタが存在しません。');
      }

      const saveCandidate = buildInitialSaveCandidate();
      state.filteredTimetableMasters = filteredTimetableMasters;
      saveCandidate.timetableMasterId = state.filteredTimetableMasters[0].id;
      state.saveCandidate = saveCandidate;
      state.showSaveModal = true;
      clearErrors();
    }

    function closeSaveModal(): void {
      state.saveCandidate = buildInitialSaveCandidate();
      state.showSaveModal = false;
      clearErrors();
    }

    async function _saveTimetableMaster() {
      if (!isExist(state.saveCandidate)) {
        return;
      }

      await assignTimetableMaster();
    }

    async function assignTimetableMaster(): Promise<void> {
      if (!isExist(state.selectedTimetableGroupMasterId)) {
        return;
      }

      const data = {
        timetable_master_id: state.saveCandidate.timetableMasterId,
      };
      try {
        await timetableGroupMasterApi.assignTimetableMasters({
          ...data,
          timetable_group_master_id: state.selectedTimetableGroupMasterId,
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
        });
        await selectTimetableMasters(state.selectedBudgetGroupId!, state.selectedTimetableGroupMasterId!);
        closeSaveModal();
        notifySuccess1(vueInstance, '工程グループに含む工程マスタを作成しました');
      } catch (err: any) {
        handleError(err, 'assign');
      }
    }

    function handleError(err: any, operation: Operation): void {
      if (Axios.isAxiosError(err)) {
        const errStatus = err.response!.status;
        const errRes = err.response!.data || {};
        if (errStatus >= 400 && errRes.reason) {
          const msg = errRes.reason;
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else if (errStatus >= 400 && errStatus < 500) {
          const msg = Array.isArray(errRes) ? errRes[0] : errRes;
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          notifyCommonError(operation, err as Error);
        }
      }
    }

    function openUnassignModal(timetableMaster: TimetableMaster): void {
      state.unassignCandidate = timetableMaster;
      state.showUnassignModal = true;
    }

    function closeUnassignModal(): void {
      state.unassignCandidate = null;
      state.showUnassignModal = false;
    }

    async function _unassignTimetableMaster(): Promise<void> {
      if (!state.unassignCandidate) {
        return;
      }

      try {
        await timetableGroupMasterApi.unassignTimetableMaster({
          workplace_id: workplaceId,
          budget_group_id: state.selectedBudgetGroupId!,
          timetable_master_id: state.unassignCandidate.id,
          timetable_group_master_id: state.selectedTimetableGroupMasterId!,
        });
        await selectTimetableMasters(state.selectedBudgetGroupId!, state.selectedTimetableGroupMasterId!);
        closeUnassignModal();
        notifySuccess1(vueInstance, 'グループに含む工程を削除しました');
      } catch (err) {
        handleError(err, 'unassign');
      }
    }

    async function selectTimetableMasters(budgetGroupId: number, timetableGroupMasterId: number) {
      const params = {
        workplace_id: workplaceId,
        budget_group_id: budgetGroupId,
      };

      const timetableGroupMasters = await timetableGroupMasterApi.index(params);
      const timetableGroupMaster = timetableGroupMasters.find(
        (timetable_group_master) => timetable_group_master.id === timetableGroupMasterId,
      );
      const timetableMasters = timetableGroupMaster?.timetable_masters ?? [];
      state.timetableMasters = props.displayDisabledItem
        ? timetableMasters
        : timetableMasters.filter((timetable_master) => timetable_master.is_enabled === true);
    }

    const gatedFuncGenerator = getGatedFuncGenerator();

    function notifyCommonError(operation: Operation, err?: Error) {
      const msg =
        `${displayPageName}の${msgVars[operation]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[operation]}, user_id:${userId.value})`;
      notifyError1(vueInstance, msg, { err });
    }
    return {
      state,
      validations,
      // computed
      hasError,
      // methods
      getError,
      openCreateModal,
      closeSaveModal,
      openUnassignModal,
      closeUnassignModal,
      saveTimetableMaster: gatedFuncGenerator.makeAsyncFuncGated(_saveTimetableMaster),
      unassignTimetableMaster: gatedFuncGenerator.makeAsyncFuncGated(_unassignTimetableMaster),
    };
  },
});
