















import { defineComponent, type PropType } from '@vue/composition-api';
import { MANAGEMENT_TYPE, ManagementType, MANAGEMENT_TYPE_NAME_MAP } from 'src/consts';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<ManagementType | null>,
      default: null,
    },
    disallowed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const options = (
      [MANAGEMENT_TYPE.QUANTITY_AND_MAN_HOUR, MANAGEMENT_TYPE.ONLY_QUANTITY, MANAGEMENT_TYPE.ONLY_MAN_HOUR] as const
    ).map((value) => ({
      value,
      label: MANAGEMENT_TYPE_NAME_MAP[value],
    }));

    return {
      options,
    };
  },
});
