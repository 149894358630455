









import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { orDefault } from 'src/util/filters';
import { formatTime } from '../../utils/filters';
import { useProgressSummary } from '../../composables/useProgressSummary';

export default defineComponent({
  filters: {
    orDefault,
    formatTime,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const { progressPlanDataListWithCumulativeValues } = useProgressSummary();

    const progressPlanDataEndTime = computed(() => {
      const progressPlanDataList = progressPlanDataListWithCumulativeValues.value;
      const lastProgressPlanData = progressPlanDataList[progressPlanDataList.length - 1] ?? null;

      if (
        props.progressHeader.scheduled_quantity === null ||
        lastProgressPlanData === null ||
        lastProgressPlanData.cumulativeQuantity !== props.progressHeader.scheduled_quantity
      ) {
        return null;
      }
      return lastProgressPlanData.endTime;
    });

    return {
      progressPlanDataEndTime,
    };
  },
});
