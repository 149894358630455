import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import {
  type AggregationTargetStaffTagIndexRequest,
  type AggregationTargetStaffTagCreateRequest,
  type AggregationTargetStaffTagDeleteRequest,
} from 'src/models/api/workplaceMasters/aggregationTargetStaffTagRequest';
import { type AggregationTargetStaffTagIndexResponse } from 'src/models/api/workplaceMasters/aggregationTargetStaffTagResponse';

export default {
  index({
    workplace_id,
  }: AggregationTargetStaffTagIndexRequest): Promise<AxiosResponse<AggregationTargetStaffTagIndexResponse>> {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplace_id}/aggregation_target_staff_tags`));
  },
  create({ workplace_id, ...data }: AggregationTargetStaffTagCreateRequest): Promise<AxiosResponse<{}>> {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplace_id}/aggregation_target_staff_tags`), data);
  },
  delete({ workplace_id, id }: AggregationTargetStaffTagDeleteRequest): Promise<AxiosResponse<{}>> {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplace_id}/aggregation_target_staff_tags/${id}`));
  },
};
