



















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import DoughnutChart from 'src/components/UIComponents/Charts/DoughnutChart';
import { orDefault } from 'src/util/filters';
import { getProgressRate, getQuantityColor } from 'src/util/progressHeaderHelpers';
import { formatTime, formatRate } from '../../utils/filters';
import { getProgressChartDataset } from './getProgressChartDataset';
import { useProgressSummary } from '../../composables/useProgressSummary';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  filters: {
    formatTime,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const { resultQuantity, plannedQuantity } = useProgressSummary();
    const dataset = computed(() =>
      getProgressChartDataset(props.progressHeader, resultQuantity.value, plannedQuantity.value),
    );
    const centerText = computed(() => `${orDefault(formatRate(getProgressRate(props.progressHeader)), '-')}%`);
    const centerTextColor = computed(() => getQuantityColor(resultQuantity.value, plannedQuantity.value));

    return {
      dataset,
      centerText,
      centerTextColor,
    };
  },
});
