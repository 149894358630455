








































































































































































































import Vue from 'vue';
import { defineComponent, ref, computed, type PropType, watch } from '@vue/composition-api';
import { ModalForm, FormItem } from 'src/components/UIComponents/Form';
import { TextInput } from 'src/components/UIComponents/Inputs/TextInput';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { TimeRangeInput } from 'src/components/UIComponents/Inputs/TimeRangeInput';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { useCustomValidator } from 'src/composables/useCustomValidator';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import {
  APPROPRIATION_DATE_DIFF_MAX,
  APPROPRIATION_DATE_DIFF_MIN,
  APPROPRIATION_DATE_DIFF_ANCHOR,
  APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS,
  APPROPRIATION_DATE_RANGE,
  APPROPRIATION_DATE_RANGE_OPTIONS,
} from '../../const';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { getActivityMasterIds } from 'src/util/timetableMasterExternalSourceHelpers';
import { type TimeRange, createTimeRange } from 'src/values/TimeRange';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { type TimetableMasterSaveItem } from '../../types/TimetableMasterSaveItem';
import { ColorSelect } from '../ColorSelect';
import { TimetableActivityMastersSelect } from '../TimetableActivityMastersSelect';
import { TimetableLabelSelect } from '../TimetableLabelSelect';
import { ManagementTypeSelect } from '../ManagementTypeSelect';
import { AppropriationDateDiffAnchorSelect } from '../AppropriationDateDiffAnchorSelect';
import { AppropriationDateRangeSelect } from '../AppropriationDateRangeSelect';
import TimetableMasterInputModalFormGroup from './TimetableMasterInputModalFormGroup.vue';
import TimetableMasterInputModalNumberInput from './TimetableMasterInputModalNumberInput.vue';
import FeatureSelect from './FeatureSelect.vue';
import Container from './Container.vue';
import SplitContent from './SplitContent.vue';
import { featuresValidator } from './validators';
import { useTimeRangeValidator } from 'src/composables/useTimeRangeValidator';
import { MANAGEMENT_TYPE } from 'src/consts';

export default defineComponent({
  components: {
    ModalForm,
    FormItem,
    TextInput,
    TimeInput,
    TimeRangeInput,
    ToggleSwitch,
    ColorSelect,
    TimetableActivityMastersSelect,
    TimetableLabelSelect,
    ManagementTypeSelect,
    AppropriationDateDiffAnchorSelect,
    AppropriationDateRangeSelect,
    TimetableMasterInputModalFormGroup,
    TimetableMasterInputModalNumberInput,
    FeatureSelect,
    Container,
    SplitContent,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    timetableMaster: {
      type: Object as PropType<TimetableMaster | null>,
      default: null,
    },
    onSubmit: {
      type: Function as PropType<(timetableMasterSaveItem: TimetableMasterSaveItem) => Promise<unknown>>,
      required: true,
    },
  },
  setup(props, context) {
    const root = context.root as Vue;
    const hasRoleSuperAdmin = root.$store.getters['user/hasRoleSuperAdmin'];
    const { in15StepMinutesValidator, afterOrEqualValidator, withinValidator } = useTimeRangeValidator();
    const in15StepMinutesRule = useCustomValidator(in15StepMinutesValidator);
    const afterOrEqualRule = useCustomValidator(afterOrEqualValidator);
    const withinRule = useCustomValidator(withinValidator);
    const workTimeRules = `required|${in15StepMinutesRule}`;
    const break1TimeRules = `${in15StepMinutesRule}|${withinRule}:workTimeRange`;
    const break2TimeRules = `${in15StepMinutesRule}|${withinRule}:workTimeRange|${afterOrEqualRule}:break1TimeRange`;
    const featuresRule = useCustomValidator(featuresValidator);
    const { timetableLabels } = useTimetableLabels();
    const { budgetGroupTimetableActivityMasters } = useTimetableActivityMasters();
    const name = ref(props.timetableMaster?.name ?? '');
    const features = ref({
      // use_in_daily_simulationとuse_in_staff_work_planは同一の値となるように制御をかけており、フロント側では一方の値を使用するようにしている。
      useInDailySimulationAndStaffWorkPlan: props.timetableMaster?.use_in_staff_work_plan ?? false,
      useInPerformanceBoardDetail: props.timetableMaster?.use_in_performance_board_detail ?? false,
      useInPerformanceBoardList: props.timetableMaster?.use_in_performance_board_list ?? false,
      useInStaffOverview: props.timetableMaster?.use_in_staff_overview ?? false,
    });
    const plannedProductivity = ref(props.timetableMaster?.planned_productivity ?? null);
    const dispColor = ref(props.timetableMaster?.disp_color ?? null);
    const isEnabled = ref(props.timetableMaster?.is_enabled ?? true);
    const workTimeRange = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.start_time ?? 0, props.timetableMaster?.end_time ?? 240000),
    );
    const break1TimeRange = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.break1_start_time ?? null, props.timetableMaster?.break1_end_time ?? null),
    );
    const break2TimeRange = ref<TimeRange | null>(
      createTimeRange(props.timetableMaster?.break2_start_time ?? null, props.timetableMaster?.break2_end_time ?? null),
    );
    const plannedQuantity = ref(props.timetableMaster?.planned_quantity ?? 0);
    const maxAllocations = ref(props.timetableMaster?.max_allocations ?? 0);
    const targetTime = ref(props.timetableMaster?.target_time ?? null);
    const timetableLabel = ref(props.timetableMaster?.timetable_label ?? null);
    const managementType = ref(props.timetableMaster?.management_type ?? MANAGEMENT_TYPE.QUANTITY_AND_MAN_HOUR);
    const appropriationDtDiffStart = ref<number>(
      props.timetableMaster?.appropriation_dt_diff_start ?? APPROPRIATION_DATE_DIFF_MIN,
    );
    const appropriationDtDiffEnd = ref<number>(
      props.timetableMaster?.appropriation_dt_diff_end ?? APPROPRIATION_DATE_DIFF_MAX,
    );
    const appropriationDateDiffAnchor = ref<number | null>(null);
    const appropriationDateRange = ref<number>(APPROPRIATION_DATE_RANGE.ON);
    const shouldAggregateTransactions = ref(props.timetableMaster?.should_aggregate_transactions ?? false);
    const activityMasterIdsForQuantity = ref(
      getActivityMasterIds(props.timetableMaster?.timetable_master_external_sources ?? [], 'use_for_quantity'),
    );
    const activityMasterIdsForManHours = ref(
      getActivityMasterIds(props.timetableMaster?.timetable_master_external_sources ?? [], 'use_for_man_hours'),
    );
    const handleSubmit = async () => {
      await props.onSubmit({
        name: name.value,
        use_in_daily_simulation: features.value.useInDailySimulationAndStaffWorkPlan,
        use_in_staff_work_plan: features.value.useInDailySimulationAndStaffWorkPlan,
        use_in_performance_board_detail: features.value.useInPerformanceBoardDetail,
        use_in_performance_board_list: features.value.useInPerformanceBoardList,
        use_in_staff_overview: features.value.useInStaffOverview,
        planned_productivity: plannedProductivity.value!,
        disp_color: dispColor.value!,
        is_enabled: isEnabled.value,
        start_time: workTimeRange.value?.startTime!,
        end_time: workTimeRange.value?.endTime!,
        break1_start_time: break1TimeRange.value?.startTime ?? null,
        break1_end_time: break1TimeRange.value?.endTime ?? null,
        break2_start_time: break2TimeRange.value?.startTime ?? null,
        break2_end_time: break2TimeRange.value?.endTime ?? null,
        planned_quantity: plannedQuantity.value,
        max_allocations: maxAllocations.value,
        target_time: targetTime.value,
        timetable_label: timetableLabel.value!,
        should_aggregate_transactions: shouldAggregateTransactions.value,
        management_type: managementType.value!,
        timetable_master_external_sources: [
          ...activityMasterIdsForQuantity.value.map((v) => ({ source_id: v, usage_type: 'use_for_quantity' } as const)),
          ...activityMasterIdsForManHours.value.map(
            (v) => ({ source_id: v, usage_type: 'use_for_man_hours' } as const),
          ),
        ],
        appropriation_dt_diff_start: appropriationDtDiffStart.value,
        appropriation_dt_diff_end: appropriationDtDiffEnd.value,
      });
    };

    // appropriation_dt_diff_startとappropriation_dt_diff_endから「特定の計上日に絞り込み」を算出する
    const computeAppropriationDate = (timetableMaster: TimetableMaster): void => {
      // appropriation_dt_diff_start:-32, appropriation_dt_diff_end:32の時
      // 未設定なので「特定の計上日に絞り込み」は設定なしと判断する
      if (
        timetableMaster.appropriation_dt_diff_start === APPROPRIATION_DATE_DIFF_MIN &&
        timetableMaster.appropriation_dt_diff_end === APPROPRIATION_DATE_DIFF_MAX
      ) {
        return;
      }
      // appropriation_dt_diff_startとappropriation_dt_diff_endが同じ値の時
      // 「特定の計上日に絞り込み」左プルダウン：選択済、「特定の計上日に絞り込み」右プルダウン：- と判断する
      if (timetableMaster.appropriation_dt_diff_start === timetableMaster.appropriation_dt_diff_end) {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_start;
        appropriationDateRange.value = APPROPRIATION_DATE_RANGE.ON;
        return;
      }

      if (Object.values(APPROPRIATION_DATE_DIFF_ANCHOR).includes(timetableMaster.appropriation_dt_diff_start!)) {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_start;
        appropriationDateRange.value =
          timetableMaster.appropriation_dt_diff_end! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      } else {
        appropriationDateDiffAnchor.value = timetableMaster.appropriation_dt_diff_end;
        appropriationDateRange.value =
          timetableMaster.appropriation_dt_diff_start! > 0
            ? APPROPRIATION_DATE_RANGE.ON_AND_AFTER
            : APPROPRIATION_DATE_RANGE.ON_AND_BEFORE;
      }
    };
    if (props.timetableMaster) {
      computeAppropriationDate(props.timetableMaster);
    }

    // 「特定の計上日に絞り込み」からappropriation_dt_diff_startとappropriation_dt_diff_endを算出する
    const computeAppropriationDtDiff = () => {
      // 「特定の計上日に絞り込み」が設定なしの時
      // デフォルト値 appropriation_dt_diff_start:-32, appropriation_dt_diff_end:32とする
      if (!appropriationDateDiffAnchor.value && appropriationDateDiffAnchor.value !== 0) {
        appropriationDtDiffStart.value = APPROPRIATION_DATE_DIFF_MIN;
        appropriationDtDiffEnd.value = APPROPRIATION_DATE_DIFF_MAX;
      } else if (appropriationDateRange.value === APPROPRIATION_DATE_RANGE.ON) {
        // 「特定の計上日に絞り込み」右プルダウン：- の時
        // appropriation_dt_diff_start・appropriation_dt_diff_endともに、「特定の計上日に絞り込み」左プルダウンの値とする
        appropriationDtDiffStart.value = appropriationDateDiffAnchor.value;
        appropriationDtDiffEnd.value = appropriationDateDiffAnchor.value;
      } else if (appropriationDateRange.value === APPROPRIATION_DATE_RANGE.ON_AND_BEFORE) {
        // 「特定の計上日に絞り込み」右プルダウン：以前 の時
        appropriationDtDiffStart.value = APPROPRIATION_DATE_DIFF_MIN;
        appropriationDtDiffEnd.value = appropriationDateDiffAnchor.value;
      } else {
        // 「特定の計上日に絞り込み」右プルダウン：以降 の時
        appropriationDtDiffStart.value = appropriationDateDiffAnchor.value;
        appropriationDtDiffEnd.value = APPROPRIATION_DATE_DIFF_MAX;
      }
    };

    // 「特定の計上日に絞り込み」右プルダウンの活性化・非活性化処理
    const isAppropriationDateRangeOptionDisabled = computed(() => {
      return typeof appropriationDateDiffAnchor.value !== 'number';
    });

    watch(
      () => appropriationDateDiffAnchor.value,
      () => {
        computeAppropriationDtDiff();
      },
    );

    watch(
      () => appropriationDateRange.value,
      () => {
        computeAppropriationDtDiff();
      },
    );

    watch(
      () => isAppropriationDateRangeOptionDisabled.value,
      () => {
        if (isAppropriationDateRangeOptionDisabled.value) {
          appropriationDateRange.value = APPROPRIATION_DATE_RANGE.ON;
        }
      },
    );

    return {
      hasRoleSuperAdmin,
      workTimeRules,
      break1TimeRules,
      break2TimeRules,
      featuresRule,
      timetableLabels,
      budgetGroupTimetableActivityMasters,
      APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS,
      APPROPRIATION_DATE_RANGE_OPTIONS,
      isAppropriationDateRangeOptionDisabled,
      name,
      features,
      plannedProductivity,
      dispColor,
      isEnabled,
      workTimeRange,
      break1TimeRange,
      break2TimeRange,
      plannedQuantity,
      maxAllocations,
      targetTime,
      timetableLabel,
      managementType,
      shouldAggregateTransactions,
      activityMasterIdsForQuantity,
      activityMasterIdsForManHours,
      appropriationDateDiffAnchor,
      appropriationDateRange,
      handleSubmit,
    };
  },
});
