







import * as C from 'src/consts';
import { defineComponent, reactive } from '@vue/composition-api';

interface State {
  maxBulkUpdatableCount: number;
}

function setupState(): State {
  return reactive({
    maxBulkUpdatableCount: C.DATA_MAX_BULK_UPDATE_ITEM_COUNT,
  });
}

export default defineComponent({
  setup() {
    const state = setupState();

    return {
      state,
    };
  },
});
