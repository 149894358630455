import { StaffExtensionResponse } from 'src/models/api/staffExtensionResponse';
import { parseYmdDate } from 'src/util/datetime';
import { StaffWorkSchedule, convertStaffWorkScheduleResponse } from './staffWorkSchedule';

/**
 * TODO
 * 全体的にモデル型のプロパティはキャメルケースで定義したい（StaffExtension以外の型も同様）
 */
export type StaffExtension = Omit<
  StaffExtensionResponse,
  'staff_work_schedules' | 'joined_date' | 'first_attendance_date' | 'dispatch_conflict_date' | 'retirement_date'
> & {
  staff_work_schedules: StaffWorkSchedule[];
  joined_date: Date | null;
  first_attendance_date: Date | null;
  dispatch_conflict_date: Date | null;
  retirement_date: Date | null;
};

export function convertStaffExtensionResponse(response: StaffExtensionResponse): StaffExtension {
  return {
    ...response,
    staff_work_schedules: response.staff_work_schedules?.map(convertStaffWorkScheduleResponse) ?? [],
    joined_date: response.joined_date ? parseYmdDate(response.joined_date) : null,
    first_attendance_date: response.first_attendance_date ? parseYmdDate(response.first_attendance_date) : null,
    dispatch_conflict_date: response.dispatch_conflict_date ? parseYmdDate(response.dispatch_conflict_date) : null,
    retirement_date: response.retirement_date ? parseYmdDate(response.retirement_date) : null,
  };
}
