import { computed, type ComputedRef } from '@vue/composition-api';
import aggregationTargetStaffTagApi from 'src/apis/workplace_masters/aggregationTargetStaffTag';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import { useNotification } from 'src/composables/useNotification';
import { type AggregationTargetStaffTag } from 'src/models/workplaceMasters/aggregationTargetStaffTag';
import { createInjection } from 'src/util/createInjection';
import { useAggregationTargetStaffTags } from './useAggregationTargetStaffTags';

type ModalContext = {
  aggregationTargetStaffTag: AggregationTargetStaffTag;
};

type InjectionValue = {
  aggregationTargetStaffTag: ComputedRef<AggregationTargetStaffTag | null>;
  showsAggregationTargetStaffTagDeleteConfirmationModal: ModalWithContext<ModalContext>['showsModal'];
  showAggregationTargetStaffTagDeleteConfirmationModal: ModalWithContext<ModalContext>['showModal'];
  hideAggregationTargetStaffTagDeleteConfirmationModal: ModalWithContext<ModalContext>['hideModal'];
  deleteAggregationTargetStaffTag: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useDeleteAggregationTargetStaffTag');

async function requestToDeleteAggregationTargetStaffTag({
  aggregationTargetStaffTag,
}: {
  aggregationTargetStaffTag: AggregationTargetStaffTag;
}): Promise<void> {
  await aggregationTargetStaffTagApi.delete({
    workplace_id: aggregationTargetStaffTag.workplace_id,
    id: aggregationTargetStaffTag.id,
  });
}

export function useDeleteAggregationTargetStaffTagProvider(): void {
  const { notifySuccess } = useNotification();
  const errorBoundary = useErrorBoundary();
  const {
    context,
    showsModal: showsAggregationTargetStaffTagDeleteConfirmationModal,
    showModal: showAggregationTargetStaffTagDeleteConfirmationModal,
    hideModal: hideAggregationTargetStaffTagDeleteConfirmationModal,
  } = useModalWithContext<ModalContext>();
  const { fetchAggregationTargetStaffTags } = useAggregationTargetStaffTags();
  const deleteAggregationTargetStaffTag = errorBoundary.wrap(
    async () => {
      if (context.value?.aggregationTargetStaffTag != null) {
        await requestToDeleteAggregationTargetStaffTag({
          aggregationTargetStaffTag: context.value.aggregationTargetStaffTag,
        });
        hideAggregationTargetStaffTagDeleteConfirmationModal();
        notifySuccess('シフト集計に使うスタッフタグを削除しました');
        await fetchAggregationTargetStaffTags();
      }
    },
    {
      fallbackMessage: 'シフト集計に使うスタッフタグの削除に失敗しました。',
      shouldContactUs: true,
    },
  );

  provide({
    aggregationTargetStaffTag: computed(() => context.value?.aggregationTargetStaffTag ?? null),
    showsAggregationTargetStaffTagDeleteConfirmationModal,
    showAggregationTargetStaffTagDeleteConfirmationModal,
    hideAggregationTargetStaffTagDeleteConfirmationModal,
    deleteAggregationTargetStaffTag,
  });
}

export function useDeleteAggregationTargetStaffTag(): InjectionValue {
  return inject();
}
