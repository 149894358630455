
















import { defineComponent, watch, type PropType } from '@vue/composition-api';
import { type StaffTag } from 'src/models/workplaceMasters/staffTag';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<StaffTag | null>,
      default: null,
    },
    staffTags: {
      type: Array as PropType<StaffTag[]>,
      required: true,
    },
  },
  setup(props, context) {
    watch(
      [() => props.value, () => props.staffTags],
      () => {
        const hasValue = props.staffTags.some((v) => v.id === props.value?.id);
        const newValue = props.staffTags[0] ?? null;
        if (!hasValue && newValue !== props.value) {
          context.emit('input', newValue);
        }
      },
      {
        immediate: true,
      },
    );
  },
});
