








import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useTimetableLabelsProvider } from 'src/composables/useTimetableLabels';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { Content } from '../Content';
import { ControlPanel } from '../ControlPanel';
import { MainPanel } from '../MainPanel';
import { useProgressHeadersProvider } from '../../composables/useProgressHeaders';
import { useCurrentStaffWorkProvider } from '../../composables/useCurrentStaffWork';
import { useSelectedTimetablesProvider, useSelectedTimetables } from '../../composables/useSelectedTimetables';
import { useMemoProvider } from '../../composables/useMemo';
import { useAttendanceProvider } from 'src/composables/useAttendance';
import { useSearchConditionsProvider } from 'src/composables/useSearchConditions';

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);
    useSelectedTimetablesProvider();
    useSearchConditionsProvider();
    const { timetableLabels } = useSelectedTimetables();

    setPageName(root, 'パフォーマンスボード カード');
    useBudgetGroupsProvider({ workplaceId });
    useTimetableLabelsProvider({ workplaceId });
    useProgressHeadersProvider({ workplaceId, timetableLabels });
    useCurrentStaffWorkProvider({ workplaceId });
    useMemoProvider({ workplaceId });
    useAttendanceProvider();
  },
});
