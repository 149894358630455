import type { TimetableAllocationPlan } from './timetableAllocationPlan';
import type { StaffAssignmentPlan } from './staffAssignmentPlan';
import type { WorkPlanHeaderByDateResponse } from './api/workPlanHeaderResponse';

export type WorkPlanHeader = {
  id: number;
  budgetGroupId: number;
  dt: Date;
  timetableAllocationPlans: TimetableAllocationPlan[];
  staffAssignmentPlans: StaffAssignmentPlan[];
};

export function convertWorkPlanHeaderByDateResponse(response: WorkPlanHeaderByDateResponse): WorkPlanHeader {
  return {
    id: response.id,
    dt: response.dt,
    budgetGroupId: response.budget_group_id,
    timetableAllocationPlans: response.timetable_allocation_plans.map((timetableAllocationPlan) => ({
      timetableMasterId: timetableAllocationPlan.timetable_master_id,
      data: timetableAllocationPlan.data.map((item) => ({
        startTime: item.start_time,
        endTime: item.end_time,
        requiredHeadcount: item.required_headcount,
      })),
    })),
    staffAssignmentPlans: response.staff_assignment_plans.map((staffAssignmentPlan) => ({
      staffId: staffAssignmentPlan.staff_id,
      data: staffAssignmentPlan.data.map((item) => ({
        startTime: item.start_time,
        endTime: item.end_time,
        timetableMasterId: item.timetable_master_id,
      })),
    })),
  };
}
