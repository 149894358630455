











import { defineComponent, type PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    align: {
      type: String as PropType<'left' | 'center'>,
      default: 'center',
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
  },
});
