import Vue from 'vue';
import { defineComponent, SetupContext, ref, computed, ComputedRef } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { isExist } from 'src/util/isExist';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { EditableTime } from 'src/components/UIComponents/Inputs/EditableTime';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { TimetableLabelSelect } from 'src/components/TimetableLabelSelect';
import { TimetableMasterSelect } from 'src/components/TimetableMasterSelect';
import { COLUMN_WIDTH, UNIT_BLOCK_MINUTES } from './const';
import type { TimetableRow } from './types';
import { formatCommaValue } from './dailySimulationHelper';
import { useSearchConditions, useSearchConditionsProvider } from './composables/useSearchConditions';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useTimetableLabels, useTimetableLabelsProvider } from 'src/composables/useTimetableLabels';
import { useTimetableMasters, useTimetableMastersProvider } from 'src/composables/useTimetableMasters';
import ControlPanel from './components/ControlPanel.vue';
import StaffShiftPanel from './components/StaffShiftPanel.vue';
import HeadcountTimeBlock from './components/HeadcountTimeBlock.vue';
import ProgressSections from './components/ProgressSections.vue';
import NumberInput from './components/NumberInput.vue';
import { dailyBoardPageOptions, TIMETABLE_TYPE } from 'src/consts';
import ProgressDetailEditButton from './components/ProgressDetailEditButton.vue';
import BreakTimeSettingModal from './components/BreakTimeSettingModal.vue';
import CommonSettingModal from './components/CommonSettingModal.vue';
import ProgressPlanBulkUpsertModal from './components/ProgressPlanBulkUpsertModal.vue';
import HeadcountUpdatePopover from './components/UpdatePopover/HeadcountUpdatePopover.vue';
import QuantityUpdatePopover from './components/UpdatePopover/QuantityUpdatePopover.vue';
import ProductivityUpdatePopover from './components/UpdatePopover/ProductivityUpdatePopover.vue';
import { useProgressDetailListModalProvider } from '../PerformanceBoardDetail/composables/useProgressDetailListModal';
import { useCreateProgressDetailProvider } from '../PerformanceBoardDetail/composables/useCreateProgressDetail';
import { useUpdateProgressDetailProvider } from '../PerformanceBoardDetail/composables/useUpdateProgressDetail';
import { useDeleteProgressDetailProvider } from '../PerformanceBoardDetail/composables/useDeleteProgressDetail';
import { ProgressDetailListModal } from '../PerformanceBoardDetail/components/ProgressDetailListModal';
import { ProgressDetailCreateModal } from '../PerformanceBoardDetail/components/ProgressDetailCreateModal';
import { ProgressDetailUpdateModal } from '../PerformanceBoardDetail/components/ProgressDetailUpdateModal';
import { ProgressDetailDeleteConfirmationModal } from '../PerformanceBoardDetail/components/ProgressDetailDeleteConfirmationModal';
import { useProgressHeaders, useProgressHeadersProvider } from './composables/useProgressHeaders';
import { useBreakTimeSetting, useBreakTimeSettingProvider } from './composables/useBreakTimeSetting';
import { useCommonSetting, useCommonSettingProvider } from './composables/useCommonSetting';
import {
  useProgressPlanBulkUpsertModal,
  useProgressPlanBulkUpsertModalProvider,
} from './composables/useProgressPlanBulkUpsertModal';
import {
  useBudgetGroupPlanBoardMisc,
  useBudgetGroupPlanBoardMiscProvider,
} from './composables/useBudgetGroupPlanBoardMiscs';
import { useProgressSection } from './composables/useProgressSection';
import { formatTimeInteger, timeStrToTimeInteger, unpackTimeInteger } from 'src/util/datetime';
import { TimeInteger } from 'src/models/common';
import { useUpdateProgressHeader } from './composables/useUpdateProgressHeader';
import { useNotification } from 'src/composables/useNotification';
import { ValidationObserver, ValidationObserverInstance } from 'vee-validate';
import {
  useHeadcountUpdatePopover,
  useHeadcountUpdatePopoverProvider,
} from './composables/UpdatePopover/useHeadcountUpdatePopover';
import {
  useQuantityUpdatePopover,
  useQuantityUpdatePopoverProvider,
} from './composables/UpdatePopover/useQuantityUpdatePopover';
import {
  useProductivityUpdatePopover,
  useProductivityUpdatePopoverProvider,
} from './composables/UpdatePopover/useProductivityUpdatePopover';
import { useDailySimulation, useDailySimulationProvider } from './composables/useDailySimulation';
import { useDailySimulationValidationRules } from './composables/useDailySimulationValidationRules';
import ProgressDetailBulkCreateModal from './ProgressDetailBulkCreateModal/index.vue';
import {
  useProgressDetailBulkCreateFetchModal,
  useProgressDetailBulkCreateFetchModalProvider,
} from './composables/useProgressDetailBulkCreateModal';
import { useCreateProgressHeaders } from './composables/useCreateProgressHeaders';
import { useDisplayConditions, useDisplayConditionsProvider } from './composables/useDisplayConditions';
import { useStaffShiftTimelines, useStaffShiftTimelinesProvider } from './composables/useStaffShiftTimelines';
import { useMacroOperationMastersProvider } from './composables/useMacroOperationMasters';
import { useScrollSyncProvider } from './composables/useScrollSync';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { WorkplaceExtension } from 'src/models/workplaceExtension';

export default defineComponent({
  components: {
    BreakTimeSettingModal,
    CommonSettingModal,
    ProgressDetailBulkCreateModal,
    ProgressPlanBulkUpsertModal,
    ControlPanel,
    HeadcountTimeBlock,
    HeadcountUpdatePopover,
    QuantityUpdatePopover,
    ProductivityUpdatePopover,
    ProgressSections,
    NumberInput,
    NormalButton,
    PrimaryButton,
    TimetableLabelSelect,
    TimetableMasterSelect,
    ProgressDetailEditButton,
    ProgressDetailListModal,
    ProgressDetailCreateModal,
    ProgressDetailUpdateModal,
    ProgressDetailDeleteConfirmationModal,
    ValidationObserver,
    EditableTime,
    StaffShiftPanel,
    ToggleSwitch,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);
    const workplaceExtension: ComputedRef<WorkplaceExtension> = wrappedMapGetters(root.$store, 'workplace', [
      'workplaceExtension',
    ]).workplaceExtension;
    const tablesRef = ref<HTMLDivElement>();
    const shiftTablesRef = ref<HTMLDivElement>();
    const validationObserver = ref<ValidationObserverInstance>();
    const routeName = root.$route.name;
    const anchorPosition = computed(() => {
      return workplaceExtension.value ? workplaceExtension.value.timetable_start_hour * COLUMN_WIDTH : 0;
    });
    setPageName(root, '当日ボード');
    useBudgetGroupsProvider({ workplaceId });
    useTimetableLabelsProvider({ workplaceId });
    useTimetableMastersProvider({
      workplaceId,
      options: {
        includes_disabled: true, // 履歴のあるものは無効なものでも表示するという仕様のため、無効なものも取得する
        use_in_staff_work_plan: true,
        includes_resting: true,
      },
    });
    useSearchConditionsProvider({ workplaceId });
    useDisplayConditionsProvider();
    useProgressHeadersProvider();
    useCommonSettingProvider();
    useBudgetGroupPlanBoardMiscProvider();
    useDailySimulationProvider({ validationObserver });
    useProgressPlanBulkUpsertModalProvider();
    useHeadcountUpdatePopoverProvider();
    useQuantityUpdatePopoverProvider();
    useProductivityUpdatePopoverProvider();
    useProgressDetailBulkCreateFetchModalProvider();
    useBreakTimeSettingProvider();

    const { fetchProgressHeaders } = useProgressHeaders();
    useProgressDetailListModalProvider();
    useCreateProgressDetailProvider();
    useUpdateProgressDetailProvider();
    useDeleteProgressDetailProvider();
    useMacroOperationMastersProvider();
    useStaffShiftTimelinesProvider();

    useScrollSyncProvider({
      tableElement: tablesRef,
      shiftTableElement: shiftTablesRef,
      anchorPosition: anchorPosition,
    });

    const { timetableLabels } = useTimetableLabels();
    const { timetableMasters } = useTimetableMasters();
    const { budgetGroup } = useSearchConditions();
    const timetableMasterOptions = computed(() => {
      const filteredTimetableMasters = timetableMasters.value.filter(
        (timetableMaster) => timetableMaster.is_enabled && timetableMaster.timetable_type === TIMETABLE_TYPE.GENERAL,
      );
      const selectedBudgetGroup = budgetGroup.value;
      if (selectedBudgetGroup === null) {
        return filteredTimetableMasters;
      }
      return filteredTimetableMasters.filter(
        (timetableMaster) => timetableMaster.budget_group_id === selectedBudgetGroup.id,
      );
    });
    const { selectedTimetableLabels, selectedTimetableMasters, filterTimetable, isShiftGroupDisplayed } =
      useDisplayConditions();

    const { state: budgetGroupPlanBoardMiscState } = useBudgetGroupPlanBoardMisc();

    const { updateProgressHeaderScheduledQuantity, updateProgressHeaderTargetCompletionTime } =
      useUpdateProgressHeader();

    const { notifySuccess } = useNotification();

    const { getProgressSectionsStyle } = useProgressSection();

    const {
      state,
      displayedTimetable,
      displayedTimetableMasters,
      timetableRowStyle,
      calculateUnassignedTimeBlocks,
      updateHeadcountAndSimulateTimetableRow,
      updateQuantity,
      updateProductivity,
      setTotalProductivity,
      fetchAndSimulate,
      fetchAndNoSimulate,
      fetchAndSimulateTimetableRow,
      simulateTimetable,
      simulateSpecificTimetableRow,
      waitLoading,
    } = useDailySimulation();

    const { createProgressHeaders } = useCreateProgressHeaders();
    const { fetchStaffShiftTimelines } = useStaffShiftTimelines();

    const { scheduledQuantityValidationRules } = useDailySimulationValidationRules();

    const { showBreakTimeSettingModal } = useBreakTimeSetting();
    const { commonSetting, showCommonSettingModal } = useCommonSetting();
    const { showProgressPlanBulkUpsertModal } = useProgressPlanBulkUpsertModal();
    const { showProgressDetailBulkCreateFetchModal } = useProgressDetailBulkCreateFetchModal();

    const targetClockOutTimeStyle = computed(() => {
      if (!isExist(commonSetting.targetClockOutTime)) {
        return {
          display: 'none',
        };
      }
      const [h, m] = unpackTimeInteger(commonSetting.targetClockOutTime);
      return {
        display: 'block',
        left: `${COLUMN_WIDTH * (h + m / 60)}px`,
      };
    });

    const refetchProgressHeaders = async () => {
      await fetchAndSimulate({ isResetHeader: false, isResetHeadcount: true });
      notifySuccess('最新情報でやり直しました');
    };

    const formatTimeIntegerWithNull = (time: TimeInteger | null) => {
      if (!isExist(time)) {
        return '--:--';
      }
      return formatTimeInteger(time);
    };

    const toFixed = (num: number | null | undefined, digits: number): string => {
      if (!isExist(num)) {
        return '-';
      }
      return num.toFixed(digits);
    };

    const isTargetCompletionTime = (displayTime: string, { header }: TimetableRow) => {
      if (!isExist(header.targetCompletionTime)) {
        return false;
      }
      const targetTime = timeStrToTimeInteger(displayTime);
      return (
        header.targetCompletionTime <= targetTime && targetTime < header.targetCompletionTime + UNIT_BLOCK_MINUTES * 100
      );
    };

    const linkTo = (pageName: string) => {
      root.$router.push({ name: pageName });
    };

    const onSearchButtonClick = async () => {
      waitLoading();
      Promise.all([fetchStaffShiftTimelines(), fetchAndSimulate()]);
    };

    const fetchStaffShiftTimeLines = async () => {
      if (!isShiftGroupDisplayed.value) {
        return;
      }
      await fetchStaffShiftTimelines();
    };

    const onScheduledQuantityUpdate = (value: number | null, timetableRow: TimetableRow) => {
      updateProgressHeaderScheduledQuantity(
        {
          id: timetableRow.progressHeader.id,
          workplaceId: workplaceId,
          scheduledQuantity: value,
        },
        fetchProgressHeaders,
      );
    };

    const onTargetCompletionTimeUpdate = (time: TimeInteger, timetableRow: TimetableRow) => {
      updateProgressHeaderTargetCompletionTime(
        {
          id: timetableRow.progressHeader.id,
          workplaceId: workplaceId,
          targetCompletionTime: time,
        },
        fetchAndNoSimulate,
      );
    };

    const { timeBlock } = useHeadcountUpdatePopover();

    const onHeadcountTimeBlockUpdate = (nextHeadcount: number) => {
      const timeBlockValue = timeBlock.value;
      if (timeBlockValue === undefined) {
        return;
      }
      updateHeadcountAndSimulateTimetableRow(timeBlockValue, nextHeadcount);
    };

    const onMoveToResting = (nextHeadcount: number) => {
      const timeBlockValue = timeBlock.value;
      if (timeBlockValue === undefined) {
        return;
      }
      updateHeadcountAndSimulateTimetableRow(timeBlockValue, nextHeadcount, { isAdjustedWithResting: true });
    };

    const { timetableMaster: quantityTimetableMaster, progressSectionQuarterTime: quantityProgressSectionQuarterTime } =
      useQuantityUpdatePopover();

    const onQuantityUpdate = (quantityToUpdate: number) => {
      if (!isExist(quantityTimetableMaster.value) || !isExist(quantityToUpdate)) {
        return;
      }
      updateQuantity(quantityTimetableMaster.value.id, quantityProgressSectionQuarterTime.value, quantityToUpdate);
      simulateSpecificTimetableRow(
        quantityTimetableMaster.value.id,
        quantityProgressSectionQuarterTime.value.start,
        false,
      );
    };

    const {
      timetableMaster: productivityTimetableMaster,
      progressSectionQuarterTime: productivityProgressSectionQuarterTime,
    } = useProductivityUpdatePopover();

    const onProductivityUpdate = (productivityToUpdate: number) => {
      if (!isExist(productivityTimetableMaster.value) || !isExist(productivityToUpdate)) {
        return;
      }
      updateProductivity(
        productivityTimetableMaster.value.id,
        productivityProgressSectionQuarterTime.value,
        productivityToUpdate,
      );
      simulateSpecificTimetableRow(
        productivityTimetableMaster.value.id,
        productivityProgressSectionQuarterTime.value.start,
        false,
      );
    };

    const onFilterButtonClick = () => {
      filterTimetable(state.timetable);
      calculateUnassignedTimeBlocks('0:00');
    };

    const onSetTotalProductivityButtonClick = () => {
      setTotalProductivity();
      simulateTimetable('0:00', []);
      notifySuccess('当日生産性をシミュレーション生産性にセットしました');
    };

    return {
      state,
      budgetGroupPlanBoardMiscState,
      timetableRowStyle,
      tablesRef,
      routeName,
      dailyBoardPageOptions,
      targetClockOutTimeStyle,
      validationObserver,
      scheduledQuantityValidationRules,
      timetableLabels,
      timetableMasterOptions,
      selectedTimetableLabels,
      selectedTimetableMasters,
      displayedTimetable,
      displayedTimetableMasters,
      getProgressSectionsStyle,
      formatTimeIntegerWithNull,
      formatCommaValue,
      fetchProgressHeaders,
      refetchProgressHeaders,
      showBreakTimeSettingModal,
      showCommonSettingModal,
      showProgressPlanBulkUpsertModal,
      toFixed,
      fetchAndSimulate,
      fetchAndSimulateTimetableRow,
      createProgressHeaders,
      onSearchButtonClick,
      onScheduledQuantityUpdate,
      onTargetCompletionTimeUpdate,
      onHeadcountTimeBlockUpdate,
      onMoveToResting,
      onQuantityUpdate,
      onProductivityUpdate,
      onFilterButtonClick,
      onSetTotalProductivityButtonClick,
      isTargetCompletionTime,
      linkTo,
      isExist,
      showProgressDetailBulkCreateFetchModal,
      fetchStaffShiftTimeLines,
    };
  },
});
