



















































import { defineComponent, ref, computed, watch, type PropType } from '@vue/composition-api';
import { ModalForm } from 'src/components/UIComponents/Form';
import { Checkbox } from 'src/components/UIComponents';
import type { TimetableMaster } from 'src/models/timetableMaster';
import { useProgressPlanBulkUpsertModal } from '../composables/useProgressPlanBulkUpsertModal';
import { useNotification } from 'src/composables/useNotification';
import { MANAGEMENT_TYPE } from 'src/consts';

export default defineComponent({
  components: {
    ModalForm,
    Checkbox,
  },
  props: {
    timetableMasters: {
      type: Array as PropType<TimetableMaster[]>,
      required: true,
    },
  },
  setup(props) {
    const { bulkUpsertProgressPlans, showsProgressPlanBulkUpsertModal, hideProgressPlanBulkUpsertModal } =
      useProgressPlanBulkUpsertModal();

    const { notifySuccess } = useNotification();

    const displayedTimetableMasters = ref<Array<TimetableMaster & { checked: boolean }>>([]);

    const isAllChecked = computed(() =>
      displayedTimetableMasters.value.every((timetableMaster) => timetableMaster.checked),
    );

    const changeAllCheckStates = (value: boolean) => {
      displayedTimetableMasters.value = displayedTimetableMasters.value.map((displayedTimetableMaster) => {
        return {
          ...displayedTimetableMaster,
          checked: value,
        };
      });
    };

    watch(showsProgressPlanBulkUpsertModal, (newValue) => {
      if (newValue) {
        displayedTimetableMasters.value = props.timetableMasters
          .filter(({ management_type }) => management_type !== MANAGEMENT_TYPE.ONLY_MAN_HOUR)
          .map((timetableMaster) => ({
            ...timetableMaster,
            checked: false,
          }));
      }
    });

    const submittable = computed<boolean>(() => {
      return displayedTimetableMasters.value.some((timetableMaster) => timetableMaster.checked);
    });

    const onSubmit = async () => {
      const result = await bulkUpsertProgressPlans(
        displayedTimetableMasters.value
          .filter((timetableMaster) => timetableMaster.checked)
          .map((timetableMaster) => timetableMaster.id),
      );
      if (result) {
        notifySuccess('パフォーマンスボードの計画に設定しました。');
      }
    };

    return {
      showsProgressPlanBulkUpsertModal,
      hideProgressPlanBulkUpsertModal,
      isAllChecked,
      changeAllCheckStates,
      displayedTimetableMasters,
      submittable,
      onSubmit,
    };
  },
});
