








import { defineComponent, SetupContext } from '@vue/composition-api';

export default defineComponent({
  name: 'un-focus-outline-button',
  setup(_, context: SetupContext) {
    function handleClick(evt: any): void {
      context.emit('click', evt);
    }

    return {
      handleClick,
    };
  },
});
