













































import { defineComponent, getCurrentInstance, type PropType, toRef } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { type ProgressPlan } from 'src/models/progressPlan';
import CardPanel from './CardPanel.vue';
import EndTime from './EndTime.vue';
import ProgressPlansEndTime from './ProgressPlansEndTime.vue';
import Difference from './Difference.vue';
import Prediction from './Prediction.vue';
import ProductivityChart from './ProductivityChart.vue';
import ProgressChart from './ProgressChart.vue';
import ProgressStatus from './ProgressStatus.vue';
import Headcounts from './Headcounts.vue';
import CubeIcon from './CubeIcon.vue';
import { MANAGEMENT_TYPE } from 'src/consts';
import { format } from 'date-fns';
import { useProgressPlanProvider } from '../../composables/useProgressPlan';
import { useProgressSummaryProvider } from '../../composables/useProgressSummary';

export default defineComponent({
  components: {
    CardPanel,
    EndTime,
    ProgressPlansEndTime,
    Difference,
    Prediction,
    ProductivityChart,
    ProgressChart,
    ProgressStatus,
    Headcounts,
    CubeIcon,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
    progressPlan: {
      type: Object as PropType<ProgressPlan | null>,
      required: false,
      default: null,
    },
    productivityGraph: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const vueInstance = getCurrentInstance()!.proxy.$root;
    const goToPerformanceBoardDetail = (progressHeader: ProgressHeader): void => {
      const { href } = vueInstance.$router.resolve({
        name: 'WorkplacePerformanceBoardDetail',
        query: {
          budget_group_id: String(progressHeader.timetable_master.budget_group_id),
          dt: String(format(progressHeader.dt, 'yyyy-MM-dd')),
          timetable_master_ids: String(progressHeader.timetable_master.id),
        },
      });

      window.open(href, '_blank');
    };

    useProgressPlanProvider({
      progressPlan: toRef(props, 'progressPlan'),
    });
    useProgressSummaryProvider(toRef(props, 'progressHeader'));

    return {
      MANAGEMENT_TYPE,
      goToPerformanceBoardDetail,
    };
  },
});
