type PropertyKey = string | number | symbol;
type KeyTypeProps<T> = {
  [K in keyof T]-?: T[K] extends PropertyKey ? K : never;
}[keyof T];

export const useMapping = () => {
  /**
   * オブジェクトの配列を指定したプロパティの値をキーにしたオブジェクトに変換する
   * uniqueKeyは値が一意であるキーが指定されることを想定している
   * もし同じ値が存在する場合は後勝ちで上書きされる
   */
  const mapBy = <T extends object, K extends KeyTypeProps<T>>(
    items: T[],
    uniqueKey: K,
  ): Record<T[K] & PropertyKey, T> => {
    return items.reduce((acc, item) => {
      const key = item[uniqueKey];
      if (
        key !== undefined &&
        key !== null &&
        (typeof key === 'string' || typeof key === 'number' || typeof key === 'symbol')
      ) {
        acc[key] = item;
      }
      return acc;
    }, {} as Record<T[K] & PropertyKey, T>);
  };

  return { mapBy };
};
