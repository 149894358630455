import { computed, ComputedRef, type DeepReadonly, reactive, watch } from '@vue/composition-api';

import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useStaffLabels } from 'src/composables/useStaffLabels';
import { createInjection } from 'src/util/createInjection';
import { useProgressHeaders } from './useProgressHeaders';
import { useRegularShifts } from './useRegularShifts';
import {
  StaffWorkPlanRow,
  StaffWorkPlanHeadcountTimeBlocks,
  StaffWorkPlanHeadcountTimetableRow,
  StaffWorkPlan,
} from '../types';
import type { TimeInteger } from 'src/models/common';
import type { StaffLabel } from 'src/models/staffLabel';
import { hoursToTimeInteger, packToTimeInteger, timeIntegerAdd } from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import { SKILL_MAP } from 'src/consts';
import { OrigStaffWithShifts } from 'src/models/regularShift';
import { BLOCK_LENGTH_PER_HOUR, DISPLAY_HOUR_PERIOD, SUPPORT_MASTER_ID } from '../consts';
import { TimetableMasterMapWithSupport, useTimetableMastersWithSupport } from './useTimetableMastersWithSupport';
import { useDisplayConditions } from './useDisplayConditions';
import { createDisplayTimes } from '../staffWorkPlanHelper';
import type { TimetableAllocationPlan } from 'src/models/timetableAllocationPlan';
import type { StaffAssignmentPlan } from 'src/models/staffAssignmentPlan';

type StaffWorkPlanState = {
  headcountTimes: TimeInteger[];
  displayTimes: string[];
  headcountTimetable: StaffWorkPlanHeadcountTimetableRow[];
  staffWorkPlans: StaffWorkPlanRow[];
  isLoading: boolean;
  isAllHeadcountChecked: boolean;
  isAllStaffChecked: boolean;
};

type InjectionValue = {
  state: DeepReadonly<StaffWorkPlanState>;
  filteredStaffWorkPlans: ComputedRef<StaffWorkPlanRow[]>;
  checkedStaffWorkPlans: ComputedRef<StaffWorkPlanRow[]>;
  fetchStaffWorkPlan: () => void;
  waitLoading: () => void;
  finishLoading: () => void;
  checkAllHeadcountTimetable: () => void;
  checkHeadcountTimetable: (timetableMasterId: number) => void;
  checkAllStaffs: () => void;
  checkStaff: (staffID: number) => void;
  addWorkPlan: (args: { staffId: number; workPlan: StaffWorkPlan }) => void;
  removeWorkPlan: (args: { staffId: number; workPlan: StaffWorkPlan }) => void;
  clearWorkPlans: () => void;
  projectTimetableAllocationPlanToTimeBlocks: (
    timetableAllocationPlansMap: Record<number, TimetableAllocationPlan>,
  ) => void;
  projectStaffAssignmentPlanToWorkPlans: (staffAssignmentPlansMap: Record<number, StaffAssignmentPlan>) => void;
  applyCopiedWorkPlans: (copySourceStaff: DeepReadonly<StaffWorkPlan[]>) => void;
};
const { provide, inject } = createInjection<InjectionValue>('useStaffWorkPlan');

export function useStaffWorkPlanProvider(): void {
  const state: StaffWorkPlanState = reactive({
    headcountTimes: createHeadcountTimes({
      displayHourPeriod: DISPLAY_HOUR_PERIOD,
      blockLengthPerHour: BLOCK_LENGTH_PER_HOUR,
    }),
    displayTimes: createDisplayTimes({ displayHourPeriod: DISPLAY_HOUR_PERIOD }),
    headcountTimetable: [],
    staffWorkPlans: [],
    isLoading: false,
    isAllHeadcountChecked: computed(() => state.headcountTimetable.every(({ isChecked }) => isChecked)),
    isAllStaffChecked: computed(() => state.staffWorkPlans.every(({ isChecked }) => isChecked)),
  });
  const errorBoundary = useErrorBoundary();
  const { progressHeaders, fetchProgressHeaders } = useProgressHeaders();
  const { regularShifts, fetchRegularShifts } = useRegularShifts();
  const { timetableMasterMapWithSupport } = useTimetableMastersWithSupport();
  const { staffLabels } = useStaffLabels();
  const staffLabelsMap = computed(() =>
    staffLabels.value.reduce<Record<number, StaffLabel>>((acc, label) => {
      acc[label.id] = label;
      return acc;
    }, {}),
  );

  const { staffLabel, gender, macroOperationMaster, skillOptions } = useDisplayConditions();

  const filteredStaffWorkPlans = computed(() => {
    const checkedSkillOptions = skillOptions.value.filter(({ checked }) => checked);
    return state.staffWorkPlans.filter(({ staffLabelId, staffExtension }) => {
      if (staffLabel.value !== null && staffLabelId !== staffLabel.value.id) {
        return false;
      }
      if (gender.value !== null && staffExtension.gender !== gender.value) {
        return false;
      }
      if (
        macroOperationMaster.value !== null &&
        staffExtension.macroOperationMasterId !== macroOperationMaster.value.id
      ) {
        return false;
      }
      if (checkedSkillOptions.length > 0) {
        const skillMap = {
          [SKILL_MAP.STAR]: staffExtension.isKeyPlayer,
          [SKILL_MAP.FORK_LIFT]: staffExtension.isForkman,
          [SKILL_MAP.CUSTOM_1]: staffExtension.hasCustomSkill1,
          [SKILL_MAP.CUSTOM_2]: staffExtension.hasCustomSkill2,
          [SKILL_MAP.CUSTOM_3]: staffExtension.hasCustomSkill3,
        };
        return checkedSkillOptions.every(({ type }) => skillMap[type] || false);
      }
      return true;
    });
  });

  const checkedStaffWorkPlans = computed(() => {
    return filteredStaffWorkPlans.value.filter(({ isChecked }) => isChecked);
  });

  const plannedHeadcountsMap = computed(() => {
    const map = [
      {
        timetable_master_id: SUPPORT_MASTER_ID,
      },
    ]
      .concat(progressHeaders.value)
      .reduce<Record<string, Record<number, number | undefined>>>((headcountMap, { timetable_master_id }) => {
        headcountMap[timetable_master_id] = {};
        return headcountMap;
      }, {});
    state.staffWorkPlans.forEach(({ workPlans }) => {
      workPlans.forEach(({ timetableMasterId, startTime, endTime }) => {
        let targetTime = startTime;
        while (targetTime < endTime) {
          map[timetableMasterId][targetTime] = (map[timetableMasterId][targetTime] ?? 0) + 1;
          targetTime = getNextTime(targetTime);
        }
      });
    });
    return map;
  });

  const fetchStaffWorkPlan = errorBoundary.wrap(
    async () => {
      waitLoading();
      try {
        await Promise.all([fetchProgressHeaders(), fetchRegularShifts()]);
      } finally {
        finishLoading();
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  const waitLoading = () => {
    state.isLoading = true;
  };

  const finishLoading = () => {
    state.isLoading = false;
  };

  const checkAllHeadcountTimetable = () => {
    if (state.isAllHeadcountChecked) {
      state.headcountTimetable.forEach((timetableRow) => {
        timetableRow.isChecked = false;
      });
    } else {
      state.headcountTimetable.forEach((timetableRow) => {
        timetableRow.isChecked = true;
      });
    }
  };

  const checkHeadcountTimetable = (timetableMasterId: number) => {
    const targetTimetable = state.headcountTimetable.find((timetableRow) => {
      return timetableRow.masterId === timetableMasterId;
    });
    if (!isExist(targetTimetable)) {
      return;
    }
    targetTimetable.isChecked = !targetTimetable.isChecked;
  };

  const checkAllStaffs = () => {
    if (state.isAllStaffChecked) {
      state.staffWorkPlans.forEach((staff) => {
        staff.isChecked = false;
      });
    } else {
      state.staffWorkPlans.forEach((staff) => {
        staff.isChecked = true;
      });
    }
  };

  const checkStaff = (staffId: number) => {
    const targetStaff = state.staffWorkPlans.find((staff) => {
      return staff.staffId === staffId;
    });
    if (!isExist(targetStaff)) {
      return;
    }
    targetStaff.isChecked = !targetStaff.isChecked;
  };

  const addWorkPlan = ({ staffId: targetStaffId, workPlan }: { staffId: number; workPlan: StaffWorkPlan }) => {
    const targetStaff = state.staffWorkPlans.find(({ staffId }) => staffId === targetStaffId);
    if (!isExist(targetStaff)) {
      return;
    }
    targetStaff.workPlans.push(workPlan);
    targetStaff.workPlans.sort((a, b) => a.startTime - b.startTime);
  };

  const removeWorkPlan = ({ staffId: targetStaffId, workPlan }: { staffId: number; workPlan: StaffWorkPlan }) => {
    const targetStaff = state.staffWorkPlans.find(({ staffId }) => staffId === targetStaffId);
    if (!isExist(targetStaff)) {
      return;
    }
    targetStaff.workPlans = targetStaff.workPlans.filter(
      ({ startTime, endTime }) => startTime !== workPlan.startTime || endTime !== workPlan.endTime,
    );
  };

  const clearWorkPlans = () => {
    state.staffWorkPlans
      .filter((staff) => staff.isChecked)
      .forEach((staff) => {
        staff.workPlans = [];
      });
  };

  const applyCopiedWorkPlans = (copySourceWorkPlans: DeepReadonly<StaffWorkPlan[]>) => {
    checkedStaffWorkPlans.value.forEach((staff) => {
      staff.workPlans = copySourceWorkPlans.map((workPlan) => {
        return {
          ...workPlan,
        };
      });
    });
  };

  const projectTimetableAllocationPlanToTimeBlocks = (
    timetableAllocationPlansMap: Record<number, TimetableAllocationPlan>,
  ) => {
    for (const headcountTimetableRow of state.headcountTimetable) {
      const timetableAllocationPlan = timetableAllocationPlansMap[headcountTimetableRow.masterId];
      for (const timeBlock of Object.values(headcountTimetableRow.timeBlocks)) {
        timeBlock.requiredHeadcount = null;
      }
      if (!isExist(timetableAllocationPlan)) {
        continue;
      }
      for (const timetableAllocationPlanData of timetableAllocationPlan.data) {
        let targetTime = timetableAllocationPlanData.startTime;
        while (targetTime < timetableAllocationPlanData.endTime) {
          headcountTimetableRow.timeBlocks[targetTime].requiredHeadcount =
            timetableAllocationPlanData.requiredHeadcount;
          targetTime = getNextTime(targetTime);
        }
      }
    }
  };

  const projectStaffAssignmentPlanToWorkPlans = (staffAssignmentPlansMap: Record<number, StaffAssignmentPlan>) => {
    for (const staffWorkPlan of state.staffWorkPlans) {
      const staffAssignmentPlan = staffAssignmentPlansMap[staffWorkPlan.staffId];
      if (!isExist(staffAssignmentPlan)) {
        staffWorkPlan.workPlans = [];
        continue;
      }
      staffWorkPlan.workPlans = staffAssignmentPlan.data.map((item) => {
        const timetableMaster = timetableMasterMapWithSupport.value[item.timetableMasterId];
        return {
          startTime: item.startTime,
          endTime: item.endTime,
          timetableMasterId: item.timetableMasterId,
          name: timetableMaster?.name ?? '',
          backgroundColor: timetableMaster?.disp_color ?? '',
        };
      });
    }
  };

  watch(progressHeaders, () => {
    state.headcountTimetable = createHeadcountTimetableFromProgressHeaders({
      progressHeaders: progressHeaders.value,
      headcountTimes: state.headcountTimes,
      timetableMasterMapWithSupport: timetableMasterMapWithSupport.value,
      plannedHeadcountsMap: plannedHeadcountsMap,
    });
  });

  watch(regularShifts, () => {
    state.staffWorkPlans = createStaffWorkPlansFromRegularShifts({
      regularShifts: regularShifts.value,
      staffLabelsMap: staffLabelsMap.value,
    });
  });

  provide({
    state,
    filteredStaffWorkPlans,
    checkedStaffWorkPlans,
    fetchStaffWorkPlan,
    waitLoading,
    finishLoading,
    checkAllHeadcountTimetable,
    checkHeadcountTimetable,
    checkAllStaffs,
    checkStaff,
    addWorkPlan,
    removeWorkPlan,
    clearWorkPlans,
    projectTimetableAllocationPlanToTimeBlocks,
    projectStaffAssignmentPlanToWorkPlans,
    applyCopiedWorkPlans,
  });
}

const createHeadcountTimes = ({
  displayHourPeriod,
  blockLengthPerHour,
}: {
  displayHourPeriod: number;
  blockLengthPerHour: number;
}) => {
  const headcountTimes: TimeInteger[] = [];
  for (let hour = 0; hour < displayHourPeriod; hour++) {
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      headcountTimes.push(packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0));
    }
  }
  return headcountTimes;
};

const createHeadcountTimetableFromProgressHeaders = ({
  progressHeaders,
  headcountTimes,
  timetableMasterMapWithSupport,
  plannedHeadcountsMap,
}: {
  progressHeaders: DeepReadonly<{ timetable_master_id: number }[]>;
  headcountTimes: TimeInteger[];
  timetableMasterMapWithSupport: TimetableMasterMapWithSupport;
  plannedHeadcountsMap: ComputedRef<Record<string, Record<number, number | undefined>>>;
}): StaffWorkPlanHeadcountTimetableRow[] => {
  return progressHeaders
    .concat()
    .sort(({ timetable_master_id: a }, { timetable_master_id: b }) => {
      const aMaster = timetableMasterMapWithSupport[a];
      const bMaster = timetableMasterMapWithSupport[b];
      return (aMaster?.disp_order ?? 0) - (bMaster?.disp_order ?? 0);
    })
    .concat([
      {
        timetable_master_id: SUPPORT_MASTER_ID,
      },
    ])
    .map((progressHeader) => {
      const timetableMaster = timetableMasterMapWithSupport[progressHeader.timetable_master_id];
      const timeBlocks = headcountTimes.reduce<StaffWorkPlanHeadcountTimeBlocks>((timeBlocks, displayTime) => {
        timeBlocks[displayTime] = reactive({
          requiredHeadcount: null,
          plannedHeadcount: computed(() => {
            return plannedHeadcountsMap.value[timetableMaster.id][displayTime] ?? null;
          }),
          isSameHeadcount: computed(() => {
            return timeBlocks[displayTime].requiredHeadcount === timeBlocks[displayTime].plannedHeadcount;
          }),
        });
        return timeBlocks;
      }, {});
      return reactive({
        masterId: timetableMaster?.id ?? null,
        timetableLabelId: timetableMaster?.timetable_label_id ?? null,
        name: timetableMaster?.name ?? null,
        backgroundColor: timetableMaster?.disp_color,
        dispOrder: timetableMaster?.disp_order ?? null,
        timeBlocks,
        isChecked: false,
      });
    });
};

const createStaffWorkPlansFromRegularShifts = ({
  regularShifts,
  staffLabelsMap,
}: {
  regularShifts: DeepReadonly<OrigStaffWithShifts[]>;
  staffLabelsMap: Record<number, StaffLabel>;
}): StaffWorkPlanRow[] => {
  return regularShifts.concat().map((shift) => {
    const targetShift = shift.shifts[0].data;
    return {
      staffId: shift.staff_id,
      staffName: `${shift.family_name} ${shift.first_name}`,
      staffLabelId: shift.staff_label_id,
      staffLabelColor: staffLabelsMap[shift.staff_label_id]?.disp_color ?? '',
      staffExtension: {
        macroOperationMasterId: shift.staff_extension.macro_operation_master.id,
        gender: shift.staff_extension.gender,
        isKeyPlayer: shift.staff_extension.is_key_player,
        isForkman: shift.staff_extension.is_forkman,
        hasCustomSkill1: shift.staff_extension.has_custom_skill1,
        hasCustomSkill2: shift.staff_extension.has_custom_skill2,
        hasCustomSkill3: shift.staff_extension.has_custom_skill3,
      },
      workStartTime: targetShift?.scheduled_work_start_time ?? 0,
      workEndTime: targetShift?.scheduled_work_end_time ?? 0,
      break1StartTime: targetShift?.scheduled_break1_start_time ?? null,
      break1EndTime: targetShift?.scheduled_break1_end_time ?? null,
      break2StartTime: targetShift?.scheduled_break2_start_time ?? null,
      break2EndTime: targetShift?.scheduled_break2_end_time ?? null,
      workPlans: [],
      isChecked: false,
    };
  });
};

const getNextTime = (currentTime: number) => {
  return timeIntegerAdd(currentTime, hoursToTimeInteger(1 / BLOCK_LENGTH_PER_HOUR));
};

export function useStaffWorkPlan(): InjectionValue {
  return inject();
}
