












import { defineComponent, type PropType } from '@vue/composition-api';
import { type AggregationTargetStaffTag } from 'src/models/workplaceMasters/aggregationTargetStaffTag';
import AggregationTargetStaffTagListRow from './AggregationTargetStaffTagListRow.vue';
import AggregationTargetStaffTagListCell from './AggregationTargetStaffTagListCell.vue';
import AggregationTargetStaffTagListControls from './AggregationTargetStaffTagListControls.vue';

export default defineComponent({
  components: {
    AggregationTargetStaffTagListRow,
    AggregationTargetStaffTagListCell,
    AggregationTargetStaffTagListControls,
  },
  props: {
    aggregationTargetStaffTag: {
      type: Object as PropType<AggregationTargetStaffTag>,
      required: true,
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    onDelete: {
      type: Function as PropType<
        ({ aggregationTargetStaffTag }: { aggregationTargetStaffTag: AggregationTargetStaffTag }) => unknown
      >,
      required: true,
    },
  },
});
