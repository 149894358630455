import { createInjection } from 'src/util/createInjection';
import { useUrlStoredParameters } from 'src/composables/useUrlStoredParameters';

type UrlParameters = {
  budget_group_id: number;
  show_mode: number;
  headcount_mode: number;
};

type InjectionValue = {
  getUrlStoredParameters: () => Partial<UrlParameters>;
  setUrlStoredParameters: (params: UrlParameters) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useMonthlyPlanUrlParameters');

export function useMonthlyPlanUrlParametersProvider(): void {
  provide(useUrlStoredParameters<UrlParameters>());
}

export function useMonthlyPlanUrlParameters(): InjectionValue {
  return inject();
}
