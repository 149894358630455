import { ref, unref, readonly, watch, onBeforeUnmount, type Ref, type DeepReadonly } from '@vue/composition-api';
import { format } from 'date-fns';
import progressBoardApi from 'src/apis/progressBoard';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableHeader } from 'src/models/timetableHeader';
import { convertProgressBoardIndexResponse, type ProgressBoard } from 'src/models/progressBoard';
import { createInjection } from 'src/util/createInjection';
import { createTimer } from 'src/util/createTimer';
import { useSearchConditions } from 'src/composables/useSearchConditions';

type InjectionValue = {
  progressBoard: DeepReadonly<Ref<ProgressBoard | null>>;
  canSearch: Ref<boolean>;
  canUpdate: Ref<boolean>;
  lastUpdatedAt: Ref<string>;
  productivityGraph: Ref<boolean>;
  hidesCompletedTimetableHeaders: Ref<boolean>;
  filterTimetableHeaders: (timetableHeaders: TimetableHeader[]) => TimetableHeader[];
  fetchProgressBoard: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressBoard');

async function getProgressBoard({ dt, budgetGroup }: { dt: Date; budgetGroup: BudgetGroup }): Promise<ProgressBoard> {
  const { data } = await progressBoardApi.index({
    dt: format(dt, 'yyyy-MM-dd'),
    workplaceId: budgetGroup.workplace_id,
    budgetGroupId: budgetGroup.id,
  });

  return convertProgressBoardIndexResponse(data);
}

function isInProgress(timetableHeader: TimetableHeader): boolean {
  return timetableHeader.completed_time === null;
}

function createTimetableHeadersFilter({
  hidesCompletedTimetableHeaders,
}: {
  hidesCompletedTimetableHeaders: Ref<boolean>;
}): (timetableHeaders: TimetableHeader[]) => TimetableHeader[] {
  return (timetableHeaders: TimetableHeader[]) => {
    return hidesCompletedTimetableHeaders.value ? timetableHeaders.filter(isInProgress) : timetableHeaders;
  };
}

export function useProgressBoardProvider(): void {
  const { dt, budgetGroup } = useSearchConditions();
  const errorBoundary = useErrorBoundary();
  const progressBoard = ref<ProgressBoard | null>(null);
  const canSearch = ref(false);
  const canUpdate = ref(false);
  const lastUpdatedAt = ref('');
  const productivityGraph = ref(false);
  const hidesCompletedTimetableHeaders = ref(false);
  const filterTimetableHeaders = createTimetableHeadersFilter({ hidesCompletedTimetableHeaders });
  const fetchProgressBoard = errorBoundary.wrap(
    async () => {
      const unwrappedDt = unref(dt);
      const unwrappedBudgetGroup = unref(budgetGroup);
      if (unwrappedBudgetGroup !== null) {
        try {
          canSearch.value = false;
          canUpdate.value = false;

          progressBoard.value = await getProgressBoard({
            dt: unwrappedDt,
            budgetGroup: unwrappedBudgetGroup,
          });

          canUpdate.value = true;
          lastUpdatedAt.value = format(new Date(), 'HH:mm:ss');
        } finally {
          canSearch.value = true;
        }
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const autoUpdateTimer = createTimer(fetchProgressBoard, 60 * 1000);

  watch([dt, budgetGroup], () => {
    canSearch.value = budgetGroup.value !== null;
    canUpdate.value = false;
  });

  watch([canUpdate], () => {
    if (canUpdate.value) {
      autoUpdateTimer.start();
    } else {
      autoUpdateTimer.stop();
    }
  });

  onBeforeUnmount(() => {
    autoUpdateTimer.stop();
  });

  provide({
    progressBoard: readonly(progressBoard),
    canSearch: readonly(canSearch),
    canUpdate: readonly(canUpdate),
    lastUpdatedAt: readonly(lastUpdatedAt),
    productivityGraph,
    hidesCompletedTimetableHeaders,
    filterTimetableHeaders,
    fetchProgressBoard,
  });
}

export function useProgressBoard(): InjectionValue {
  return inject();
}
