import { ref, type Ref } from '@vue/composition-api';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
};

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions');

export function useSearchConditionsProvider(): void {
  const dt = ref(new Date());
  const budgetGroup = ref<BudgetGroup | null>(null);

  provide({
    dt,
    budgetGroup,
  });
}

export function useSearchConditions(): InjectionValue {
  return inject();
}
