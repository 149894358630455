export const TOTAL_HOUR_BLOCK_ID = -1; // 合計行のID
export const UNASSIGNED_HOUR_BLOCK_ID = -2; // 割当なし行のID

export const COLUMN_WIDTH = 100; // 時間列の 1 列分の幅

export const DISPLAY_END_HOUR = 48; // 表示終了時間 (時)
export const DISPLAY_END_TIME_INTEGER = 480000; // 表示終了時間 (TimeInteger)

export const UNIT_BLOCK_MINUTES = 15; // 1ブロックの時間 (分)
export const UNIT_BLOCK_MINUTES_IN_SECONDS = UNIT_BLOCK_MINUTES * 60; // 1ブロックの時間 (秒)
export const UNIT_BLOCK_LENGTH_PER_HOUR = 60 / UNIT_BLOCK_MINUTES; // 1時間あたりのブロック数
