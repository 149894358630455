import { ComputedRef, Ref, nextTick, watch } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import { isExist } from 'src/util/isExist';

type Params = {
  tableElement: Ref<HTMLDivElement | undefined>;
  shiftTableElement: Ref<HTMLDivElement | undefined>;
  anchorPosition: ComputedRef<number | null>;
};

type InjectionValue = {
  tableElement: Ref<HTMLDivElement | undefined>;
  shiftTableElement: Ref<HTMLDivElement | undefined>;
};

const { provide, inject } = createInjection<InjectionValue>('useScrollSync');

export function useScrollSyncProvider({ tableElement, shiftTableElement, anchorPosition }: Params): void {
  nextTick(() => {
    watch(
      () => tableElement.value,
      (value) => {
        if (value === undefined) {
          return;
        }
        if (isExist(value)) {
          value.scrollLeft = anchorPosition.value ?? 0;

          value.onscroll = function () {
            if (!isExist(shiftTableElement.value)) {
              return;
            }
            const scrollLeft = value.scrollLeft || 0;
            shiftTableElement.value.scrollLeft = scrollLeft;
          };
        }
      },
    );
    watch(
      () => shiftTableElement.value,
      (value) => {
        if (value === undefined) {
          return;
        }
        if (isExist(value)) {
          value.scrollLeft = tableElement.value ? tableElement.value.scrollLeft : anchorPosition.value ?? 0;
          value.onscroll = function () {
            if (!isExist(tableElement.value)) {
              return;
            }
            const scrollLeft = value.scrollLeft || 0;
            tableElement.value.scrollLeft = scrollLeft;
          };
        }
      },
    );
  });

  provide({
    tableElement,
    shiftTableElement,
  });
}

export function useScrollSync(): InjectionValue {
  return inject();
}
