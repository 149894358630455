import { ref, type Ref } from '@vue/composition-api';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  timetableLabels: Ref<TimetableLabel[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useSelectedTimetables');

export function useSelectedTimetablesProvider(): void {
  const timetableLabels = ref<TimetableLabel[]>([]);

  provide({
    timetableLabels,
  });
}

export function useSelectedTimetables(): InjectionValue {
  return inject();
}
