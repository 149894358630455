import { convertTimetableHeaderResponse, type TimetableHeader } from 'src/models/timetableHeader';
import { type ProgressBoardIndexResponse } from 'src/models/api/progressBoardResponse';
import { parseDatetime } from 'src/util/datetime';

export type StaffHeadCounts = {
  working: number;
  resting: number;
  waiting: number;
  other_working: number;
};

export type ActivityStatusMap = {
  our_staff_head_counts: StaffHeadCounts;
  rescue_staff_head_counts: StaffHeadCounts;
};

export type HeadCountByLabel = {
  staff_label_id: number;
  staff_label_name: string;
  head_count: number;
  disp_color: string;
};

export type ActivitySummary = {
  activity_master_id: number;
  appropriation_date_start: Date;
  appropriation_date_end: Date;
  head_counts_by_label: HeadCountByLabel[];
};

export type CurrentStaffWork = {
  activity_status_map: ActivityStatusMap;
  activity_summaries: ActivitySummary[];
};

export type ProgressBoard = {
  timetableHeaders: TimetableHeader[];
  currentStaffWork: CurrentStaffWork;
};

export function convertProgressBoardIndexResponse(response: ProgressBoardIndexResponse): ProgressBoard {
  return {
    timetableHeaders: response.timetable_headers.map(convertTimetableHeaderResponse),
    currentStaffWork: {
      ...response.current_staff_work,
      activity_summaries: response.current_staff_work.activity_summaries.map((v) => ({
        ...v,
        appropriation_date_start: parseDatetime(v.appropriation_date_start),
        appropriation_date_end: parseDatetime(v.appropriation_date_end),
      })),
    },
  };
}
